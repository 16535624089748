import Countdown, { zeroPad, CountdownRendererFn } from 'react-countdown'

interface Props {
  smallText?: boolean
  text: string
  endTime?: string | Date | null
}

const renderer: (smallText: boolean) => CountdownRendererFn =
  (smallText: boolean) =>
  ({ days, hours, minutes, seconds }) => {
    const isLessThanOneMin = days === 0 && hours === 0 && minutes < 1
    return (
      <div
        className={`
        relative top-[0.1rem] font-aldrich text-[0.82rem] dxs:text-[1.3rem] whitespace-nowrap text-red leading-none
        ${smallText ? 'dxs:text-[1rem] top-[0.15rem]' : ''}
        `}
      >
        <div
          className={`ml-[0.5rem] leading-none ${
            isLessThanOneMin
              ? `${
                  smallText
                    ? 'w-[3.0rem] dxs:w-[3.5rem]'
                    : 'w-[3rem] dxs:w-[6rem]'
                }`
              : `${
                  smallText
                    ? 'w-[5rem] dxs:w-[6.3rem]'
                    : 'w-[5rem] dxs:w-[7.5rem]'
                }`
          }`}
        >
          {isLessThanOneMin
            ? '< 1 min'
            : `${zeroPad(days)}:${zeroPad(hours)}:${zeroPad(minutes)}:${zeroPad(
                seconds
              )}`}
        </div>
      </div>
    )
  }

export default function Timer({ text, endTime, smallText = false }: Props) {
  return (
    <div
      className={`flex items-center justify-center text-[0.82rem] ${
        smallText ? 'dxs:text-base' : 'dxs:text-[1.3rem]'
      } `}
    >
      <div className="leading-none">{text}</div>
      {endTime ? (
        <Countdown
          zeroPadTime={2}
          date={endTime}
          renderer={renderer(smallText)}
        />
      ) : null}
    </div>
  )
}
