import { RouteComponentProps } from '@reach/router'
import { Header } from 'components'
import Modals from 'components/Modals'
import noop from 'lodash/noop'
import React from 'react'
import { useEffect } from 'react'
interface Props extends RouteComponentProps {
  children?: React.ReactNode
}

function Main(props: Props) {
  const { location, navigate = noop } = props

  useEffect(() => {
    if (location?.search.match(/__cf_chl_captcha_tk__/)) {
      location.search = ''
      navigate(location.toString())
    }
  }, [location?.search, location, navigate])

  return (
    <div className="relative flex justify-center h-screen m-auto font-mmc">
      <div className="flex-col dxs:flex grow">
        <Header />
        {props.children}
      </div>
      <Modals />
    </div>
  )
}

export default Main
