import { Button, ModalTitle } from 'components'
import { useTheme } from 'themeContext'
import { useStore } from 'effector-react'
import { $stakingInfo } from 'models/app'
import { $unstakeAmount } from 'models/flow'
import { unstake } from 'models/staking'
import { formatNumber } from 'utils/numbers'
import numeral from 'numeral'
import { useWallet } from '@txnlab/use-wallet'

export default function TxWaitModal() {
  const { signTransactions } = useWallet()
  const dark = useTheme()
  const color = dark ? '!text-white' : '!text-black'
  const stakingInfo = useStore($stakingInfo)
  const stakingSymbol = stakingInfo.stakingToken?.symbol ?? ''
  const rewardSymbol = stakingInfo.rewardToken?.symbol ?? ''

  const loseAmount = formatNumber(
    stakingInfo.account?.stakeBooster?.stakeBoosterBalance ?? '0'
  )

  const unstakeAmount = useStore($unstakeAmount)

  const handleSubmit = () => {
    unstake({ unstakeAmount, signTransactions })
  }

  return (
    <div className="flex flex-col flex-grow">
      <ModalTitle className={color}>Unstake {stakingSymbol}</ModalTitle>
      <div
        className={`text-center flex flex-col space-y-[1rem] font-mono ${color} leading-[1.35rem] text-base mb-[2rem]`}
      >
        <p>
          You will irreversibly lose your stake boost of{' '}
          <span className="text-blue">
            {numeral(loseAmount).format('0')} {stakingSymbol}
          </span>{' '}
          if your total stake falls below your reference stake before maturity
          date.
        </p>
        <p>
          Your participation tier and {rewardSymbol} earnings will suffer as
          well.
        </p>
      </div>

      <Button type="slide" onClick={handleSubmit}>
        Unstake anyway
      </Button>
    </div>
  )
}
