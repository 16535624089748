import { sample } from 'effector'
import { TIMESTAMP_START } from 'utils/dayjs'
import produce from 'immer'
/*
 * @LOCAL
import { PoolType } from 'types'
import poolsJson from 'utils/mock/pools.json'
 * */

import { openApplyForKYCLink, $kycInfo } from '.'
import {
  fetchGeneralInfoFx,
  fetchKYCInfoFx,
  openApplyForKYCLinkFx,
  setPools,
} from '.'

/*
 * @LOCAL
const pools = poolsJson.data.pools as unknown as PoolType[]
setPools(pools)
 * */

fetchGeneralInfoFx()
fetchKYCInfoFx()
setInterval(fetchGeneralInfoFx, 5000)
setInterval(fetchKYCInfoFx, 5000)

sample({
  clock: openApplyForKYCLink,
  source: $kycInfo,
  fn: (arg) => arg,
  target: openApplyForKYCLinkFx,
})

sample({
  clock: setPools,
  fn: (pools) =>
    produce(pools, (draft) => {
      draft.sort((a, b) => {
        if (
          a.pipeline?.startTime &&
          b.pipeline?.startTime &&
          a.pipeline?.startTime !== TIMESTAMP_START &&
          b.pipeline?.startTime !== TIMESTAMP_START
        ) {
          if (a.pipeline.startTime > b.pipeline.startTime) {
            return -1
          } else {
            return 1
          }
        }
        if (
          a.pipeline?.startTime === TIMESTAMP_START &&
          b.pipeline?.startTime === TIMESTAMP_START
        ) {
          return a.id > b.id ? -1 : 1
        }

        return a.pipeline?.startTime === TIMESTAMP_START ? -1 : 1
      })
    }),
})
