import React from 'react'

type FieldProps = {
  valueColor?: 'default' | 'red' | 'blue' | 'white'
  titleColor?: 'default' | 'red' | 'grayLight'
  className?: string
  percent?: string
  value: string
  symbol?: string
  title: string
  size?: 'sm' | 'm' | 'ml' | 'l' | 'xl'
  symbolColor?: 'grayLight' | 'white'
  icon?: React.ReactElement
}

export default function Field({
  valueColor = 'default',
  titleColor = 'default',
  size = 'm',
  symbol,
  percent,
  value,
  title,
  className,
  symbolColor = 'grayLight',
  icon,
}: FieldProps) {
  const cn = {
    usdcIcon: `
      ${
        size === 'm'
          ? 'w-[1rem] h-[1rem] dxs:w-[1.6rem] dxs:h-[1.6rem] mr-[0.5rem] dxs:mr-[0.7rem]'
          : ''
      }
      ${
        size === 'l'
          ? 'w-[1rem] h-[1rem] dxs:w-[1.7rem] dxs:h-[1.7rem] mr-[0.5rem] dxs:mr-[0.7rem]'
          : ''
      }
      ${
        size === 'xl'
          ? 'w-[1.6rem] h-[1.6rem] dxs:w-[2.35rem] h-[2.35rem] mr-[0.7rem]'
          : ''
      }
    `,
    percent: 'text-[0.77rem] dxs:text-[0.8rem] text-grayLight',
    value: `
      leading-none
      ${size === 'sm' ? 'mb-[0.2rem] text-base' : ''}
      ${size === 'm' ? 'mb-[0.1rem] dxs:text-[1.3rem]' : ''}
      ${size === 'ml' ? 'mb-[0.1rem] text-[1.3rem] dxs:text-[1.6rem]' : ''}
      ${size === 'l' ? 'mb-[0.2rem] ml:text-[1.05rem] dxs:text-[1.6rem]' : ''}
      ${size === 'xl' ? 'mb-[0.2rem] text-[2.1rem] dxs:text-[2.6rem]' : ''}
      ${valueColor === 'red' ? 'text-red' : ''}
      ${valueColor === 'blue' ? 'text-blue' : ''}
      ${valueColor === 'white' ? 'text-white' : ''}
      
  `,
    title: `
      ${size === 'sm' ? 'text-[0.8rem]' : ''}
      ${size === 'm' ? 'text-[0.77rem] dxs:text-[0.8rem]' : ''}
      ${size === 'ml' ? 'text-[0.88rem] dxs:text-base' : ''}
      ${size === 'l' ? 'text-[0.78rem] ml:text-[0.82rem] dxs:text-base' : ''}
      ${size === 'xl' ? 'text-[1.05rem] dxs:text-[1.3rem]' : ''}
      ${titleColor === 'red' ? 'text-red' : ''}
      ${titleColor === 'grayLight' ? 'text-grayLight' : ''}

      
  `,
    symbol: `
      ${size === 'sm' ? 'text-[0.8rem]' : ''}
      ${size === 'm' ? 'text-[0.77rem] dxs:text-[0.8rem]' : ''}
      ${size === 'ml' ? 'text-[0.88rem] dxs:text-base' : ''}
      ${size === 'xl' ? 'text-[1.05rem] dxs:text-[1.3rem]' : ''}
      ${symbolColor === 'grayLight' ? 'text-grayLight' : ''}
      ${symbolColor === 'white' ? 'text-grayLightSecond' : ''}
  `,
  }
  return (
    <div className={`flex ${className}`}>
      {icon ? React.cloneElement(icon, { className: cn.usdcIcon }) : null}
      <div className={`font-aldrich`}>
        {percent && <div className={cn.percent}>{percent}</div>}
        <div className={cn.value}>
          {value} {symbol && <span className={`${cn.symbol}`}>{symbol}</span>}
        </div>
        <div className={`${cn.title} uppercase`}>{title}</div>
      </div>
    </div>
  )
}
