import {
  Algodv2,
  assignGroupID,
  //decodeAddress,
  encodeUint64,
  getApplicationAddress,
  makeApplicationCallTxnFromObject,
  makeAssetTransferTxnWithSuggestedParamsFromObject,
  OnApplicationComplete,
  Transaction,
} from 'algosdk'

export interface StakeTransactionOptions {
  algodClient: Algodv2
  from: string
  stakingAppID: number
  amount: number
  assetID: number
  signTransactions: SignTransactions
}

export const makeStakeTransaction = async ({
  algodClient,
  from,
  stakingAppID,
  amount,
  assetID,
}: Omit<StakeTransactionOptions, 'signTransactions'>): Promise<
  Transaction[]
> => {
  const enc = new TextEncoder()
  const methodName = enc.encode('stake')

  let suggestedParams = await algodClient.getTransactionParams().do()
  const transferTx = makeAssetTransferTxnWithSuggestedParamsFromObject({
    from: from,
    to: getApplicationAddress(stakingAppID),
    amount: amount,
    assetIndex: assetID,
    suggestedParams: suggestedParams,
  })

  suggestedParams = await algodClient.getTransactionParams().do()
  suggestedParams.fee = 3_000
  suggestedParams.flatFee = true
  const appCallTx = makeApplicationCallTxnFromObject({
    from: from,
    appIndex: stakingAppID,
    appArgs: [methodName],
    foreignAssets: [assetID],
    onComplete: OnApplicationComplete.NoOpOC,
    suggestedParams: suggestedParams,
  })

  suggestedParams = await algodClient.getTransactionParams().do()
  const extendComputationBudgetTx = makeApplicationCallTxnFromObject({
    from: from,
    appIndex: stakingAppID,
    appArgs: [enc.encode('extend_comp_budget')],
    onComplete: OnApplicationComplete.NoOpOC,
    suggestedParams: suggestedParams,
  })

  return assignGroupID([transferTx, appCallTx, extendComputationBudgetTx])
}

interface UnstakeTransactionOptions {
  algodClient: Algodv2
  from: string
  stakingAppID: number
  amount: number
  feeThreshold: number
  assetID: number
  feeBurnAddress: string
}

export const makeUnstakeTransaction = async ({
  algodClient,
  from,
  stakingAppID,
  amount,
  feeThreshold,
  assetID,
  feeBurnAddress,
}: UnstakeTransactionOptions): Promise<Transaction[]> => {
  const enc = new TextEncoder()
  const methodName = enc.encode('unstake')

  let suggestedParams = await algodClient.getTransactionParams().do()
  suggestedParams.fee = 3_000
  suggestedParams.flatFee = true

  const unstakeTx = makeApplicationCallTxnFromObject({
    from: from,
    appIndex: stakingAppID,
    appArgs: [methodName, encodeUint64(amount), encodeUint64(feeThreshold)],
    foreignAssets: [assetID],
    accounts: [feeBurnAddress],
    onComplete: OnApplicationComplete.NoOpOC,
    suggestedParams: suggestedParams,
  })

  suggestedParams = await algodClient.getTransactionParams().do()
  const extendComputationBudgetTx = makeApplicationCallTxnFromObject({
    from: from,
    appIndex: stakingAppID,
    appArgs: [enc.encode('extend_comp_budget')],
    onComplete: OnApplicationComplete.NoOpOC,
    suggestedParams: suggestedParams,
  })

  return assignGroupID([unstakeTx, extendComputationBudgetTx])
}

export interface ClaimTransactionOptions {
  algodClient: Algodv2
  stakingAppID: number
  from: string
  rewardAssetID: number
  signTransactions: SignTransactions
}

export const makeWithdrawRewardTransaction = async ({
  algodClient,
  stakingAppID,
  from,
  rewardAssetID,
}: Omit<ClaimTransactionOptions, 'signTransactions'>): Promise<
  Transaction[]
> => {
  const enc = new TextEncoder()

  let suggestedParams = await algodClient.getTransactionParams().do()
  suggestedParams.fee = 2_000
  suggestedParams.flatFee = true

  const withdrawTx = makeApplicationCallTxnFromObject({
    from: from,
    appIndex: stakingAppID,
    appArgs: [enc.encode('withdraw_reward')],
    foreignAssets: [rewardAssetID],
    onComplete: OnApplicationComplete.NoOpOC,
    suggestedParams: suggestedParams,
  })

  suggestedParams = await algodClient.getTransactionParams().do()
  const extendComputationBudgetTx1 = makeApplicationCallTxnFromObject({
    from: from,
    appIndex: stakingAppID,
    appArgs: [enc.encode('extend_comp_budget')],
    onComplete: OnApplicationComplete.NoOpOC,
    suggestedParams: suggestedParams,
  })

  suggestedParams = await algodClient.getTransactionParams().do()
  const extendComputationBudgetTx2 = makeApplicationCallTxnFromObject({
    from: from,
    appIndex: stakingAppID,
    appArgs: [enc.encode('extend_comp_budget')],
    note: enc.encode(`${Math.random()}`),
    onComplete: OnApplicationComplete.NoOpOC,
    suggestedParams: suggestedParams,
  })

  return assignGroupID([
    withdrawTx,
    extendComputationBudgetTx1,
    extendComputationBudgetTx2,
  ])
}

/*
* not used
* 
export const makeTransferStakeTransaction = async (
  algodClient: Algodv2,
  stakingAppID: number,
  from: string,
  to: string,
  amount: number,
  assetID: number
): Promise<Transaction> => {
  const enc = new TextEncoder()
  const methodName = enc.encode('transfer_stake')

  const suggestedParams = await algodClient.getTransactionParams().do()
  suggestedParams.fee = 2_000
  suggestedParams.flatFee = true

  return makeApplicationCallTxnFromObject({
    from: from,
    appIndex: stakingAppID,
    appArgs: [methodName, decodeAddress(to).publicKey, encodeUint64(amount)],
    foreignAssets: [assetID],
    accounts: [to],
    onComplete: OnApplicationComplete.NoOpOC,
    suggestedParams: suggestedParams,
  })
}
*/
