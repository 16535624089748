import { Button, ModalTitle } from 'components'
import Logo from 'components/Logo'
import { closeModal } from 'models/flow'
import { t } from 'utils'

export default function RegistrationSuccessModal() {
  const handleClick = () => {
    closeModal()
  }
  return (
    <div className="flex flex-col flex-grow">
      <ModalTitle>{t('registration_success')}</ModalTitle>
      <p className="text-center font-mono text-black leading-[1.35rem] text-base">
        You have successfully opted-in to the pool contract.
      </p>
      <div className="flex flex-col items-center">
        <Logo className="text-red w-[6rem] h-[6.5rem] mt-[4rem] mb-[2rem] " />
      </div>
      <Button type="slide" onClick={handleClick}>
        Ok
      </Button>
    </div>
  )
}
