import React from 'react'
import { RouteComponentProps } from '@reach/router'

const ThemeContext = React.createContext(false)

interface Props extends RouteComponentProps {
  children: React.ReactNode
}

function ThemeProvider({ children, location }: Props) {
  const [dark, setIsDark] = React.useState(false)
  const isStaking = location?.pathname === '/staking'
  const isSigma = location?.pathname === '/dashboard'

  React.useEffect(() => {
    setIsDark(isStaking || isSigma)
  }, [isStaking, isSigma])

  return <ThemeContext.Provider value={dark}>{children}</ThemeContext.Provider>
}

function useTheme() {
  const context = React.useContext(ThemeContext)

  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider')
  }
  return context
}

export { ThemeProvider, useTheme }
