import { Accounts } from 'components'
import ModalWalletHeading from 'components/ModalWalletHeading'
import { useStore } from 'effector-react'
import { ReactComponent as ExclamationMarkRedIcon } from 'icons/exclamation-mark-red.svg'
import { $accountsData } from 'models/algo'
import { useTheme } from 'themeContext'

export default function ConnectWalletChoiceAccountModal() {
  const accountsData = useStore($accountsData)

  const isOptIn = accountsData.findIndex((account) => account.isOptIn) !== -1
  const dark = useTheme()

  const cn = {
    text: dark ? 'text-white' : 'text-black',
  }

  return (
    <div className="flex flex-col flex-grow text-black">
      <ModalWalletHeading />
      {!isOptIn && (
        <div className={`${cn.text} mb-[2.8rem]`}>
          {!isOptIn && (
            <div className="relative mb-[0.5rem]">
              <ExclamationMarkRedIcon className="absolute w-[1.4rem] h-[1.4rem] left-0 top-[0.15rem]" />
              <p className="pl-[2.15rem]">
                You need to opt-in your account to the AlgoDAO app. You only
                need to do this once per account.
              </p>
            </div>
          )}
        </div>
      )}

      <div>
        <div className={`${cn.text} text-[1.3rem] mb-[1.5rem]`}>
          Select account
        </div>
        <Accounts />
      </div>
    </div>
  )
}
