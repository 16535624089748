import React from 'react'
import { ReactComponent as MediumIcon } from 'icons/medium.svg'
import { ReactComponent as TwitterIcon } from 'icons/twitter.svg'
import { ReactComponent as TelegramIcon } from 'icons/telegram.svg'
import { ReactComponent as GithubIcon } from 'icons/github.svg'
import { ReactComponent as DiscordIcon } from 'icons/discord.svg'
import { ReactComponent as WhitepaperIcon } from 'icons/whitepaper.svg'

type SocialLinkProps = {
  url?: string | null
  // name?: string
  icon?:
    | 'telegram'
    | 'medium'
    | 'twitter'
    | 'github'
    | 'discord'
    | 'whitepaper'
    | string
  className?: string
}

export default function SocialLink({
  url,
  // name,
  icon = 'telegram',
  className,
}: SocialLinkProps) {
  const iconComp = React.useMemo(() => {
    switch (icon) {
      case 'telegram':
        return <TelegramIcon />
      case 'medium':
        return <MediumIcon />
      case 'twitter':
        return <TwitterIcon />
      case 'github':
        return <GithubIcon />
      case 'discord':
        return <DiscordIcon />
      case 'whitepaper':
        return <WhitepaperIcon />
    }
  }, [icon])

  if (!url) {
    return null
  }
  const handleClick = () => {
    window.open(url, '_blank', 'noopener,noreferrer')
  }

  return (
    <div onClick={handleClick} className={`cursor-pointer group ${className}`}>
      {iconComp
        ? React.cloneElement(iconComp, {
            className:
              'fill-grayLight w-[1.05rem] h-[1.05rem] dxs:w-[1.4rem] dxs:h-[1.4rem] group-hover:fill-blueDarkLight transition-colors',
          })
        : null}
      {/* {name ? (
        <div
          className={`font-mono text-[0.66rem] uppercase text-blue leading-3 ${
            icon ? 'ml-2' : ''
          }`}
        >
          {name}
        </div>
      ) : null} */}
    </div>
  )
}
