import {
  Algodv2,
  makeAssetTransferTxnWithSuggestedParamsFromObject,
  makeApplicationCloseOutTxnFromObject,
  makeApplicationOptInTxnFromObject,
  Transaction,
} from 'algosdk'

interface AppOptInOptions {
  algodClient: Algodv2
  appID: number
  from: string
}

export const makeAppOptInTransaction = async ({
  algodClient,
  appID,
  from,
}: AppOptInOptions): Promise<Transaction> => {
  const suggestedParams = await algodClient.getTransactionParams().do()
  return makeApplicationOptInTxnFromObject({
    from: from,
    appIndex: appID,
    suggestedParams: suggestedParams,
  })
}

interface AppCloseOutOptions {
  algodClient: Algodv2
  appID: number
  from: string
}

export const makeAppCloseOutTransaction = async ({
  algodClient,
  appID,
  from,
}: AppCloseOutOptions): Promise<Transaction> => {
  const suggestedParams = await algodClient.getTransactionParams().do()
  return makeApplicationCloseOutTxnFromObject({
    from: from,
    appIndex: appID,
    suggestedParams: suggestedParams,
  })
}

interface MakeAssetOptInTransaction {
  algodClient: Algodv2
  assetID: number
  from: string
}

export const makeAssetOptInTransaction = async ({
  algodClient,
  assetID,
  from,
}: MakeAssetOptInTransaction): Promise<Transaction> => {
  const suggestedParams = await algodClient.getTransactionParams().do()
  return makeAssetTransferTxnWithSuggestedParamsFromObject({
    from: from,
    to: from,
    amount: 0,
    assetIndex: assetID,
    suggestedParams: suggestedParams,
  })
}
