import ModalTitle from 'components/ModalTitle'
import { useStore } from 'effector-react'
import { ReactComponent as LogoutIcon } from 'icons/logout.svg'
import { ReactComponent as SwitchIcon } from 'icons/switch.svg'
import isEmpty from 'lodash/isEmpty'
import { $accounts } from 'models/algo'
import {
  $modalState,
  ModalState,
  openConnectWalletModal,
  openConnectWalletSelectAccountModal,
} from 'models/flow'
import { $userSession, setUserSession } from 'models/user'
import { useTheme } from 'themeContext'
import { t } from 'utils'
import { useWallet } from '@txnlab/use-wallet'

export default function ModalWalletHeading() {
  const dark = useTheme()
  const { providers } = useWallet()

  const currentProvider = providers?.find((provider) => provider.isConnected)

  const userSession = useStore($userSession)
  const accounts = useStore($accounts)

  const handleDisconnectClick = async () => {
    await currentProvider?.disconnect()
    setUserSession({ address: '', session: '', shortAddress: '' })
    localStorage.clear()
    openConnectWalletModal()
  }

  const handleSwitchClick = () => {
    openConnectWalletSelectAccountModal()
  }

  const modalState = useStore($modalState)

  const showSwitch =
    !isEmpty(userSession.shortAddress) &&
    accounts.length > 1 &&
    modalState !== ModalState.ConnectWalletChoiceAccount

  const cn = {
    text: dark ? 'text-white' : 'text-black',
    container: showSwitch
      ? 'space-y-[1.3rem] ml:space-y-0 flex-col ml:flex-row'
      : '',
  }

  return (
    <div className="mb-[1.5rem]">
      <div
        className={`${cn.container} flex items-start justify-between ml:items-center`}
      >
        <ModalTitle className="mb-0">{t('wallet')}</ModalTitle>
        <div className="flex items-center justify-between">
          <img
            className="w-[1.5rem] h-[1.5rem]"
            alt={`${currentProvider?.metadata.name} icon`}
            src={currentProvider?.metadata.icon}
          />
          {!isEmpty(userSession.shortAddress) && (
            <a
              className={`${cn.text} flex items-center ml-[0.7rem] font-aldrich relative top-[0.1rem]`}
              href={`${process.env.REACT_APP_ALGO_EXPLORER}/address/${userSession.address}`}
              target="_blank"
              rel="noreferrer"
            >
              {userSession.shortAddress}
            </a>
          )}
          {showSwitch && (
            <div
              className="cursor-pointer ml-[1.3rem] flex items-center uppercase font-aldrich text-grayLight"
              onClick={handleSwitchClick}
            >
              <span className="relative top-[0.1rem] mr-[0.2rem]">Switch</span>{' '}
              <SwitchIcon className="w-[1.25rem] h-[0.9rem]" />
            </div>
          )}
          <LogoutIcon
            className="ml-[2.05rem] cursor-pointer w-[1.61rem] h-[1.7rem] opacity-80 hover:opacity-100 transition-opacity"
            onClick={handleDisconnectClick}
          />
        </div>
      </div>
    </div>
  )
}
