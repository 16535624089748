interface Props {
  color: string
  className?: string
}

export default function Tier3({ color, className = '' }: Props) {
  return (
    <svg
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w4.org/2000/svg"
      className={className}
    >
      <path
        d="M47.3454 4.59902C48.1376 3.42634 49.8643 3.42634 50.6566 4.59902L60.5871 19.2977C61.0353 19.9612 61.8363 20.293 62.6224 20.1408L80.0379 16.7692C81.4273 16.5002 82.6483 17.7211 82.3793 19.1105L79.0076 36.526C78.8554 37.3121 79.1872 38.1131 79.8507 38.5614L94.5494 48.4918C95.7221 49.2841 95.7221 51.0108 94.5494 51.8031L79.8507 61.7335C79.1872 62.1818 78.8554 62.9828 79.0076 63.7689L82.3793 81.1844C82.6483 82.5738 81.4273 83.7948 80.0379 83.5258L62.6224 80.1541C61.8363 80.0019 61.0353 80.3337 60.5871 80.9972L50.6566 95.6959C49.8643 96.8686 48.1376 96.8686 47.3454 95.6959L37.4149 80.9972C36.9667 80.3337 36.1656 80.0019 35.3795 80.1541L17.9641 83.5258C16.5746 83.7948 15.3537 82.5738 15.6227 81.1844L18.9943 63.7689C19.1465 62.9828 18.8147 62.1818 18.1512 61.7335L3.45253 51.8031C2.27985 51.0108 2.27986 49.2841 3.45254 48.4918L18.1512 38.5614C18.8147 38.1131 19.1465 37.3121 18.9943 36.526L15.6227 19.1105C15.3537 17.7211 16.5746 16.5002 17.9641 16.7692L35.3795 20.1408C36.1656 20.293 36.9667 19.9612 37.4149 19.2977L47.3454 4.59902Z"
        fill={color}
      />
      <path
        d="M49.0019 18.1543L62.8219 36.7463L81.4139 50.5663L62.8219 64.3863L49.0019 82.9783L35.1819 64.3863L16.5898 50.5663L35.1819 36.7463L49.0019 18.1543Z"
        fill="url(#paint0_linear_1054_615)"
      />
      <path
        d="M49.515 30.3006C49.3594 29.7991 48.6495 29.7991 48.4939 30.3006L44.2912 43.8441L38.1688 58.8031C37.964 59.3036 38.5467 59.7569 38.9815 59.4354L48.6866 52.2578C48.8755 52.1181 49.1335 52.1181 49.3224 52.2578L59.0275 59.4354C59.4622 59.7569 60.0449 59.3035 59.8401 58.8031L53.7178 43.8441L49.515 30.3006Z"
        fill="white"
      />
      <path
        d="M49.0044 60.5718L49.0044 67.2739"
        stroke="white"
        strokeWidth="2.87989"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1054_615"
          x1="1.70338"
          y1="28.067"
          x2="118.444"
          y2="101.523"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#557FE7" />
          <stop offset="1" stopColor="#8BA5E7" />
        </linearGradient>
      </defs>
    </svg>
  )
}
