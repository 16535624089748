import {
  optInPoolFx,
  optInPool,
  optOutPool,
  optOutPoolFx,
  claimPool,
  claimPoolFx,
  refund,
  refundFx,
  buy,
  buyFx,
  checkOptIn,
  checkOptInFx,
  fetchWalletBalancesFx,
  registerToThePool,
  registerToThePoolFx,
  optIntoAsset,
  optIntoAssetFx,
} from './'
import { sample, forward, combine } from 'effector'
import { $userSession } from 'models/user'
import { $algodClient, $algodIndexer } from 'models/algo'
import { toDecimal } from 'utils/numbers'
import Decimal from 'decimal.js'
import isEmpty from 'lodash/isEmpty'
import { $stakingInfo, $pools } from 'models/app'

const $poolActionEssentials = combine(
  $algodClient,
  $userSession,
  (algodClient, userSession) => ({
    algodClient,
    userSession,
  })
)

sample({
  clock: optIntoAsset,
  source: $poolActionEssentials,
  fn: ({ algodClient, userSession }, { assetId, signTransactions }) => ({
    algodClient,
    from: userSession.address,
    assetID: assetId,
    signTransactions,
  }),
  filter: ({ userSession }) => !isEmpty(userSession.address),
  target: optIntoAssetFx,
})

sample({
  clock: optInPool,
  source: $poolActionEssentials,
  fn: (
    { algodClient, userSession },
    { poolId, isPrivate, signTransactions }
  ) => ({
    algodClient,
    from: userSession.address,
    appID: Number(poolId),
    signTransactions,
    poolId,
    isPrivate,
  }),
  filter: ({ userSession }) => !isEmpty(userSession.address),
  target: optInPoolFx,
})

sample({
  clock: optOutPool,
  source: $poolActionEssentials,
  fn: ({ algodClient, userSession }, { appId, signTransactions }) => ({
    algodClient,
    from: userSession.address,
    appID: Number(appId),
    signTransactions,
  }),
  filter: ({ userSession }) => !isEmpty(userSession.address),
  target: optOutPoolFx,
})

sample({
  clock: claimPool,
  source: $poolActionEssentials,
  fn: (
    { algodClient, userSession },
    { poolId, idoAssetID, signTransactions }
  ) => ({
    algodClient,
    from: userSession.address,
    poolAppID: Number(poolId),
    idoAssetID: Number(idoAssetID),
    signTransactions,
  }),
  filter: ({ userSession }) => !isEmpty(userSession.address),
  target: claimPoolFx,
})

sample({
  clock: refund,
  source: $poolActionEssentials,
  fn: (
    { algodClient, userSession },
    { poolId, targetTokenId, signTransactions }
  ) => ({
    algodClient,
    from: userSession.address,
    poolAppID: Number(poolId),
    quoteAssetID: Number(targetTokenId),
    signTransactions,
  }),
  filter: ({ userSession }) => !isEmpty(userSession.address),
  target: refundFx,
})

sample({
  clock: buy,
  source: $poolActionEssentials,
  fn: (
    { algodClient, userSession },
    {
      poolId,
      isPrivate,
      input,
      targetTokenId,
      targetTokenDecimals,
      signTransactions,
    }
  ) => {
    return {
      algodClient,
      userSession,
      isPrivate,
      amount: toDecimal(input)
        .mul(Decimal.pow(10, targetTokenDecimals))
        .toNumber(),
      poolId,
      quoteAssetID: Number(targetTokenId),
      signTransactions,
    }
  },
  filter: ({ userSession }) => !isEmpty(userSession.address),
  target: buyFx,
})

sample({
  clock: checkOptIn,
  source: {
    algodIndexer: $algodIndexer,
    session: $userSession,
  },
  fn: ({ algodIndexer, session }, poolId) => ({
    algodIndexer,
    from: session.address,
    poolId: Number(poolId),
  }),
  filter: ({ session }) => !isEmpty(session.address),
  target: checkOptInFx,
})

sample({
  clock: registerToThePool,
  source: {
    userSession: $userSession,
    pools: $pools,
  },
  fn: ({ userSession, pools }, { poolId }) => {
    const pool = pools.find((pool) => pool.id === poolId)
    return {
      poolAddress: pool?.address ?? '',
      session: userSession.session,
    }
  },
  target: registerToThePoolFx,
})

forward({
  from: $stakingInfo,
  to: fetchWalletBalancesFx,
})
