import { sample } from 'effector'
import { $stakingInfo } from 'models/app'

import {
  $algodClient,
  $optInAddressesPending,
  addOptInAddressToPending,
  createAccountsDataFx,
  removeOptInAddressFromPending,
  signTransaction,
  signTransactionFx,
} from '.'

// $accounts
$stakingInfo.watch(() => {
  createAccountsDataFx()
})

$optInAddressesPending
  .on(addOptInAddressToPending, (state, address) => {
    return [...state, address]
  })
  .on(removeOptInAddressFromPending, (state, address) => {
    return state.filter((addr) => addr !== address)
  })

sample({
  clock: signTransaction,
  source: {
    algodClient: $algodClient,
  },
  fn: ({ algodClient }, { transactions, signTransactions }) => ({
    algodClient,
    transactions,
    signTransactions,
  }),
  target: signTransactionFx,
})

// sample({
//   source: createAccountsDataFx,
//   fn: (accountsData: AccountsData) => {
//     return accountsData
//   },
//   target: setAccountsData,
// })

// 1. 18a9c8d21312eb0c1468594b6072286053d6017d1c212877af038514fbfc5d3f
// 2. 10.10.0.128:8980
// import { guard } from 'effector'
// import {
//   openConnectWalletModal,
//   openConnectWalletSuccessModal,
// } from 'models/flow'
// import { WalletStatus } from 'utils/wallets'
// import {
//   $connected,
//   $walletStatus,
//   connectWallet,
//   connectWalletFx,
//   setWalletStatus,
// } from '.'

// connectWalletFx.watch((meta) =>
//   setWalletStatus({ name: meta.name, status: WalletStatus.Pending })
// )
// connectWalletFx.done.watch(({ params }) => {
//   openConnectWalletSuccessModal()
//   setWalletStatus({ name: params.name, status: WalletStatus.Connected })
// })
// connectWalletFx.fail.watch(({ params }) => {
//   setWalletStatus({ name: params.name, status: WalletStatus.Error })
// })

// $walletStatus
//   .on(setWalletStatus, (state, payload) => {
//     state[payload.name] = payload.status
//     return { ...state }
//   })
//   .on(openConnectWalletModal, (walletStatus) => {
//     for (const wallet in walletStatus) {
//       if (walletStatus[wallet] === WalletStatus.Error) {
//         setWalletStatus({ name: wallet, status: WalletStatus.NotConnected })
//       }
//     }
//   })

// guard({
//   clock: connectWallet, // on connectWallet
//   filter: $connected.map((connected) => !connected), // if is NOT connected
//   target: openConnectWalletModal, // open connect wallet modal
// })

// guard({
//   clock: connectWallet, // on connectWallet
//   filter: $connected, // if is connected
//   target: openConnectWalletSuccessModal, // open wallet modal
// })
