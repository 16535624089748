import { getSdk } from 'gqlgen/public'
import { GraphQLClient } from 'graphql-request'

const endpoint =
  process.env.REACT_APP_GRAPHQL_PUBLIC_ENDPOINT ??
  'https://api.adao.dev/graphql'

const authorization = process.env.REACT_APP_GRAPHQL_PUBLIC_AUTHORIZATION ?? ''

const clientPublic = new GraphQLClient(endpoint, {
  headers: {
    Authorization: authorization,
  },
})

export const graphqlSdk = getSdk(clientPublic)
