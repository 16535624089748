import Decimal from 'decimal.js'
import { sample } from 'effector'
import isEmpty from 'lodash/isEmpty'
import { $algodClient, $algodIndexer } from 'models/algo'
import { $stakingInfo } from 'models/app'
import { $userSession } from 'models/user'
import { toDecimal } from 'utils/numbers'
import {
  stake,
  stakeFx,
  unstake,
  unstakeFx,
  claim,
  claimFx,
  $stakingTokenDecimals,
} from './'

sample({
  clock: stake,
  source: {
    algodClient: $algodClient,
    session: $userSession,
    stakingInfo: $stakingInfo,
    stakingTokenDecimals: $stakingTokenDecimals,
  },
  fn: (
    { algodClient, session, stakingInfo, stakingTokenDecimals },
    { stakeAmount, signTransactions }
  ) => {
    const stakingAppID = Number(process.env.REACT_APP_STAKING_APP_ID)
    const assetID = Number(stakingInfo.stakingToken.address)

    const amount = toDecimal(stakeAmount)
      .mul(Decimal.pow(10, stakingTokenDecimals))
      .toNumber()

    return {
      algodClient,
      from: session.address,
      stakingAppID,
      amount,
      assetID,
      signTransactions,
    }
  },
  filter: ({ session }) => !isEmpty(session.address),
  target: stakeFx,
})

sample({
  clock: unstake,
  source: {
    algodClient: $algodClient,
    session: $userSession,
    stakingInfo: $stakingInfo,
    algodIndexer: $algodIndexer,
    stakingTokenDecimals: $stakingTokenDecimals,
  },
  fn: (
    { algodClient, session, stakingInfo, algodIndexer, stakingTokenDecimals },
    { unstakeAmount, signTransactions }
  ) => {
    const stakingAppID = Number(process.env.REACT_APP_STAKING_APP_ID)
    const assetID = Number(stakingInfo.stakingToken.address)
    const unstakingFeeRatio = stakingInfo.unstakingFeeRatio

    const amount = toDecimal(unstakeAmount)
      .mul(Decimal.pow(10, stakingTokenDecimals))
      .toNumber()

    return {
      algodClient,
      from: session.address,
      stakingAppID,
      amount,
      assetID,
      unstakingFeeRatio,
      algodIndexer,
      signTransactions,
    }
  },
  filter: ({ session }) => !isEmpty(session.address),
  target: unstakeFx,
})

sample({
  clock: claim,
  source: {
    algodClient: $algodClient,
    session: $userSession,
    stakingInfo: $stakingInfo,
  },
  fn: ({ algodClient, session, stakingInfo }, { signTransactions }) => {
    const stakingAppID = Number(process.env.REACT_APP_STAKING_APP_ID)
    const rewardAssetID = Number(stakingInfo.rewardToken.address)

    return {
      from: session.address,
      algodClient,
      stakingAppID,
      rewardAssetID,
      signTransactions,
    }
  },
  filter: ({ session }) => !isEmpty(session.address),
  target: claimFx,
})
