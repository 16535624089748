import { createEvent, createStore, restore } from 'effector'

export enum TxStatus {
  Empty = 'Empty',
  Failed = 'Failed',
  Pending = 'Pending',
  Success = 'Success',
}

export enum ModalState {
  TxWait,
  TxSuccess,
  TxFailure,
  Closed,
  ConnectWallet,
  ConnectWalletWait,
  ConnectWalletSign,
  ConnectWalletChoiceAccount,
  ConnectWalletSelectAccount,
  ConnectWalletSuccess,
  ConnectWalletFailed,
  AboutKYC,
  RegistrationSuccess,
  RegistrationConfirmation,
  OptInPool,
  OptInToAsset,
  RegistrationSign,
  UnstakeWarning,
}

export const approveStaking = createEvent()
export const claimStakingReward = createEvent()

export const openConnectWalletModal = createEvent()
export const openConnectWalletWaitModal = createEvent()
export const openConnectWalletSign = createEvent()
export const openConnectChoiceAccountModal = createEvent()
export const openConnectWalletSelectAccountModal = createEvent()
export const openConnectWalletSuccessModal = createEvent()
export const openAboutKYCModal = createEvent()
export const openConnectWalletFailedModal = createEvent<string | undefined>()
export const openUnstakeWarningModal = createEvent<string>()

export const openRegistrationModal = createEvent()
export const setTxHash = createEvent<string>()
export const setTxStatus = createEvent<TxStatus>()
export const openTxWaitModal = createEvent()
export const openTxSuccessModal = createEvent()
export const openTxFailModal = createEvent<string | undefined>()
export const openRegistrationConfirmationModal = createEvent()
export const openRegistrationSignModal = createEvent()
export const openOptInPoolModal = createEvent()
export const openOptIntoAssetModal = createEvent()
export const closeModal = createEvent()

export const setPoolId = createEvent<string>()

export const $modalState = createStore<ModalState>(ModalState.Closed)
export const $txHash = restore(setTxHash, '')
export const $txStatus = restore(setTxStatus, TxStatus.Empty)
export const $txErrorMsg = restore(openTxFailModal, '')
export const $connectWalletFailedMsg = restore(openConnectWalletFailedModal, '')
export const $showRain = createStore(false)
export const $poolId = restore(setPoolId, null)
export const $unstakeAmount = restore(openUnstakeWarningModal, '0')
