import { Buffer } from 'buffer'
import { createEffect, createEvent, restore } from 'effector'
import { persist } from 'effector-storage/session'
import isEmpty from 'lodash/isEmpty'
import {
  $algodClient,
  $algosdk,
  // $walletClient,
  AccountData,
  Accounts,
  createAccountsDataFx,
} from 'models/algo'
import { fetchGeneralInfoFx } from 'models/app'
import {
  openConnectWalletFailedModal,
  openConnectWalletModal,
  openConnectWalletSign,
  openConnectWalletSuccessModal,
  openConnectChoiceAccountModal,
} from 'models/flow'
import { graphqlSdk } from 'utils/consts'
import { WalletMeta } from 'utils/wallets'

export type UserSession = {
  address: string
  session: string
  shortAddress: string
}

export const EMPTY_USER_SESSION = {
  address: '',
  session: '',
  shortAddress: '',
}

export const setUserTier = createEvent<number>()
export const $userTier = restore(setUserTier, 0)

export const setUserSession = createEvent<UserSession>()
export const $userSession = restore(setUserSession, EMPTY_USER_SESSION)
persist({ store: $userSession, key: 'userSession' })

export const setUserAccount = createEvent<AccountData>()
export const $userAccount = restore(setUserAccount, {})
persist({ store: $userAccount, key: 'userAccount' })

export const $connected = $userSession.map(
  (userSession) => userSession.address !== ''
)

type SignTransactions = (
  transactions: Uint8Array[] | Uint8Array[][],
  indexesToSign?: number[] | undefined,
  returnGroup?: boolean | undefined
) => Promise<Uint8Array[]>

export const authFx = createEffect(
  async ({
    address,
    signTransactions,
  }: {
    address: string
    signTransactions: SignTransactions
  }) => {
    try {
      openConnectWalletSign()

      const algodClient = $algodClient.getState()
      // const walletClient = $walletClient.getState()
      const algosdk = $algosdk.getState()

      const authMessageData = await graphqlSdk.GetAuthMessage({
        address: address,
        blockchain: 'algorand',
      })
      const authMessage = authMessageData.getAuthMessage
      const params = await algodClient.getTransactionParams().do()
      const txn = algosdk.makePaymentTxnWithSuggestedParamsFromObject({
        suggestedParams: {
          ...params,
        },
        from: address,
        to: address,
        amount: 0,
        note: new Uint8Array(Buffer.from(authMessage)),
      })

      const encodedTransaction = algosdk.encodeUnsignedTransaction(txn)
      const signedTxn = await signTransactions([encodedTransaction])
      const sign = Buffer.from(signedTxn[0]).toString('base64')

      let authData = await graphqlSdk.Auth({
        input: { sign, authMessage: authMessage, blockchain: 'algorand' },
      })

      if (authData?.auth.session) {
        setUserSession({
          session: `${authData?.auth.session}`,
          address,
          shortAddress: `${address.slice(0, 4)}...${address.slice(-3)}`,
        })
        await createAccountsDataFx()
        await fetchGeneralInfoFx()
        openConnectWalletSuccessModal()
      } else {
        setUserSession(EMPTY_USER_SESSION)
      }
    } catch (err) {
      let msg = ''
      if (
        err &&
        err instanceof Error &&
        err.message &&
        err.message.includes('error verify signature')
      ) {
        msg = 'Error verifying signature. Check the wallet for being rekeyed.'
      }
      openConnectWalletFailedModal(msg)

      console.error('err', err)

      throw Error()
    }
  }
)

// export const connectWallet = createEvent()
export const connectWalletFx = createEffect(
  ({
    accounts,
    currentWallet,
    address,
  }: {
    accounts: Accounts
    currentWallet: WalletMeta
    address: string
  }) => {
    if (accounts.length && !isEmpty(currentWallet) && !isEmpty(address)) {
      openConnectWalletSuccessModal()
      return
    }

    if (accounts.length > 0 && !isEmpty(currentWallet) && isEmpty(address)) {
      openConnectChoiceAccountModal()
      return
    }

    openConnectWalletModal()
  }
)
