interface Props {
  color: string
  className?: string
}

export default function Tier2({ className = '' }: Props) {
  return (
    <svg
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w4.org/2000/svg"
      className={className}
    >
      <path
        d="M48.194 7.13625C49.3128 6.49585 50.6872 6.49585 51.806 7.13625L86.1704 26.8053C87.3019 27.453 88 28.6569 88 29.9607V69.2444C88 70.5482 87.3019 71.7521 86.1704 72.3998L51.806 92.0688C50.6872 92.7092 49.3128 92.7092 48.194 92.0688L13.8296 72.3998C12.6981 71.7521 12 70.5482 12 69.2444V29.9607C12 28.6569 12.6981 27.453 13.8296 26.8053L48.194 7.13625Z"
        fill="#51555E"
      />
      <path
        d="M49.0969 21.9895C49.6563 21.6693 50.3435 21.6693 50.9029 21.9895L73.6581 35.0138C74.2238 35.3377 74.5729 35.9396 74.5729 36.5915V62.613C74.5729 63.2649 74.2238 63.8668 73.6581 64.1907L50.9029 77.215C50.3435 77.5352 49.6563 77.5352 49.0969 77.215L26.3418 64.1907C25.776 63.8668 25.4269 63.2649 25.4269 62.613V36.5915C25.4269 35.9396 25.776 35.3377 26.3418 35.0138L49.0969 21.9895Z"
        fill="white"
        fillOpacity="0.1"
        stroke="white"
        strokeWidth="1.81782"
      />
      <path
        d="M80.0337 47.2817L80.0337 62.6497"
        stroke="white"
        strokeWidth="1.81782"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.3745 36.585V44.2299"
        stroke="white"
        strokeWidth="1.81782"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.4724 33.889C50.3532 33.3915 49.6455 33.3915 49.5264 33.889L45.7096 49.8293C45.697 49.8815 45.676 49.9313 45.6472 49.9766L38.6208 61.0495C38.3512 61.4742 38.825 61.9787 39.2658 61.7363L49.765 55.9625C49.9109 55.8822 50.0878 55.8822 50.2337 55.9625L60.7329 61.7363C61.1737 61.9787 61.6475 61.4742 61.3779 61.0495L54.3515 49.9766C54.3227 49.9313 54.3017 49.8815 54.2892 49.8293L50.4724 33.889Z"
        fill="#FBFBFB"
      />
    </svg>
  )
}
