import React from 'react'
import { useTheme } from 'themeContext'

interface Props {
  children: React.ReactNode
  className?: string
}

export default function ModalTitle({ children, className = '' }: Props) {
  const dark = useTheme()

  const cn = dark ? 'text-white' : 'text-black'

  return (
    <p
      className={`${cn} ${
        className ?? ''
      } text-center uppercase mb-[1.75rem] leading-none text-[1.6rem]`}
    >
      {children}
    </p>
  )
}
