import { useNavigate } from '@reach/router'
import noop from 'lodash/noop'
import { useTheme } from 'themeContext'

interface Props {
  children: React.ReactNode
  href?: string
  onClick?(): void
}

export default function TextLinkModal({
  href,
  children,
  onClick = noop,
}: Props) {
  const navigate = useNavigate()
  const isInternal = href && href.startsWith('/')
  const dark = useTheme()

  const handleClick = () => {
    if (isInternal) {
      navigate(href)
    } else {
      if (href) {
        window.open(href, '_blank', 'noopener,noreferrer')
      } else if (onClick) {
        onClick()
      }
    }
  }

  const cn = {
    base: 'whitespace-nowrap inline underline cursor-pointer text-[1.1rem] underline-offset-4 decoration-grayLight hover:text-grayLight/60',
    color: dark ? 'text-white' : 'text-black',
  }

  return (
    <div className={`${cn.base} ${cn.color}`} onClick={handleClick}>
      {children}
    </div>
  )
}
