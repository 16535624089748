import { useStore } from 'effector-react'
import { ReactComponent as CloseModalIcon } from 'icons/close-modal.svg'
import { $modalState, $showRain, closeModal, ModalState } from 'models/flow'
import { ReactNode, useLayoutEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import { useTheme } from 'themeContext'
import useRect from 'utils/hooks/useRect'
import useResize from 'utils/hooks/useResize'
import useOutsideClick from 'utils/hooks/useOutsideClick'

interface Props {
  children: ReactNode
}

const portal = document.getElementById('modal')
const root = document.getElementById('root')

export default function ModalContainer({ children }: Props) {
  const modalRef = useRef<HTMLDivElement>(null)
  const showModal = useStore($modalState.map((ms) => ms !== ModalState.Closed))
  const showRain = useStore($showRain)
  const dark = useTheme()

  useOutsideClick(modalRef, () => {
    if (showModal) {
      closeModal()
    }
  })

  const cn = {
    bg: dark ? 'bg-black3' : 'bg-white',
    w: 'w-[20rem] mm:w-[22rem] ml:w-[32rem] t:w-[36rem] dxs:w-[34.5rem]',
    p: 'px-4 mm:px-[1.5rem] t:px-[2.22rem] dxs:px-[3.1rem] pt-[1.9rem] pb-[2.5rem]',
    closeIconSize:
      'w-[2.2rem] h-[2.2em] t:w-[3rem] t:h-[3rem] dxs:w-[3.55rem] dxs:h-[3.55rem] ',
  }

  const { height: modalHeight } = useRect(modalRef, showModal)
  const { height: windowHeight } = useResize()

  const alignTop = modalHeight > windowHeight

  useLayoutEffect(() => {
    if (!root) {
      return
    }

    if (showModal) {
      root.classList.add('disable-scroll')
    } else {
      root.classList.remove('disable-scroll')
    }
  }, [showModal])

  if (!portal || !showModal) {
    return null
  }

  const content = (
    <>
      <div
        className={`
        absolute z-30 cursor-pointer right-0 rotate-0 hover:rotate-[360deg] 
        transition-transform translate-x-[0] dxs:translate-x-full -translate-y-[120%] dxs:-translate-y-[110%]
        `}
        onClick={() => closeModal()}
      >
        <CloseModalIcon className={cn.closeIconSize} />
      </div>
      <div className="relative">
        {showRain ? (
          <div className="overflow-hidden absolute w-full h-full z-[1] rounded-[0.5rem]">
            <div className="absolute w-full h-full longfazers">
              <span className="absolute h-[20%] w-[1px] bg-raindropGradient" />
              <span className="absolute h-[20%] w-[1px] bg-raindropGradient" />
              <span className="absolute h-[20%] w-[1px] bg-raindropGradient" />
              <span className="absolute h-[20%] w-[1px] bg-raindropGradient" />
            </div>
          </div>
        ) : null}
        <div
          className={`${cn.p} overflow-hidden relative z-[10]`}
          ref={modalRef}
        >
          {children}
        </div>
      </div>
    </>
  )

  const container = `
    flex flex-col ${alignTop ? 'justify-start' : 'justify-center'} 
    absolute w-screen h-screen z-100 flex flex-col items-center overflow-x-hidden overflow-y-scroll 
    pt-[5rem] t:pt-[8rem] pb-[3rem] dxs:pt-0 dxs:pb-0
  `

  return createPortal(
    <>
      <div className="relative h-auto">
        <div className={container}>
          <div
            className={`${cn.w} ${cn.bg} relative block modal rounded-[0.5rem] modal-shadow`}
          >
            {content}
          </div>
        </div>
        <div
          className={`
          fixed w-screen h-screen 
          ${dark ? 'bg-black/90' : 'bg-grayUltraLight/80'} 
          z-90 backdrop-filter backdrop-blur-sm
          `}
          onClick={() => closeModal()}
        />
      </div>
    </>,
    portal
  )
}
