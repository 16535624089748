import { ReactComponent as IconShowTier } from 'icons/tiers/show-tier.svg'
import { useState } from 'react'
import tiersMapFn from './tiersMap'

import { Button } from 'components'
import useResizeAnnimationStopper from 'utils/hooks/useResizeAnimationStopper'
import React from 'react'
import { TierUnion } from 'types'
import { useTheme } from 'themeContext'
import { $connected, $userTier } from 'models/user'
import { useStore } from 'effector-react'
import { useNavigate } from '@reach/router'
import { $stakingInfo } from 'models/app'
import { formatNumber, toDecimal } from 'utils/numbers'
import { openConnectWalletModal } from 'models/flow'

type YourTierProps = {
  tier?: TierUnion
  showButton?: boolean
}

export default function YourTier({ showButton = false }: YourTierProps) {
  const navigate = useNavigate()
  const userTier = useStore($userTier)
  const [tierIsOpen, setTierIsOpen] = useState(false)
  const dark = useTheme()

  const stakingInfo = useStore($stakingInfo)
  const tierInfo = stakingInfo.tiers.find(({ level }) => level === userTier)

  const animationStopperClass = useResizeAnnimationStopper()
  const connected = useStore($connected)
  const currentTier = !connected ? 'login' : userTier
  const cn = {
    nameColor:
      userTier === 0 ? 'text-grayLight' : dark ? 'text-white' : 'text-black',
    textColor: dark ? 'text-grayLight' : 'text-gray',
    imgOffsetRight: showButton || !connected ? 'right-[7rem]' : 'right-0',
    tierIconColor: dark ? 'text-blackLight' : 'text-grayMedium',
    tierBgColor: dark ? 'text-blackLight' : 'text-grayMedium',
  }

  const TIERS_MAP = React.useMemo(() => tiersMapFn(dark), [dark])

  const buttonHandler = () => {
    if (connected) {
      navigate('/staking')
    } else {
      openConnectWalletModal()
    }
  }

  const oddsMultiple = toDecimal(tierInfo?.participationTickets).eq(0)
    ? 'Guaranteed'
    : tierInfo?.participationTickets

  const allocationMultiple = tierInfo?.allocationMultiple
  const aboutStakingTiersLink =
    'https://algodao.gitbook.io/faq/algodao-introduction-and-faq/participation-in-idos#membership-tiers-and-idos'
  return (
    <>
      <div
        className={`
      dxl:max-w-[53rem] dxl:m-auto
      dxs:p-0 dxs:w-auto dxs:relative dxs:bg-opacity-0 dxs:right-0 dxs:no-shadow dxs:bottom-0
      p-[1.7rem] w-[20.5rem] z-40 fixed bg-white tier-block-shadow-mobile rounded-[2rem] 
      bottom-[5.55rem] right-[1.3rem] mm:right-[1.8rem] t:right-[3.3rem]
      transition-all duration-500 ${
        tierIsOpen ? 'translate-y-0' : 'translate-y-[130%] dxs:translate-y-0'
      } ${animationStopperClass}
    `}
      >
        <div className={`absolute ${cn.imgOffsetRight} hidden dxs:block`}>
          {TIERS_MAP[currentTier].bg}
        </div>
        <div className="relative flex flex-col dxs:flex-row items-center dxs:h-[9.9rem]">
          <div className="flex flex-col dxs:flex-row items-center dxs:w-[19.55rem] mb-[2rem] dxs:mb-0">
            <div
              className={` ${cn.tierIconColor} mb-[1.1rem] dxs:mb-0 dxs:mr-[2.15rem] w-[8.3rem] h-[8.3rem] dxs:w-[5rem] dxs:h-[5rem]`}
            >
              {TIERS_MAP[currentTier].icon}
            </div>
            <div className="text-center dxs:text-left">
              <div className={`${cn.textColor} leading-[100%] mb-[0.5rem]`}>
                Your tier
              </div>
              <div
                className={`uppercase text-[1.6rem] leading-[100%] ${cn.nameColor}`}
              >
                {TIERS_MAP[currentTier].name}
              </div>
            </div>
          </div>
          <Separator dark={dark} />
          <div
            className={`${cn.textColor} w-[19.0rem] mb-[1.4rem] dxs:mb-0 text-[0.9rem] dxs:text-base`}
          >
            {!connected ? (
              <>
                <p>
                  Connect wallet to stake{' '}
                  {stakingInfo?.stakingToken?.symbol ?? ''} and participate in
                  IDOs.
                </p>
                <p>
                  Read more{' '}
                  <a
                    target="_blank"
                    href={aboutStakingTiersLink}
                    className="underline cursor-pointer hover:no-underline text-grayLight"
                    rel="noreferrer"
                  >
                    about staking tiers.
                  </a>
                </p>
              </>
            ) : tierInfo ? (
              <>
                <p>
                  Pool Weight Multiplier —{' '}
                  <span className="text-blue font-aldrich">
                    x{allocationMultiple}
                  </span>
                </p>
                <p>
                  Participation Odds Multiple —{' '}
                  <span className="text-blue font-aldrich">{oddsMultiple}</span>
                </p>
                <p>
                  Your stake —{' '}
                  <span className="text-blue font-aldrich">
                    {formatNumber(stakingInfo?.account?.stakingBalance)}{' '}
                    {stakingInfo?.stakingToken?.symbol}
                  </span>
                </p>
              </>
            ) : null}
            {connected && userTier === 0 && (
              <>
                <p>You don’t have sufficient stake to participate in IDOs.</p>
                <p>
                  Read more{' '}
                  <a
                    target="_blank"
                    href={aboutStakingTiersLink}
                    className="underline cursor-pointer hover:no-underline text-grayLight"
                    rel="noreferrer"
                  >
                    about staking tiers.
                  </a>
                </p>
              </>
            )}
          </div>
          {showButton || !connected ? (
            <Button
              color="blue"
              className="w-full dxs:w-[9.4rem] dxs:ml-auto"
              onClick={buttonHandler}
            >
              {!connected ? 'Connect wallet' : 'Stake'}
            </Button>
          ) : null}
        </div>
      </div>
      <div
        className={`
          flex items-center
          bottom-[0.66rem] right-[1.3rem] mm:right-[1.8rem] t:right-[3.3rem] 
          dxs:hidden fixed z-50 bg-white h-[4.3rem] rounded-[3rem] w-[20.5rem]
        `}
      >
        <Button
          color="blue"
          className={`
            px-[0.4rem] tier-button-shadow w-[6.9rem] h-[3.7rem] rounded-[2rem] relative left-[0.3rem] hover:bg-blueButtonGradient
            ${tierIsOpen ? 'opacity-60' : 'opacity-100'}
          `}
          onClick={() => {
            setTierIsOpen(!tierIsOpen)
          }}
        >
          <div className="flex items-center justify-between w-full">
            <div
              className={`${
                tierIsOpen
                  ? '-translate-x-[130%] -rotate-180'
                  : 'translate-x-0 rotate-0'
              } h-[2.8rem] transition-transform duration-500 w-[2.8rem] ${animationStopperClass}`}
            >
              {TIERS_MAP[currentTier].icon}
            </div>
            <IconShowTier
              className={`
                w-[1rem] h-[1rem] relative -left-[0.8rem]
                ${
                  tierIsOpen
                    ? '-rotate-180 -translate-x-[150%] scale-150'
                    : 'rotate-0'
                }
                transition-transform duration-500 ${animationStopperClass}
              `}
            />
          </div>
        </Button>
        <div className="ml-[2rem] uppercase">{TIERS_MAP[currentTier].name}</div>
      </div>
    </>
  )
}

const Separator = ({ dark = false }: { dark: boolean }) => (
  <div
    className={`w-[1px] h-[3.6rem] ${
      dark ? 'bg-blackLight' : 'bg-[#ddd]'
    } ml-[0.9rem] mr-[1.9rem] hidden dxs:block tier-block-shadow-mobile`}
  ></div>
)
