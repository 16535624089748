interface Props {
  color: string
  className?: string
}

export default function Tier4({ color, className = '' }: Props) {
  return (
    <svg
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w4.org/2000/svg"
      className={className}
    >
      <path
        d="M87.69 8.91071C89.2318 8.56816 90.6071 9.94352 90.2646 11.4853L81.9945 48.7073C81.926 49.0155 81.926 49.3351 81.9945 49.6433L90.2646 86.8653C90.6071 88.4071 89.2318 89.7824 87.69 89.4399L50.468 81.1698C50.1598 81.1013 49.8402 81.1013 49.532 81.1698L12.31 89.4399C10.7682 89.7824 9.39287 88.4071 9.73542 86.8653L18.0055 49.6433C18.074 49.3351 18.074 49.0155 18.0055 48.7073L9.73542 11.4853C9.39287 9.94352 10.7682 8.56816 12.31 8.91071L49.532 17.1808C49.8402 17.2493 50.1598 17.2493 50.468 17.1808L87.69 8.91071Z"
        fill={color}
      />
      <path
        d="M50.02 27.6494L60.5507 38.7023L71.6036 49.2329L60.5507 59.7636L50.02 70.8165L39.4894 59.7636L28.4365 49.2329L39.4894 38.7023L50.02 27.6494Z"
        fill="#D2D2D2"
      />
      <path
        d="M50.6712 27.7972C50.473 27.1293 49.5271 27.1293 49.329 27.7972L43.6082 47.0839L35.2464 68.4582C34.9876 69.1197 35.7649 69.7015 36.3267 69.2668L49.5717 59.0188C49.8239 58.8237 50.1762 58.8237 50.4284 59.0188L63.6734 69.2668C64.2352 69.7015 65.0125 69.1197 64.7537 68.4582L56.3919 47.0839L50.6712 27.7972Z"
        fill="url(#paint0_linear_1054_614)"
      />
      <path
        d="M37.3384 52.6426L31.3104 65.8681"
        stroke="#6587DA"
        strokeWidth="2.15789"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1054_614"
          x1="72.8762"
          y1="32.459"
          x2="6.63298"
          y2="61.3189"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#557FE7" />
          <stop offset="1" stopColor="#8BA5E7" />
        </linearGradient>
      </defs>
    </svg>
  )
}
