import { useNavigate } from '@reach/router'
import noop from 'lodash/noop'
import { useTheme } from 'themeContext'

interface Props {
  children: React.ReactNode
  href?: string
  onClick?(): void
  className?: string
}

export default function TextLink({
  href,
  children,
  className = '',
  onClick = noop,
}: Props) {
  const navigate = useNavigate()
  const isInternal = href && href.startsWith('/')
  const dark = useTheme()

  const handleClick = () => {
    if (isInternal) {
      navigate(href)
    } else {
      if (href) {
        window.open(href, '_blank', 'noopener,noreferrer')
      } else if (onClick) {
        onClick()
      }
    }
  }

  return (
    <div
      className={`
      ${className}
inline
      text-base leading-[1.25rem] cursor-pointer
      underline underline-offset-[0.25rem] decoration-grayLight hover:text-grayLight/60
      ${dark ? 'text-white' : ' text-black/75'}
      `}
      onClick={handleClick}
    >
      {children}
    </div>
  )
}
