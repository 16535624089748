import Logo from 'components/Logo'
import { ModalTitle } from 'components'
import Button from 'components/Button'
import { $txErrorMsg, closeModal } from 'models/flow'
import { t } from 'utils'
import { useTheme } from 'themeContext'
import { useStore } from 'effector-react'

export default function TxFailureModal() {
  const dark = useTheme()
  const msg = useStore($txErrorMsg)

  const handleClick = () => {
    closeModal()
  }

  return (
    <div className="flex flex-col flex-grow">
      <ModalTitle className="!text-red text-center">
        {t('modal_tx_error_heading')}
      </ModalTitle>
      <p
        className={`text-center font-mono ${
          dark ? 'text-white' : 'text-black'
        } leading-[1.35rem] text-base`}
      >
        {msg ? msg : 'Something went wrong'}
      </p>
      <div className="flex flex-col items-center">
        <Logo
          bgFill="#a3a3a3"
          className="text-[#c9c9c9] w-[6rem] h-[6.5rem] mt-[2rem] mb-[2.4rem] "
        />
      </div>
      <Button className="w-full" onClick={handleClick} type="slide">
        Close
      </Button>
    </div>
  )
}
