import {
  $modalState,
  $showRain,
  closeModal,
  ModalState,
  openAboutKYCModal,
  openConnectWalletFailedModal,
  openConnectWalletModal,
  openConnectWalletSelectAccountModal,
  openConnectWalletSign,
  openConnectWalletSuccessModal,
  openConnectWalletWaitModal,
  openConnectChoiceAccountModal,
  openOptInPoolModal,
  openOptIntoAssetModal,
  openRegistrationConfirmationModal,
  openRegistrationModal,
  openRegistrationSignModal,
  openTxFailModal,
  openTxSuccessModal,
  openTxWaitModal,
  openUnstakeWarningModal,
} from '.'

$modalState
  .on(openConnectWalletModal, () => ModalState.ConnectWallet)
  .on(openConnectWalletWaitModal, () => ModalState.ConnectWalletWait)
  .on(openConnectWalletSign, () => ModalState.ConnectWalletSign)
  .on(openConnectWalletSuccessModal, () => ModalState.ConnectWalletSuccess)
  .on(
    openConnectChoiceAccountModal,
    () => ModalState.ConnectWalletChoiceAccount
  )
  .on(
    openConnectWalletSelectAccountModal,
    () => ModalState.ConnectWalletSelectAccount
  )
  .on(openConnectWalletFailedModal, () => ModalState.ConnectWalletFailed)
  .on(openAboutKYCModal, () => ModalState.AboutKYC)
  .on(openTxFailModal, () => ModalState.TxFailure)
  .on(openTxSuccessModal, () => ModalState.TxSuccess)
  .on(openRegistrationModal, () => ModalState.RegistrationConfirmation)
  .on(openTxWaitModal, () => ModalState.TxWait)
  .on(openTxWaitModal, () => ModalState.TxWait)
  .on(openOptInPoolModal, () => ModalState.OptInPool)
  .on(openOptIntoAssetModal, () => ModalState.OptInToAsset)
  .on(closeModal, () => ModalState.Closed)
  .on(
    openRegistrationConfirmationModal,
    () => ModalState.RegistrationConfirmation
  )
  .on(openRegistrationSignModal, () => ModalState.RegistrationSign)
  .on(openUnstakeWarningModal, () => ModalState.UnstakeWarning)

$showRain
  .on(
    [
      openTxWaitModal,
      openConnectWalletWaitModal,
      openConnectWalletSign,
      openRegistrationSignModal,
    ],
    () => true
  )
  .on(
    [
      openConnectWalletModal,
      openConnectWalletSuccessModal,
      openConnectWalletSelectAccountModal,
      openConnectChoiceAccountModal,
      openAboutKYCModal,
      openRegistrationModal,
    ],
    () => false
  )
  .reset(...[closeModal])
