import React from 'react'

interface Props {
  disabled?: boolean
  onClick?: () => void
  className?: string
}

const ConnectWalletButton = React.forwardRef<HTMLButtonElement, Props>(
  (props, ref) => {
    const { disabled = false, onClick = () => {}, className = '' } = props

    const handleClick = (e: React.MouseEvent) => {
      if (disabled) {
        e.preventDefault()
        e.stopPropagation()
        return
      }
      onClick()
    }

    const cn = {
      connect: `
      overflow-hidden group bg-red px-[2rem] h-[3rem] text-[1.3rem] uppercase w-full text-white button-dark-shadow rounded-base
      ${disabled ? 'bg-grayLightGradient button-dark-shadow' : ''}
    `,
    }

    return (
      <button
        ref={ref}
        className={`relative flex items-center justify-center w-[80%] m-auto ${cn.connect} ${className}`}
        disabled={disabled}
        type="button"
        onClick={handleClick}
        onMouseLeave={() => {
          //@ts-ignore
          document.activeElement!.blur()
        }}
      >
        {!disabled && (
          <div
            className={`
              absolute h-full w-full top-0 left-0
            `}
          >
            <div>
              <div
                className={`
              absolute z-10 left-0 bottom-[0.15rem] bg-connectButtonGradient h-full w-[51%] bg-cover
              group-hover:-translate-x-full transition-transform duration-500
              `}
              ></div>
              <div
                className={`
              absolute z-10 right-0 bottom-[0.15rem] bg-connectButtonGradient h-full w-[51%] bg-cover
              group-hover:translate-x-full transition-transform duration-500
              `}
              ></div>
            </div>
            <div
              className={`
            absolute z-0 left-0 bg-connectButtonMaskLeft h-full w-[4.95rem] bg-cover
            group-focus:-translate-x-full transition-transform duration-500
            `}
            ></div>
            <div
              className={`
            absolute z-0 right-0 bg-connectButtonMaskRight h-full w-[5.05rem] bg-cover
            group-focus:translate-x-full transition-transform duration-500
            `}
            ></div>
          </div>
        )}
        <div className={`relative z-20 w-full`}>
          {
            <div>
              Connect{' '}
              <span className="text-red group-hover:text-white group-focus:text-white transition-color duration-500">
                wallet
              </span>
            </div>
          }
        </div>
      </button>
    )
  }
)

export default ConnectWalletButton
