import { GraphQLClient } from 'graphql-request'
import * as Dom from 'graphql-request/dist/types.dom'
import gql from 'graphql-tag'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  _FieldSet: string
  Address: string
  Blockchain: string
  Decimal: string
  Hash: string
  MerkleHash: string
  PoolID: string
  Session: string
  Time: string
}

export enum AccessType {
  Private = 'Private',
  Public = 'Public',
}

export type AccountVesting = {
  __typename?: 'AccountVesting'
  available: Array<Scalars['Decimal']>
  claimed: Array<Scalars['Decimal']>
  totalAvailable: Scalars['Decimal']
  totalClaimed: Scalars['Decimal']
  unlocks: Array<Scalars['Decimal']>
}

export type AuthInput = {
  authMessage: Scalars['String']
  blockchain: Scalars['Blockchain']
  sign: Scalars['String']
}

export type AuthResult = {
  __typename?: 'AuthResult'
  address: Scalars['Address']
  session: Scalars['Session']
}

export type Balance = {
  __typename?: 'Balance'
  amount: Scalars['Decimal']
  token: Token
}

export type Epoch = {
  __typename?: 'Epoch'
  rewardsPerSecond: Scalars['Decimal']
  startTime: Scalars['Time']
}

export type FixedPricePool = Pool & {
  __typename?: 'FixedPricePool'
  accessType: AccessType
  account?: Maybe<PoolAccount>
  address: Scalars['Address']
  blockchain: Scalars['Blockchain']
  currentTime: Scalars['Time']
  id: Scalars['PoolID']
  idoToken: Token
  meta: PoolMeta
  minOrderSizeTargetToken: Scalars['Decimal']
  pipeline: PoolPipeline
  poolStatus: PoolStatus
  poolType: PoolType
  price?: Maybe<Scalars['Decimal']>
  priceReverse?: Maybe<Scalars['Decimal']>
  progress: PoolProgress
  projectAdmin?: Maybe<Scalars['Address']>
  stakingAddress: Scalars['Address']
  statistics: PoolStatistics
  targetToken?: Maybe<Token>
  tiers: Array<Tier>
  verifiedStatus: VerifiedStatus
  vesting?: Maybe<Vesting>
  waves?: Maybe<PoolWaves>
  whitelistAddress: Scalars['Address']
}

export type GetMerkleProofResponse = {
  __typename?: 'GetMerkleProofResponse'
  proof: Array<Scalars['MerkleHash']>
  tier: Scalars['Int']
  wave: Scalars['Int']
}

export type KycAccountInfo = {
  __typename?: 'KYCAccountInfo'
  lastSyncTime: Scalars['Time']
  rejectType?: Maybe<KycRejectType>
  resubmitLink: Scalars['String']
  status: KycStatus
}

export type KycInfo = {
  __typename?: 'KycInfo'
  account?: Maybe<KycAccountInfo>
  generalLink: Scalars['String']
  supportLink: Scalars['String']
  usInvestorsLink: Scalars['String']
}

export enum KycRejectType {
  Final = 'Final',
  Retry = 'Retry',
}

export enum KycStatus {
  Approved = 'Approved',
  Empty = 'Empty',
  Rejected = 'Rejected',
}

export type Member = {
  __typename?: 'Member'
  avatar: Scalars['String']
  linkedIn: Scalars['String']
  name: Scalars['String']
  position: Scalars['String']
}

export type Mutation = {
  __typename?: 'Mutation'
  auth: AuthResult
  getAuthMessage: Scalars['String']
  registerPoolParticipation: PoolParticipationResponse
}

export type MutationAuthArgs = {
  input: AuthInput
}

export type MutationGetAuthMessageArgs = {
  address: Scalars['Address']
  blockchain: Scalars['Blockchain']
}

export type MutationRegisterPoolParticipationArgs = {
  input: RegisterPoolParticipationInput
}

export type Pool = {
  /** @deprecated use meta.private */
  accessType: AccessType
  account?: Maybe<PoolAccount>
  address: Scalars['Address']
  blockchain: Scalars['Blockchain']
  currentTime: Scalars['Time']
  id: Scalars['PoolID']
  idoToken: Token
  meta: PoolMeta
  minOrderSizeTargetToken: Scalars['Decimal']
  pipeline: PoolPipeline
  poolStatus: PoolStatus
  poolType: PoolType
  price?: Maybe<Scalars['Decimal']>
  /** @deprecated No longer supported */
  priceReverse?: Maybe<Scalars['Decimal']>
  progress: PoolProgress
  projectAdmin?: Maybe<Scalars['Address']>
  /** @deprecated No longer supported */
  stakingAddress: Scalars['Address']
  statistics: PoolStatistics
  targetToken?: Maybe<Token>
  tiers: Array<Tier>
  /** @deprecated use meta.isIncubated */
  verifiedStatus: VerifiedStatus
  vesting?: Maybe<Vesting>
  waves?: Maybe<PoolWaves>
  whitelistAddress: Scalars['Address']
}

export type PoolAccount = {
  __typename?: 'PoolAccount'
  account: Scalars['String']
  amountPaid: Scalars['Decimal']
  /** @deprecated use vesting.totalClaimed */
  claimAmount: Scalars['Decimal']
  idoTokenBalance: Scalars['Decimal']
  isRegistered: Scalars['Boolean']
  isWhitelisted?: Maybe<Scalars['Boolean']>
  refundAmount: Scalars['Decimal']
  targetTokenBalance: Scalars['Decimal']
  tier?: Maybe<Tier>
  userToReserve: Scalars['Decimal']
  /** Account vesting information. Available for SoldOut and SuccessfullyFinished pool status only. */
  vesting?: Maybe<AccountVesting>
  /** @deprecated use account.idoTokenBalance instead */
  walletBalance: Scalars['Decimal']
  wave?: Maybe<Scalars['Int']>
  waveStartTime?: Maybe<Scalars['Time']>
  withdrawnAmount: Scalars['Decimal']
}

export type PoolMeta = {
  __typename?: 'PoolMeta'
  ROIAllTimeHigh?: Maybe<Scalars['Decimal']>
  auditReport?: Maybe<Scalars['String']>
  brandBackground?: Maybe<Scalars['String']>
  brandLogo?: Maybe<Scalars['String']>
  coingecko?: Maybe<Scalars['String']>
  coinmarketcap?: Maybe<Scalars['String']>
  constantUSDTokenPrice?: Maybe<Scalars['Decimal']>
  constantUSDTotalTarget?: Maybe<Scalars['Decimal']>
  coolOffExplainedLink: Scalars['String']
  description: Scalars['String']
  discord?: Maybe<Scalars['String']>
  dueDiligenceReport?: Maybe<Scalars['String']>
  /** @deprecated use constantUSDTokenPrice */
  idoTokenPriceUSD?: Maybe<Scalars['Decimal']>
  initialCirculationAmount?: Maybe<Scalars['Decimal']>
  initialMarketCap?: Maybe<Scalars['Decimal']>
  initialMarketCapUSD?: Maybe<Scalars['Decimal']>
  isIncubated?: Maybe<Scalars['Boolean']>
  isKYCRequired: Scalars['Boolean']
  markdown?: Maybe<Scalars['String']>
  medium?: Maybe<Scalars['String']>
  name: Scalars['String']
  participationExplainedLink: Scalars['String']
  position?: Maybe<Scalars['Int']>
  prelaunchStart?: Maybe<Scalars['Time']>
  priceAllTimeHigh?: Maybe<Scalars['Decimal']>
  private: Scalars['Boolean']
  registrationStart?: Maybe<Scalars['Time']>
  roughTokenPriceUSD?: Maybe<Scalars['Decimal']>
  secondaryMarkets: Array<SecondaryMarket>
  sourceCode?: Maybe<Scalars['String']>
  subtitle?: Maybe<Scalars['String']>
  tags: Array<Scalars['String']>
  team: Array<Member>
  telegram?: Maybe<Scalars['String']>
  tiersExplainedLink: Scalars['String']
  twitter?: Maybe<Scalars['String']>
  vestingDescription?: Maybe<Scalars['String']>
  video?: Maybe<Scalars['String']>
  visibility: Scalars['Boolean']
  website?: Maybe<Scalars['String']>
  whitelistingStart?: Maybe<Scalars['Time']>
  whitepaper?: Maybe<Scalars['String']>
}

export type PoolParticipationResponse = {
  __typename?: 'PoolParticipationResponse'
  reason?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type PoolPipeline = {
  __typename?: 'PoolPipeline'
  /** @deprecated use vesting.unlockCheckpoints[0] */
  claimTime?: Maybe<Scalars['Time']>
  finishTime?: Maybe<Scalars['Time']>
  prelaunchTime?: Maybe<Scalars['Time']>
  registrationTime?: Maybe<Scalars['Time']>
  startTime?: Maybe<Scalars['Time']>
  whitelistingTime?: Maybe<Scalars['Time']>
}

export type PoolProgress = {
  __typename?: 'PoolProgress'
  softCap?: Maybe<Scalars['Decimal']>
  softCapPercent?: Maybe<Scalars['Decimal']>
  tokensSoftCap?: Maybe<Scalars['Decimal']>
  tokensSold?: Maybe<Scalars['Decimal']>
  tokensToSell?: Maybe<Scalars['Decimal']>
  totalRaised?: Maybe<Scalars['Decimal']>
  totalTarget?: Maybe<Scalars['Decimal']>
  totalTargetUSD?: Maybe<Scalars['Decimal']>
}

export type PoolStatistics = {
  __typename?: 'PoolStatistics'
  /** @deprecated No longer supported */
  marketcapEstimate?: Maybe<Scalars['Decimal']>
  participantsCount: Scalars['Int']
  registeredCount: Scalars['Int']
}

export enum PoolStatus {
  ComingSoon = 'ComingSoon',
  Failed = 'Failed',
  InProgress = 'InProgress',
  Paused = 'Paused',
  Prelaunch = 'Prelaunch',
  Registration = 'Registration',
  SoldOut = 'SoldOut',
  SuccessfullyFinished = 'SuccessfullyFinished',
  Whitelisting = 'Whitelisting',
}

export enum PoolType {
  FixedPricePool = 'FixedPricePool',
}

export type PoolWavePipeline = {
  __typename?: 'PoolWavePipeline'
  number: Scalars['Int']
  start: Scalars['Time']
}

export type PoolWaves = {
  __typename?: 'PoolWaves'
  currentWave?: Maybe<Scalars['Int']>
  pipeline: Array<PoolWavePipeline>
}

export type Query = {
  __typename?: 'Query'
  getMerkleProof?: Maybe<GetMerkleProofResponse>
  kycInfo: KycInfo
  /** Returns a single pool by given id. */
  pool?: Maybe<Pool>
  pools: Array<Pool>
  stakingInfo: StakingInfo
  stakingInfoWithoutSession: StakingInfo
}

export type QueryGetMerkleProofArgs = {
  pool: Scalars['Address']
  session: Scalars['Session']
}

export type QueryKycInfoArgs = {
  session?: InputMaybe<Scalars['Session']>
}

export type QueryPoolArgs = {
  id: Scalars['PoolID']
  session?: InputMaybe<Scalars['Session']>
}

export type QueryPoolsArgs = {
  session?: InputMaybe<Scalars['Session']>
}

export type QueryStakingInfoArgs = {
  address?: InputMaybe<Scalars['Address']>
  blockchain?: InputMaybe<Scalars['Blockchain']>
  session?: InputMaybe<Scalars['Session']>
}

export type QueryStakingInfoWithoutSessionArgs = {
  account?: InputMaybe<Scalars['Address']>
  address?: InputMaybe<Scalars['Address']>
  blockchain?: InputMaybe<Scalars['Blockchain']>
}

export type QueryStaking = {
  __typename?: 'QueryStaking'
  stakingInfo: StakingInfo
  stakingInfoWithoutSession: StakingInfo
}

export type QueryStakingStakingInfoArgs = {
  address?: InputMaybe<Scalars['Address']>
  blockchain?: InputMaybe<Scalars['Blockchain']>
  session?: InputMaybe<Scalars['Session']>
}

export type QueryStakingStakingInfoWithoutSessionArgs = {
  account?: InputMaybe<Scalars['Address']>
  address?: InputMaybe<Scalars['Address']>
  blockchain?: InputMaybe<Scalars['Blockchain']>
}

export type RegisterPoolParticipationInput = {
  blockchain: Scalars['Blockchain']
  poolAddress: Scalars['Address']
  session: Scalars['Session']
}

export type SecondaryMarket = {
  __typename?: 'SecondaryMarket'
  name: Scalars['String']
  sellStart?: Maybe<Scalars['Time']>
  url: Scalars['String']
}

export type StakeBooster = {
  __typename?: 'StakeBooster'
  /**  Time when stake booster will be converted to an ordinary stake  */
  boosterUnlockTime: Scalars['Time']
  stakeBoosterBalance: Scalars['Decimal']
  /**  If users stake will drop below this amount at any time, his booster will be nullified  */
  stakeBoosterThreshold: Scalars['Decimal']
}

export type StakingAccount = {
  __typename?: 'StakingAccount'
  account: Scalars['Address']
  allowance: Scalars['Decimal']
  rewardAvailable: Scalars['Decimal']
  rewardClaimed: Scalars['Decimal']
  /** Pending is a part of a reward that an account have earned already, but which is not yet available for claiming. */
  rewardPending: Scalars['Decimal']
  rewardTotal: Scalars['Decimal']
  rewardTotalUSD: Scalars['Decimal']
  stakeBooster?: Maybe<StakeBooster>
  stakingBalance: Scalars['Decimal']
  tier?: Maybe<StakingTier>
  walletBalance: Scalars['Decimal']
}

export type StakingInfo = {
  __typename?: 'StakingInfo'
  account?: Maybe<StakingAccount>
  address: Scalars['Address']
  apy: Scalars['Decimal']
  blockchain: Scalars['Blockchain']
  contract: Scalars['String']
  currentEpoch?: Maybe<Epoch>
  id: Scalars['ID']
  nextEpoch?: Maybe<Epoch>
  priceRewardTokenUSD: Scalars['Decimal']
  priceStakingTokenUSD: Scalars['Decimal']
  rewardToken: Token
  sessionError?: Maybe<Scalars['String']>
  stakingToken: Token
  tiers: Array<StakingTier>
  totalEmittedRewards: Scalars['Decimal']
  totalRewardToDistribute?: Maybe<Scalars['Decimal']>
  totalStaked: Scalars['Decimal']
  /**  Number of different wallets that staked to this address.  */
  uniqueStakers: Scalars['Int']
  unstakingFeeRatio: Scalars['Decimal']
}

export type StakingTier = {
  __typename?: 'StakingTier'
  allocationMultiple: Scalars['Decimal']
  coolOff?: Maybe<Scalars['Int']>
  index: Scalars['Int']
  isSpecial?: Maybe<Scalars['Boolean']>
  level: Scalars['Int']
  minStake: Scalars['Decimal']
  name: Scalars['String']
  participationTickets: Scalars['Decimal']
  stakeDelta?: Maybe<Scalars['Decimal']>
  ticketDelta?: Maybe<Scalars['Decimal']>
}

export type Tier = {
  __typename?: 'Tier'
  allocationMultiple: Scalars['Decimal']
  coolOff: Scalars['Int']
  index: Scalars['Int']
  isSpecial?: Maybe<Scalars['Boolean']>
  level: Scalars['Int']
  maxAllocation?: Maybe<Scalars['Decimal']>
  minStake?: Maybe<Scalars['Decimal']>
  name: Scalars['String']
  /** @deprecated use participationTickets */
  participationChanceFrom: Scalars['Int']
  /** @deprecated use participationTickets */
  participationChanceTo: Scalars['Int']
  participationTickets: Scalars['Decimal']
}

export type Token = {
  __typename?: 'Token'
  address: Scalars['Address']
  blockchain: Scalars['Blockchain']
  decimals: Scalars['Int']
  id: Scalars['ID']
  name: Scalars['String']
  symbol: Scalars['String']
  totalSupply: Scalars['Decimal']
}

export enum VerifiedStatus {
  Unverified = 'UNVERIFIED',
  Verified = 'VERIFIED',
}

export type Vesting = {
  __typename?: 'Vesting'
  currentPeriodIndex?: Maybe<Scalars['Int']>
  unlockCheckpoints: Array<Scalars['Time']>
  unlockPercentages: Array<Scalars['Decimal']>
  unlockPercentagesByCheckpoint: Array<Scalars['Decimal']>
  /** If vesting is performed via airdrop, then user will not be able to claim tokens himself. Tokens will be sent to user via airdrop. */
  viaAirdrop: Scalars['Boolean']
}

export type AuthMutationVariables = Exact<{
  input: AuthInput
}>

export type AuthMutation = {
  __typename?: 'Mutation'
  auth: { __typename?: 'AuthResult'; session: string; address: string }
}

export type GetAuthMessageMutationVariables = Exact<{
  address: Scalars['Address']
  blockchain: Scalars['Blockchain']
}>

export type GetAuthMessageMutation = {
  __typename?: 'Mutation'
  getAuthMessage: string
}

export type RegisterPoolParticipationMutationVariables = Exact<{
  input: RegisterPoolParticipationInput
}>

export type RegisterPoolParticipationMutation = {
  __typename?: 'Mutation'
  registerPoolParticipation: {
    __typename?: 'PoolParticipationResponse'
    reason?: string | null
    success: boolean
  }
}

export type GeneralInfoQueryVariables = Exact<{
  session?: InputMaybe<Scalars['Session']>
  address?: InputMaybe<Scalars['Address']>
}>

export type GeneralInfoQuery = {
  __typename?: 'Query'
  pools: Array<{
    __typename?: 'FixedPricePool'
    address: string
    blockchain: string
    currentTime: string
    id: string
    minOrderSizeTargetToken: string
    poolStatus: PoolStatus
    poolType: PoolType
    price?: string | null
    priceReverse?: string | null
    stakingAddress: string
    whitelistAddress: string
    account?: {
      __typename?: 'PoolAccount'
      account: string
      amountPaid: string
      idoTokenBalance: string
      refundAmount: string
      targetTokenBalance: string
      userToReserve: string
      withdrawnAmount: string
      isRegistered: boolean
      isWhitelisted?: boolean | null
      wave?: number | null
      waveStartTime?: string | null
      tier?: { __typename?: 'Tier'; level: number } | null
      vesting?: {
        __typename?: 'AccountVesting'
        unlocks: Array<string>
        claimed: Array<string>
        available: Array<string>
        totalClaimed: string
        totalAvailable: string
      } | null
    } | null
    idoToken: {
      __typename?: 'Token'
      id: string
      address: string
      totalSupply: string
      name: string
      symbol: string
      decimals: number
      blockchain: string
    }
    pipeline: {
      __typename?: 'PoolPipeline'
      registrationTime?: string | null
      whitelistingTime?: string | null
      prelaunchTime?: string | null
      startTime?: string | null
      finishTime?: string | null
    }
    progress: {
      __typename?: 'PoolProgress'
      tokensToSell?: string | null
      tokensSold?: string | null
      tokensSoftCap?: string | null
      softCapPercent?: string | null
      totalTarget?: string | null
      totalTargetUSD?: string | null
    }
    statistics: {
      __typename?: 'PoolStatistics'
      registeredCount: number
      participantsCount: number
      marketcapEstimate?: string | null
    }
    targetToken?: {
      __typename?: 'Token'
      id: string
      address: string
      totalSupply: string
      name: string
      symbol: string
      decimals: number
      blockchain: string
    } | null
    tiers: Array<{
      __typename?: 'Tier'
      level: number
      index: number
      name: string
      maxAllocation?: string | null
      minStake?: string | null
      allocationMultiple: string
      participationTickets: string
      coolOff: number
      isSpecial?: boolean | null
    }>
    vesting?: {
      __typename?: 'Vesting'
      unlockCheckpoints: Array<string>
      unlockPercentages: Array<string>
      currentPeriodIndex?: number | null
    } | null
    waves?: {
      __typename?: 'PoolWaves'
      currentWave?: number | null
      pipeline: Array<{
        __typename?: 'PoolWavePipeline'
        number: number
        start: string
      }>
    } | null
    meta: {
      __typename?: 'PoolMeta'
      ROIAllTimeHigh?: string | null
      auditReport?: string | null
      brandBackground?: string | null
      brandLogo?: string | null
      coingecko?: string | null
      coinmarketcap?: string | null
      constantUSDTokenPrice?: string | null
      constantUSDTotalTarget?: string | null
      coolOffExplainedLink: string
      description: string
      discord?: string | null
      dueDiligenceReport?: string | null
      initialCirculationAmount?: string | null
      initialMarketCap?: string | null
      isIncubated?: boolean | null
      isKYCRequired: boolean
      markdown?: string | null
      medium?: string | null
      name: string
      participationExplainedLink: string
      position?: number | null
      prelaunchStart?: string | null
      priceAllTimeHigh?: string | null
      private: boolean
      registrationStart?: string | null
      roughTokenPriceUSD?: string | null
      sourceCode?: string | null
      subtitle?: string | null
      tags: Array<string>
      telegram?: string | null
      tiersExplainedLink: string
      twitter?: string | null
      vestingDescription?: string | null
      video?: string | null
      visibility: boolean
      website?: string | null
      whitelistingStart?: string | null
      whitepaper?: string | null
      secondaryMarkets: Array<{
        __typename?: 'SecondaryMarket'
        name: string
        url: string
        sellStart?: string | null
      }>
      team: Array<{
        __typename?: 'Member'
        name: string
        linkedIn: string
        avatar: string
      }>
    }
  }>
  stakingInfo: {
    __typename?: 'StakingInfo'
    id: string
    apy: string
    unstakingFeeRatio: string
    totalEmittedRewards: string
    stakingToken: {
      __typename?: 'Token'
      id: string
      symbol: string
      decimals: number
      address: string
      totalSupply: string
    }
    account?: {
      __typename?: 'StakingAccount'
      stakingBalance: string
      walletBalance: string
      allowance: string
      rewardAvailable: string
      rewardClaimed: string
      rewardTotal: string
      rewardTotalUSD: string
      stakeBooster?: {
        __typename?: 'StakeBooster'
        stakeBoosterBalance: string
        stakeBoosterThreshold: string
        boosterUnlockTime: string
      } | null
      tier?: {
        __typename?: 'StakingTier'
        level: number
        index: number
        name: string
        minStake: string
        allocationMultiple: string
        participationTickets: string
        coolOff?: number | null
        isSpecial?: boolean | null
        stakeDelta?: string | null
        ticketDelta?: string | null
      } | null
    } | null
    rewardToken: {
      __typename?: 'Token'
      id: string
      symbol: string
      decimals: number
      address: string
      totalSupply: string
    }
    tiers: Array<{
      __typename?: 'StakingTier'
      level: number
      index: number
      name: string
      minStake: string
      allocationMultiple: string
      participationTickets: string
      coolOff?: number | null
      isSpecial?: boolean | null
      stakeDelta?: string | null
      ticketDelta?: string | null
    }>
  }
}

export type GetMerkleProofQueryVariables = Exact<{
  session: Scalars['Session']
  pool: Scalars['Address']
}>

export type GetMerkleProofQuery = {
  __typename?: 'Query'
  getMerkleProof?: {
    __typename?: 'GetMerkleProofResponse'
    tier: number
    wave: number
    proof: Array<string>
  } | null
}

export type KycInfoQueryVariables = Exact<{
  session: Scalars['Session']
}>

export type KycInfoQuery = {
  __typename?: 'Query'
  kycInfo: {
    __typename?: 'KycInfo'
    generalLink: string
    supportLink: string
    usInvestorsLink: string
  }
}

export const AuthDocument = gql`
  mutation Auth($input: AuthInput!) {
    auth(input: $input) {
      session
      address
    }
  }
`
export const GetAuthMessageDocument = gql`
  mutation GetAuthMessage($address: Address!, $blockchain: Blockchain!) {
    getAuthMessage(address: $address, blockchain: $blockchain)
  }
`
export const RegisterPoolParticipationDocument = gql`
  mutation RegisterPoolParticipation($input: RegisterPoolParticipationInput!) {
    registerPoolParticipation(input: $input) {
      reason
      success
    }
  }
`
export const GeneralInfoDocument = gql`
  query GeneralInfo($session: Session, $address: Address) {
    pools(session: $session) {
      account {
        account
        amountPaid
        idoTokenBalance
        refundAmount
        targetTokenBalance
        userToReserve
        withdrawnAmount
        tier {
          level
        }
        isRegistered
        isWhitelisted
        vesting {
          unlocks
          claimed
          available
          totalClaimed
          totalAvailable
        }
        wave
        waveStartTime
      }
      address
      blockchain
      currentTime
      id
      idoToken {
        id
        address
        totalSupply
        name
        symbol
        decimals
        blockchain
      }
      minOrderSizeTargetToken
      pipeline {
        registrationTime
        whitelistingTime
        prelaunchTime
        startTime
        finishTime
      }
      poolStatus
      poolType
      price
      priceReverse
      progress {
        tokensToSell
        tokensSold
        tokensSoftCap
        softCapPercent
        totalTarget
        totalTargetUSD
      }
      stakingAddress
      statistics {
        registeredCount
        participantsCount
        marketcapEstimate
      }
      targetToken {
        id
        address
        totalSupply
        name
        symbol
        decimals
        blockchain
      }
      tiers {
        level
        index
        name
        maxAllocation
        minStake
        allocationMultiple
        participationTickets
        coolOff
        isSpecial
      }
      vesting {
        unlockCheckpoints
        unlockPercentages
        currentPeriodIndex
      }
      waves {
        currentWave
        pipeline {
          number
          start
        }
      }
      whitelistAddress
      meta {
        ROIAllTimeHigh
        auditReport
        brandBackground
        brandLogo
        coingecko
        coinmarketcap
        constantUSDTokenPrice
        constantUSDTotalTarget
        coolOffExplainedLink
        description
        discord
        dueDiligenceReport
        initialCirculationAmount
        initialMarketCap
        isIncubated
        isKYCRequired
        markdown
        medium
        name
        participationExplainedLink
        position
        prelaunchStart
        priceAllTimeHigh
        private
        registrationStart
        roughTokenPriceUSD
        secondaryMarkets {
          name
          url
          sellStart
        }
        sourceCode
        subtitle
        tags
        team {
          name
          linkedIn
          avatar
        }
        telegram
        tiersExplainedLink
        twitter
        vestingDescription
        video
        visibility
        website
        whitelistingStart
        whitepaper
      }
    }
    stakingInfo(session: $session, address: $address) {
      id
      stakingToken {
        id
        symbol
        decimals
        address
        totalSupply
      }
      account {
        stakingBalance
        walletBalance
        allowance
        rewardAvailable
        rewardClaimed
        rewardTotal
        rewardTotalUSD
        stakeBooster {
          stakeBoosterBalance
          stakeBoosterThreshold
          boosterUnlockTime
        }
        tier {
          level
          index
          name
          minStake
          allocationMultiple
          participationTickets
          coolOff
          isSpecial
          stakeDelta
          ticketDelta
        }
      }
      apy
      unstakingFeeRatio
      totalEmittedRewards
      rewardToken {
        id
        symbol
        decimals
        address
        totalSupply
      }
      tiers {
        level
        index
        name
        minStake
        allocationMultiple
        participationTickets
        coolOff
        isSpecial
        stakeDelta
        ticketDelta
      }
      apy
      unstakingFeeRatio
      rewardToken {
        id
        symbol
        decimals
      }
      tiers {
        level
        index
        name
        minStake
        allocationMultiple
        participationTickets
        coolOff
        isSpecial
        stakeDelta
        ticketDelta
      }
    }
  }
`
export const GetMerkleProofDocument = gql`
  query GetMerkleProof($session: Session!, $pool: Address!) {
    getMerkleProof(session: $session, pool: $pool) {
      tier
      wave
      proof
    }
  }
`
export const KycInfoDocument = gql`
  query KYCInfo($session: Session!) {
    kycInfo(session: $session) {
      generalLink
      supportLink
      usInvestorsLink
    }
  }
`

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string
) => Promise<T>

const defaultWrapper: SdkFunctionWrapper = (
  action,
  _operationName,
  _operationType
) => action()

export function getSdk(
  client: GraphQLClient,
  withWrapper: SdkFunctionWrapper = defaultWrapper
) {
  return {
    Auth(
      variables: AuthMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<AuthMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<AuthMutation>(AuthDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'Auth',
        'mutation'
      )
    },
    GetAuthMessage(
      variables: GetAuthMessageMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<GetAuthMessageMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetAuthMessageMutation>(
            GetAuthMessageDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'GetAuthMessage',
        'mutation'
      )
    },
    RegisterPoolParticipation(
      variables: RegisterPoolParticipationMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<RegisterPoolParticipationMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<RegisterPoolParticipationMutation>(
            RegisterPoolParticipationDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'RegisterPoolParticipation',
        'mutation'
      )
    },
    GeneralInfo(
      variables?: GeneralInfoQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<GeneralInfoQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GeneralInfoQuery>(GeneralInfoDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GeneralInfo',
        'query'
      )
    },
    GetMerkleProof(
      variables: GetMerkleProofQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<GetMerkleProofQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetMerkleProofQuery>(
            GetMerkleProofDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'GetMerkleProof',
        'query'
      )
    },
    KYCInfo(
      variables: KycInfoQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<KycInfoQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<KycInfoQuery>(KycInfoDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'KYCInfo',
        'query'
      )
    },
  }
}
export type Sdk = ReturnType<typeof getSdk>
