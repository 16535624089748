import AlgodClient from 'algosdk/dist/types/client/v2/algod/algod'
import {
  createEffect,
  createEvent,
  createStore,
  merge,
  restore,
} from 'effector'
import { $algosdk, AlgodIndexer, signTransactionFn } from 'models/algo'
import { openTxFailModal, openTxWaitModal } from 'models/flow'
import {
  ClaimTransactionOptions,
  makeStakeTransaction,
  makeUnstakeTransaction,
  makeWithdrawRewardTransaction,
  StakeTransactionOptions,
} from 'sdk/staking'
import { toDecimal } from 'utils/numbers'

export const setStakingTokenDecimals = createEvent<number>()
export const $stakingTokenDecimals = restore(setStakingTokenDecimals, 0)

export const stake = createEvent<{
  stakeAmount: string
  signTransactions: SignTransactions
}>()

export const stakeFx = createEffect(
  async ({
    amount,
    algodClient,
    from,
    stakingAppID,
    assetID,
    signTransactions,
  }: StakeTransactionOptions) => {
    openTxWaitModal()
    /*
    console.log({
      amount,
      assetID,
    })
    */

    try {
      const txnsArray = await makeStakeTransaction({
        algodClient,
        from,
        stakingAppID,
        amount,
        assetID,
      })

      await signTransactionFn({
        transactions: txnsArray,
        algodClient,
        signTransactions,
      })
    } catch (err) {
      openTxFailModal()
      console.error('err', err)
      throw Error(`${err}`)
    }
  }
)

export const unstake = createEvent<{
  unstakeAmount: string
  signTransactions: SignTransactions
}>()
export const unstakeFx = createEffect(
  async ({
    algodClient,
    from,
    stakingAppID,
    amount,
    assetID,
    algodIndexer,
    unstakingFeeRatio,
    signTransactions,
  }: {
    algodClient: AlgodClient
    amount: number
    unstakingFeeRatio: string
    from: string
    stakingAppID: number
    assetID: number
    algodIndexer: AlgodIndexer
    signTransactions: SignTransactions
  }) => {
    const algosdk = $algosdk.getState()

    openTxWaitModal()

    try {
      const appInfo = await algodIndexer.lookupApplications(stakingAppID).do()
      const feeThreshold = toDecimal(unstakingFeeRatio)
        .mul(amount)
        .ceil()
        .toNumber()

      const feeBurnAddressKey =
        Buffer.from('FEE_BURN_ADDRESS').toString('base64')
      const state = appInfo?.application?.params['global-state']
      const feeBurnAddressValue = state.find(
        (v: any) => v.key === feeBurnAddressKey
      )
      const feeBurnAddressBase64 = Buffer.from(
        feeBurnAddressValue.value.bytes,
        'base64'
      )
      const feeBurnAddress = algosdk.encodeAddress(feeBurnAddressBase64)
      const transactions = await makeUnstakeTransaction({
        algodClient,
        from,
        stakingAppID,
        amount,
        assetID,
        feeThreshold,
        feeBurnAddress,
      })

      await signTransactionFn({ algodClient, transactions, signTransactions })
    } catch (err) {
      openTxFailModal()
      console.error('err', err)
      throw Error(`${err}`)
    }
  }
)

export const claim = createEvent<{ signTransactions: SignTransactions }>()
export const claimFx = createEffect(
  async ({
    from,
    algodClient,
    stakingAppID,
    rewardAssetID,
    signTransactions,
  }: ClaimTransactionOptions) => {
    openTxWaitModal()
    try {
      const transactions = await makeWithdrawRewardTransaction({
        algodClient,
        stakingAppID,
        from,
        rewardAssetID,
      })

      await signTransactionFn({ algodClient, transactions, signTransactions })
    } catch (err) {
      openTxFailModal()
      console.error('err', err)
      throw Error(`${err}`)
    }
  }
)

const triggersPending = merge([stakeFx, claimFx, unstakeFx])

const triggersDone = merge([claimFx.done, stakeFx.done, unstakeFx.done])

const triggersFail = merge([claimFx.fail, stakeFx.fail, unstakeFx.fail])

const setTransactionFinished = createEvent()

triggersDone.watch(() => {
  setTimeout(() => {
    setTransactionFinished()
  }, 5000)
})

export const $stakingFormTransactionPending = createStore(false)
  .on(triggersPending, () => true)
  .on(setTransactionFinished, () => false)
  .on(triggersFail, () => false)
