import { useEffect, useState } from 'react'

export default function useResizeAnnimationStopper() {
  const [animationStopper, setAnnimationStopper] = useState('')

  useEffect(() => {
    const resizeHandler = () => {
      let resizeTimer

      setAnnimationStopper('resize-animation-stopper')
      clearTimeout(resizeTimer)
      resizeTimer = setTimeout(() => {
        setAnnimationStopper('')
      }, 400)
    }

    window.addEventListener('resize', resizeHandler)

    return () => window.removeEventListener('resize', resizeHandler)
  }, [])

  return animationStopper
}
