import Decimal from 'decimal.js'
import noop from 'lodash/noop'
import TextInput from 'components/TextInput'
import { toDecimal, formatNumber } from 'utils/numbers'

interface CurrencyInputPanelProps {
  symbol: string
  value: string
  label?: string
  onUserInput?(value: string): void
  available?: Decimal
  leftSub?: string
  rightSub?: string
  rightCount?: Decimal
  onMax?(): void
  currency?: string | null
  disabled?: boolean
}

export default function CurrencyInputPanelDark({
  symbol,
  label = 'You send',
  value,
  leftSub,
  available,
  rightSub,
  rightCount,
  onUserInput = noop,
  onMax = noop,
  disabled = false,
}: CurrencyInputPanelProps) {
  const showMaxButton =
    available?.gt(0) && !toDecimal(value).eq(toDecimal(available)) && !disabled
  return (
    <div>
      <div>
        <div className="border border-grayDark rounded-base grow w-full dxs:w-auto bg-blackDeep p-[1rem]">
          <div className="flex items-center justify-between mb-[0.44rem]">
            <span className="font-mono text-[0.8rem] text-grayLight">
              {label}
            </span>
            {showMaxButton ? (
              <button
                onClick={onMax}
                className="px-1 font-aldrich text-[0.7rem] outline-none cursor-pointer text-grayLightSecond bg-blackLight rounded-base hover:bg-blue"
              >
                MAX
              </button>
            ) : null}
          </div>
          <div className="flex items-center justify-between text-[1.3rem] leading-none">
            <TextInput
              value={value}
              placeholder="0.00"
              className={`font-aldrich w-full flex-grow relative text-left bg-transparent placeholder-white/50 text-white focus:outline-none`}
              onChange={onUserInput}
              disabled={disabled}
            />

            <span className="text-base text-white">{symbol}</span>
          </div>
        </div>
      </div>
      <div
        className={`flex ${
          rightSub && rightCount
            ? 'justify-between'
            : 'justify-center dxs:justify-between'
        } mt-2 font-mono text-base t:mb-[1.0rem]`}
      >
        <div className="">
          {leftSub && available ? (
            <p>
              {leftSub}: {formatNumber(available, { fractionDigits: 6 })}{' '}
              {symbol}
            </p>
          ) : null}
        </div>
        <div>
          {rightSub && rightCount ? (
            <p>
              {rightSub}: {formatNumber(rightCount, { fractionDigits: 6 })}{' '}
              {symbol}
            </p>
          ) : null}
        </div>
      </div>
    </div>
  )
}
