import Logo from 'components/Logo'
import { ReactComponent as IconIndex } from 'icons/menu/index.svg'
import { ReactComponent as IconIdos } from 'icons/menu/idos.svg'
import { ReactComponent as IconWaller } from 'icons/menu/wallet.svg'
import { ReactComponent as IconWallerCheck } from 'icons/menu/wallet-check.svg'

import { ReactComponent as IconStake } from 'icons/menu/stake.svg'
import { Link, useLocation } from '@reach/router'
import { useStore } from 'effector-react'
import { ReactComponent as IconMedium } from 'icons/medium.svg'
import { ReactComponent as IconAlgodaoLogoText } from 'icons/algodao-logo-text.svg'
import { ReactComponent as IconBurgerClose } from 'icons/menu/burger-close.svg'
import { ReactComponent as IconBurger } from 'icons/menu/burger.svg'
import { ReactComponent as IconTelegram } from 'icons/telegram.svg'
import { ReactComponent as IconTwitter } from 'icons/twitter.svg'
import { ReactComponent as IconDiscord } from 'icons/discord.svg'
import {
  $userSession,
  // connectWallet
} from 'models/user'
import { useState } from 'react'
import useResizeAnnimationStopper from 'utils/hooks/useResizeAnimationStopper'
import { useTheme } from 'themeContext'
import { $isSinglePoolMode, $stakingInfo } from 'models/app'
import React from 'react'
import { openConnectWalletModal } from 'models/flow'

type Props = {
  navActiveColor?: string
  gradient?: string
}

const cnMenuIcons = (isActive: boolean) => `
  w-[1.8rem] h-[1.8rem] fill-current transition-all
  group-hover:menu-icons-hover-shadow  group-hover:scale-110 
${isActive ? 'menu-icons-active-shadow' : 'no-filter-shadow'}
`

export default function Header({ navActiveColor = '', gradient = '' }: Props) {
  const userSession = useStore($userSession)
  const isSinglePoolMode = useStore($isSinglePoolMode)
  const location = useLocation()
  const stakingInfo = useStore($stakingInfo)

  const URLS_MAP = React.useMemo(() => {
    return [
      isSinglePoolMode
        ? {
            url: '/IDO',
            text: 'IDO',
            icon: (
              <IconIdos className={cnMenuIcons(location.pathname === '/IDO')} />
            ),
            active: location.pathname === '/IDO',
          }
        : {
            url: '/',
            text: 'IDOs',
            icon: (
              <IconIdos className={cnMenuIcons(location.pathname === '/')} />
            ),
            active: location.pathname === '/',
          },
      {
        url: '/staking',
        text: 'Stake',
        icon: (
          <IconStake
            className={cnMenuIcons(location.pathname === '/staking')}
          />
        ),
        active: location.pathname === '/staking',
      },
      {
        url: '/dashboard',
        text: stakingInfo?.rewardToken?.symbol ?? '',
        icon: (
          <IconIndex
            className={cnMenuIcons(location.pathname === '/dashboard')}
          />
        ),
        active: location.pathname === '/dashboard',
      },
    ]
  }, [isSinglePoolMode, location.pathname, stakingInfo?.rewardToken.symbol])

  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const dark = useTheme()

  const cnSocial = `w-[1.8rem] h-[1.8rem] no-filter-shadow group-hover:menu-icons-hover-shadow transition-all fill-current group-hover:fill-white`
  const animationStopperClass = useResizeAnnimationStopper()

  const connectWalletHandler = () => {
    openConnectWalletModal()
  }

  const handleLinkClick = (url: string) => {
    setMenuIsOpen(false)
  }

  return (
    <>
      <div className="w-full h-[3.7rem] dxs:hidden"></div>
      <HeaderContainer>
        <div className="px-[1.75rem] py-[0.6rem] dxs:px-0 dxs:py-0 dxs:mb-[2.35rem] flex justify-between items-center">
          <Link to={'/'} className="group">
            <Logo className="text-white w-[2rem] h-[2.5rem] dxs:h-auto dxs:w-[3.5rem] no-filter-shadow  group-hover:menu-icons-hover-shadow transition-all" />
          </Link>
          <IconAlgodaoLogoText className="w-[13.1rem] dxs:hidden fill-white" />
          <div className="cursor-pointer dxs:hidden w-[1.7rem]">
            {menuIsOpen ? (
              <IconBurgerClose
                onClick={() => {
                  setMenuIsOpen(false)
                }}
              />
            ) : (
              <IconBurger
                onClick={() => {
                  setMenuIsOpen(true)
                }}
              />
            )}
          </div>
        </div>
        <div
          className={`
          flex items-center flex-col overflow-hidden transition-all duration-700 dxs:transition-none overflow-visible
          ${dark ? 'text-blueLight' : 'text-redLight'} 
          ${
            menuIsOpen
              ? 'max-h-[500px] dxs:max-h-full'
              : 'max-h-[0px] dxs:max-h-full'
          }
          ${animationStopperClass}
    `}
        >
          <div
            className={`
            flex dxs:flex-col items-center justify-center my-[2.35rem] dxs:mb-[1.75rem] dxs:mt-0
             delay-150 duration-500  ${
               menuIsOpen
                 ? 'translate-y-0 opacity-100'
                 : 'translate-y-[120%] opacity-0 dxs:translate-y-0 dxs:opacity-100'
             }
            `}
          >
            {URLS_MAP.map((i) => {
              return (
                <Link
                  key={`link-${i.text}`}
                  to={i.url}
                  className={`
group block mx-[1.5rem] dxs:mx-0 dxs:mb-[1.75rem] relative
${
  i.active
    ? 'before:w-[0.3rem] before:h-[0.3rem] before:-right-[0.3rem] before:-top-[0.3rem] before:bg-white before:absolute before:rounded-[50%]'
    : ''
} 
`}
                  onClick={() => handleLinkClick(i.url)}
                >
                  <div className="flex flex-col items-center">
                    <div className="mb-[0.75rem]">{i.icon}</div>
                    <div
                      className={`${
                        i.active ? 'opacity-100' : 'opacity-70'
                      } text-white group-hover:opacity-100 transition-opacity`}
                    >
                      {i.text}
                    </div>
                  </div>
                </Link>
              )
            })}
            <div className="hidden dxs:block">
              <Separator />
            </div>
            <div className="flex flex-col items-center mx-[1.5rem] dxs:mx-0">
              <div
                onClick={connectWalletHandler}
                className="relative flex flex-col items-center cursor-pointer group"
              >
                {userSession.address && (
                  <IconWallerCheck className="w-[0.35rem] h-[0.35rem] absolute top-[-0.3rem] right-[0.4rem]" />
                )}
                <IconWaller
                  className={`mb-[0.75rem] ${cnMenuIcons(
                    false
                  )} group-hover:scale-110`}
                />
                <div className="text-center text-white opacity-70 group-hover:opacity-100 transition-opacity">
                  {userSession.address
                    ? `${userSession.address.slice(
                        0,
                        2
                      )}...${userSession.address.slice(-3)}`
                    : 'Wallet'}
                </div>
              </div>
            </div>
          </div>

          <Separator />
          <div
            className={`
          flex dxs:flex-col pb-[1.75rem] dxs:pb-0 justify-center items-center
          delay-300 duration-500 ${
            menuIsOpen
              ? 'translate-y-0 opacity-100'
              : 'translate-y-[120%] opacity-0 dxs:translate-y-0 dxs:opacity-100'
          }  
          `}
          >
            <a
              href="https://medium.com/algodao-fi"
              target="_blank"
              className="block dxs:mb-[1.75rem] group px-[1.8rem] dxs:px-0"
              rel="noreferrer"
            >
              <IconMedium className={`${cnSocial}`} />
            </a>
            <a
              href="https://twitter.com/algodao_fi"
              target="_blank"
              className="block dxs:mb-[1.75rem] group px-[1.8rem] dxs:px-0"
              rel="noreferrer"
            >
              <IconTwitter className={`${cnSocial}`} />
            </a>
            <a
              href="https://t.me/AlgoDAOCommunity"
              target="_blank"
              className="block dxs:mb-[1.75rem] group px-[1.8rem] dxs:px-0"
              rel="noreferrer"
            >
              <IconTelegram className={`${cnSocial}`} />
            </a>
            <a
              href="https://discord.com/invite/algodao"
              target="_blank"
              className="block dxs:mb-[1.75rem] group px-[1.8rem] dxs:px-0"
              rel="noreferrer"
            >
              <IconDiscord className={`${cnSocial}`} />
            </a>
          </div>
        </div>
      </HeaderContainer>
    </>
  )
}

const Separator = () => (
  <div className="w-full dxs:w-[1.8rem] h-[1px] bg-white opacity-30 mb-[1.75rem]"></div>
)

const HeaderContainer = ({ children }: { children: React.ReactNode }) => {
  const dark = useTheme()
  const cn = {
    base: `
      absolute w-full h-full z-[-1]
      transition transition-all duration-700 delay-300
      before:absolute before:bottom-[-0.55rem]  before:h-[0.5rem] before:w-full
      dxs:before:absolute dxs:before:top-0 dxs:before:w-[0.55rem]  dxs:before:-right-[0.65rem] dxs:before:h-full dxs:before:bg-cover
      dxs:after:absolute dxs:after:top-0 dxs:after:w-[2.15rem]  dxs:after:-right-[2.15rem] dxs:after:h-full dxs:after:bg-cover
    `,
    dark: 'bg-blueMainGradient dxs:before:bg-menuBeforeBlue',
    other: 'bg-redMainGradient dxs:before:bg-menuBefore dxs:after:bg-menuAfter',
  }

  return (
    <div
      className={`
      fixed top-0 left-0 w-full z-50 dxs:z-10
      dxs:fixed dxs:left-0 dxs:top-0 dxs:h-screen dxs:w-[5.1rem] dxs:flex dxs:flex-col dxs:items-center 
    `}
    >
      <div
        className={`${cn.base} ${cn.dark} ${
          dark
            ? 'before:bg-headerAfterBlue opacity-100 z-10'
            : 'opacity-100'
            ? 'before:bg-headerAfterRed opacity-100 z-10'
            : ' before:bg-headerAfterRed opacity-100'
        }`}
      />
      <div
        className={`${cn.base} ${cn.other} ${
          dark ? 'opacity-0' : 'opacity-100 z-10'
        }`}
      />
      <div className="dxs:pt-[3.6rem] relative overflow-hidden">{children}</div>
    </div>
  )
}
