interface Props {
  className?: string
  bgFill?: string
}

export default function Logo({ className = '', bgFill = '#282A32' }: Props) {
  return (
    <svg
      className={className}
      width="70"
      height="89"
      viewBox="0 0 70 89"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.3641 4.50008C34.3958 4.12331 34.9471 4.1233 34.9789 4.50008L39.0485 52.7752C39.1171 53.5893 39.3289 54.3849 39.6741 55.1253L53.5462 84.8771C53.7062 85.2202 53.2269 85.4941 53.0125 85.1823L37.2134 62.2065C35.9876 60.4238 33.3553 60.4238 32.1295 62.2065L16.3305 85.1823C16.116 85.4941 15.6368 85.2202 15.7967 84.8771L29.6688 55.1253C30.0141 54.3849 30.2259 53.5893 30.2945 52.7752L34.3641 4.50008Z"
        fill="url(#paint0_linear_1045_397)"
      />
      <path
        d="M30.2233 52.7894L31.1802 42.8944C31.2142 42.5432 31.7027 42.4857 31.8173 42.8194L38.835 63.2477C38.9563 63.6006 38.4882 63.85 38.2629 63.5524L30.2883 53.0191C30.2385 52.9534 30.2153 52.8714 30.2233 52.7894Z"
        fill={bgFill}
      />
      <rect
        width="2.46303"
        height="0.577056"
        transform="matrix(-0.706913 0.707301 -0.706913 -0.707301 6.17969 74.9131)"
        fill="url(#paint1_linear_1045_397)"
      />
      <rect
        width="2.46303"
        height="0.577056"
        transform="matrix(0.706929 0.707284 0.706929 -0.707284 64.0371 75.5503)"
        fill="url(#paint2_linear_1045_397)"
      />
      <rect
        width="1.43029"
        height="0.577056"
        transform="matrix(0.706913 -0.707301 -0.706913 -0.707301 1.49805 79.5967)"
        fill="url(#paint3_linear_1045_397)"
      />
      <rect
        width="0.355017"
        height="0.577056"
        transform="matrix(0.706913 -0.707301 -0.706913 -0.707301 0.408203 80.688)"
        fill="url(#paint4_linear_1045_397)"
      />
      <rect
        width="1.43029"
        height="0.577056"
        transform="matrix(-0.706929 -0.707284 0.706929 -0.707284 68.7148 80.2344)"
        fill="url(#paint5_linear_1045_397)"
      />
      <rect
        width="0.209255"
        height="0.577056"
        transform="matrix(-0.706929 -0.707284 0.706929 -0.707284 69.5918 81.1099)"
        fill="url(#paint6_linear_1045_397)"
      />
      <path
        d="M62.1751 73.3626L48.1358 59.3163C45.6686 56.8478 44.0647 53.6484 43.5631 50.1945L38.0176 12.0088"
        stroke="url(#paint7_linear_1045_397)"
        strokeWidth="0.771236"
      />
      <path
        d="M7.54166 73.3626L21.581 59.3163C24.0482 56.8478 25.6521 53.6484 26.1537 50.1945L31.6992 12.0088"
        stroke="url(#paint8_linear_1045_397)"
        strokeWidth="0.771236"
      />
      <path
        opacity="0.4"
        d="M23.523 37.6655L26.4961 20.9326"
        stroke="url(#paint9_linear_1045_397)"
        strokeWidth="0.771236"
      />
      <path
        opacity="0.6"
        d="M48.4232 50.6802L46.1934 37.2939"
        stroke="url(#paint10_linear_1045_397)"
        strokeWidth="0.771236"
      />
      <path
        opacity="0.6"
        d="M43.964 27.9988L43.2207 22.7925"
        stroke="url(#paint11_linear_1045_397)"
        strokeWidth="0.771236"
      />
      <path
        opacity="0.6"
        d="M29.8393 6.06037L30.2109 3.8291"
        stroke="url(#paint12_linear_1045_397)"
        strokeWidth="0.771236"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1045_397"
          x1="65.7377"
          y1="122.061"
          x2="2.11955"
          y2="29.8511"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" />
          <stop offset="1" stopColor="currentColor" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1045_397"
          x1="1.23151"
          y1="0"
          x2="1.23151"
          y2="0.577056"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" />
          <stop offset="1" stopColor="currentColor" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1045_397"
          x1="1.23151"
          y1="0"
          x2="1.23151"
          y2="0.577056"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" />
          <stop offset="1" stopColor="currentColor" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1045_397"
          x1="0.715146"
          y1="0"
          x2="0.715146"
          y2="0.577056"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" />
          <stop offset="1" stopColor="currentColor" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1045_397"
          x1="0.177509"
          y1="0"
          x2="0.177509"
          y2="0.577056"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" />
          <stop offset="1" stopColor="currentColor" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1045_397"
          x1="0.715146"
          y1="0"
          x2="0.715146"
          y2="0.577056"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" />
          <stop offset="1" stopColor="currentColor" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1045_397"
          x1="0.104627"
          y1="0"
          x2="0.104627"
          y2="0.577056"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" />
          <stop offset="1" stopColor="currentColor" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_1045_397"
          x1="53.8129"
          y1="12.0088"
          x2="53.8129"
          y2="73.3626"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" stopOpacity="0" />
          <stop offset="1" stopColor="currentColor" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_1045_397"
          x1="21.6645"
          y1="13.4962"
          x2="15.8982"
          y2="73.3621"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" stopOpacity="0" />
          <stop offset="1" stopColor="currentColor" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_1045_397"
          x1="17.9484"
          y1="13.4958"
          x2="12.1818"
          y2="73.3616"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" stopOpacity="0" />
          <stop offset="1" stopColor="currentColor" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_1045_397"
          x1="53.9977"
          y1="26.5105"
          x2="59.7644"
          y2="86.3764"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" stopOpacity="0" />
          <stop offset="1" stopColor="currentColor" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_1045_397"
          x1="51.0251"
          y1="10.8924"
          x2="56.7929"
          y2="70.7641"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" stopOpacity="0" />
          <stop offset="1" stopColor="currentColor" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_1045_397"
          x1="23.5215"
          y1="-11.7898"
          x2="17.7537"
          y2="48.0819"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" stopOpacity="0" />
          <stop offset="1" stopColor="currentColor" />
        </linearGradient>
      </defs>
    </svg>
  )
}
