import { ReactComponent as AlgodaoLogoBtnIcon } from 'icons/algodao-logo-btn.svg'

interface Props {
  color?: 'blue' | 'red'
}

export default function ButtonRocket({ color = 'blue' }: Props) {
  const bg = color === 'blue' ? 'bg-blueButtonGradient' : 'bg-redButtonGradient'
  return (
    <div
      className={`relative w-full min-h-[3rem] overflow-hidden rounded-sm ${bg}`}
    >
      <div className="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2">
        <AlgodaoLogoBtnIcon className="w-[2rem] h-[1.35rem] animate-rocketShake-fast" />
      </div>
      <div className="absolute animate-longFazerHorizontal-1 top-[20%] h-[1px] w-[20px] bg-white delay-[-5s]"></div>
      <div className="absolute animate-longFazerHorizontal-2 top-[40%] h-[1px] w-[20px] bg-white delay-[-1s]"></div>
      <div className="absolute animate-longFazerHorizontal-4 top-[80%] h-[1px] w-[20px] bg-white delay-[-3s]"></div>
      <div className="absolute animate-longFazerHorizontal-3 top-[60%] h-[1px] w-[20px] bg-white"></div>
    </div>
  )
}
