import React from 'react'

type ButtonType = 'slide' | 'basic' | 'border'
type ButtonColor = 'red' | 'blue' | 'white'

interface Props {
  children?: React.ReactNode
  type?: ButtonType
  size?: 'l' | 's'
  color?: ButtonColor
  disabled?: boolean
  onClick?: (e: React.MouseEvent) => void
  className?: string
  classNameInner?: string
}

const Button = React.forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const {
    children,
    type = 'basic',
    size = 's',
    color = 'red',
    disabled = false,
    onClick = () => {},
    className = '',
    classNameInner = '',
  } = props

  const handleClick = (e: React.MouseEvent) => {
    if (disabled) {
      e.preventDefault()
      e.stopPropagation()
      return
    }
    onClick(e)
  }

  const plainButtonColorCn: { [key in ButtonColor]: string } = {
    red: 'bg-redButtonGradient hover:bg-redButtonGradientHover shadow-redButtonPlain text-white',
    blue: 'bg-blueButtonGradient hover:text-blue text-white hover:bg-whiteGradient hover:blue-button-shadow',
    white: 'text-gray bg-white hover:shadow-whiteButton active:no-shadow',
  }

  const slideBg: { [key in ButtonColor]: string } = {
    red: 'bg-redButtonGradient',
    blue: 'bg-blueButtonGradient',
    white: '',
  }

  const cn: { [key in ButtonType]: string } = {
    border: `
      font-aldrich text-grayLight dxs:px-[2rem] ${
        size === 'l'
          ? 'px-[1.3rem] h-[1.8rem] dxs:h-[2.3rem] dxs:text-[1.3rem] '
          : 'px-[1rem]'
      } 
      h-[2.3rem] 
      text-base rounded-base
      border border-grayLight uppercase no-shadow transition-all
    ${
      disabled
        ? 'opacity-40'
        : ` hover:bg-mailButtonHover hover:text-white hover:mail-button-shadow hover:border-blueDarkLight `
    }
    overflow-hidden disabled:select-none
    `,
    basic: `
      overflow-hidden transition-all rounded-base text-base leading-[1.2rem]
      ${plainButtonColorCn[color]} 
      ${size === 's' ? 'h-[2.6rem]' : 'h-[2.2rem] dxs:h-[2.8rem]'} 
      ${disabled ? 'opacity-60 pointer-events-none' : ''}
    `,
    slide: `
      group overflow-hidden relative w-full font-aldrich text-white px-[2rem] h-[3rem] text-[1.3rem] uppercase transition-color
      rounded-[0.38rem] dxs:rounded-base bg-white
      ${
        disabled
          ? color === 'red'
            ? 'bg-redButtonDisabledGradient'
            : 'bg-blueButtonDisabledGradient'
          : color === 'red'
          ? 'red-button-shadow focus:text-red'
          : 'shadow-blueButtonShadow focus:text-blue'
      }
      `,
  }

  const finalClassName = `relative flex items-center justify-center ${cn[type]} ${className}`
  const content = (
    <>
      {type === 'slide' && !disabled && (
        <div
          className={`
          absolute ${slideBg[color]} top-0 -left-[2rem] w-[125%] h-full transition-transform duration-500
          group-hover:translate-x-[3rem] group-focus:translate-x-[85%]
        `}
        >
          <div className="bg-redButtonLeftHover w-[2rem] h-full bg-contain bg-no-repeat"></div>
        </div>
      )}

      <div className={`relative z-20 w-full ${classNameInner}`}>{children}</div>
    </>
  )

  return (
    <button
      ref={ref}
      className={finalClassName}
      disabled={disabled}
      type="button"
      onClick={handleClick}
      onMouseLeave={() => {
        //@ts-ignore
        document.activeElement!.blur()
      }}
    >
      {content}
    </button>
  )
})

export default Button
