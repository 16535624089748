import { ModalTitle, Check, TextLinkModal } from 'components'
import Button from 'components/Button'
import { useStore } from 'effector-react'
import { $txHash, closeModal } from 'models/flow'
import { useTheme } from 'themeContext'
import { t } from 'utils'

export default function TxSuccessModal() {
  const dark = useTheme()

  const handleClick = () => {
    closeModal()
  }
  const txHash = useStore($txHash)

  const color = dark ? 'text-white' : 'text-black'
  const explorerUrl = `${process.env.REACT_APP_ALGO_EXPLORER}/tx/${txHash}`

  return (
    <div className="flex flex-col flex-grow">
      <ModalTitle className={`!${color}`}>
        {t('modal_tx_success_heading')}
      </ModalTitle>
      <div className="flex flex-col items-center">
        <TextLinkModal href={explorerUrl}>
          View transaction on AlgoExplorer
        </TextLinkModal>
        <Check
          bgFill={dark ? 'white' : 'black'}
          className="text-red  w-[6rem] h-[6.5rem] mt-[2rem] mb-[2.4rem] "
        />
      </div>
      <Button className="w-full" type="slide" onClick={handleClick}>
        Ok
      </Button>
    </div>
  )
}
