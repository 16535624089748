interface Props {
  className?: string
  bgFill?: string
}

export default function Check({ className = '', bgFill = 'white' }: Props) {
  return (
    <svg
      className={className}
      width="86"
      height="101"
      viewBox="0 0 86 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.6398 99.0037C36.2745 99.7343 35.2349 99.7424 34.8581 99.0177L6.17282 43.8334C5.96924 43.4418 6.48991 43.0801 6.78584 43.4076L34.0432 73.5702C34.9162 74.5362 36.4649 74.4237 37.1891 73.3417L85.7695 0.757812L36.6398 99.0037Z"
        fill="currentColor"
      />
      <path
        d="M20.8806 59.0209L33.3733 95.4287C33.4489 95.649 33.1356 95.783 33.0283 95.5762L6.18386 43.819C5.98411 43.4339 6.49595 43.0788 6.78684 43.4008L20.8422 58.9558C20.8593 58.9747 20.8724 58.9969 20.8806 59.0209Z"
        fill={bgFill}
      />
      <path
        opacity="0.6"
        d="M48.8473 90.6728L65.6582 57.2734"
        stroke="url(#paint1_linear_1697_11192)"
        strokeLinecap="round"
      />
      <path
        opacity="0.6"
        d="M8.87483 61.4554L0.769531 45.5059"
        stroke="url(#paint2_linear_1697_11192)"
        strokeLinecap="round"
      />
      <defs>
        <linearGradient
          id="paint1_linear_1697_11192"
          x1="67.7972"
          y1="58.1857"
          x2="54.9076"
          y2="92.9199"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0698491" stopColor="currentColor" stopOpacity="0" />
          <stop offset="1" stopColor="currentColor" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1697_11192"
          x1="4.56114"
          y1="46.507"
          x2="6.2222"
          y2="61.7448"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0698491" stopColor="currentColor" stopOpacity="0" />
          <stop offset="1" stopColor="currentColor" />
        </linearGradient>
      </defs>
    </svg>
  )
}
