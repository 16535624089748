import Accounts from 'components/Accounts'
import ModalTitle from 'components/ModalTitle'
import { ReactComponent as IconBackArrow } from 'icons/back-arrow.svg'
import { openConnectWalletSuccessModal } from 'models/flow'
import { useTheme } from 'themeContext'

export default function ConnectWalletSelectAccountModal() {
  const dark = useTheme()

  const cn = {
    text: dark ? 'text-white' : 'text-black',
  }

  const handleBackClick = () => {
    openConnectWalletSuccessModal()
  }

  return (
    <div className={`${cn.text} flex flex-col flex-grow text-black`}>
      <div className="flex items-center mb-[1.85rem]">
        <IconBackArrow
          className="mr-[0.75rem] w-[1.25rem] h-[0.85rem] cursor-pointer"
          onClick={handleBackClick}
        />
        <ModalTitle className="mb-0 leading-none text-left">
          Select account
        </ModalTitle>
      </div>
      <Accounts />
    </div>
  )
}
