import { Button, ModalTitle } from 'components'
import { closeModal } from 'models/flow'
import { t } from 'utils'
import { ReactComponent as ExclamationMarkRedIcon } from 'icons/exclamation-mark-red.svg'

export default function OptInToAssetModal() {
  const handleClick = () => {
    closeModal()
  }

  return (
    <div className="flex flex-col flex-grow">
      <ModalTitle>{t('modal_optin_to_asset_heading')}</ModalTitle>

      <div className="font-mono text-black/75 leading-[1.2rem] text-base mb-[3.2rem]">
        <div className="flex items-start ">
          <ExclamationMarkRedIcon className="shrink-0 w-[1.4rem] h-[1.4rem]" />
          <p className="ml-[1rem]">
            Before an account can receive a specific asset it must opt-in to
            receive it. You only need to do this once.
          </p>
        </div>
      </div>

      <Button type="slide" onClick={handleClick}>
        Ok
      </Button>
    </div>
  )
}
