import Logo from 'components/Logo'
import { ModalTitle } from 'components'
import Button from 'components/Button'
import {
  openConnectWalletModal,
  openConnectWalletSuccessModal,
  openConnectChoiceAccountModal,
  $connectWalletFailedMsg,
} from 'models/flow'
import { useStore } from 'effector-react'
import { $accounts } from 'models/algo'
import { $userSession } from 'models/user'
import { isEmpty } from 'lodash'

interface Props {
  dark?: boolean
}

export default function TxFailureModal({ dark }: Props) {
  const accounts = useStore($accounts)
  const userSession = useStore($userSession)
  const msg = useStore($connectWalletFailedMsg)

  const handleClick = () => {
    if (accounts.length && !isEmpty(userSession.address)) {
      openConnectWalletSuccessModal()
      return
    }

    if (accounts.length && isEmpty(userSession.address)) {
      openConnectChoiceAccountModal()
      return
    }

    openConnectWalletModal()
  }

  return (
    <div className="flex flex-col flex-grow">
      <ModalTitle className="!text-red text-center">
        Connection failed
      </ModalTitle>
      <p
        className={`text-center font-mono ${
          dark ? 'text-white' : 'text-black'
        } leading-[1.35rem] text-base`}
      >
        {msg ? msg : 'Something went wrong'}
      </p>
      <div className="flex flex-col items-center">
        <Logo
          bgFill="#a3a3a3"
          className="text-[#d9d9d9] w-[6rem] h-[6.5rem] mt-[2rem] mb-[2rem] "
        />
      </div>
      <Button className="w-full" onClick={handleClick} type="slide">
        Try again
      </Button>
    </div>
  )
}
