import ReactTooltip from 'react-tooltip'
import { useStore } from 'effector-react'
import { ReactComponent as ExclamationMarkRedIcon } from 'icons/exclamation-mark-red.svg'
import { $accountsData, AccountData, optInFx } from 'models/algo'
import { openConnectWalletSuccessModal } from 'models/flow'
import { $userSession, authFx } from 'models/user'
import { toDecimal } from 'utils/numbers'
import ReactDOMServer from 'react-dom/server'
import { useTheme } from 'themeContext'
import ButtonRocket from 'components/ButtonRocket'
import { useWallet } from '@txnlab/use-wallet'

const MIN_ALGO_FOR_OPT_IN = 4.6665

const insufficientFundsText =
  'The current balance of this address is {{amount}}, in order to opt-in to AlgoDAO with this address you must maintain a minimum balance of 4.6665 ALGO'

export default function Accounts() {
  const accountsData = useStore($accountsData)
  const userSession = useStore($userSession)
  const { providers, signTransactions } = useWallet()

  const currentProvider = providers?.find((provider) => provider.isConnected)

  const dark = useTheme()

  const cn = {
    text: dark ? 'text-white' : 'text-black',
    bgAccounts: dark ? 'bg-blackDeep' : 'bg-grayUltraLight',
  }

  const buttonHandler = (account: AccountData) => {
    currentProvider?.setActiveAccount(account.address!)
    if (account.isOptIn) {
      if (account.address === userSession.address) {
        openConnectWalletSuccessModal()
        return
      }
      authFx({ address: account.address!, signTransactions })
    } else {
      //TODO fix type for sendTransactions
      //@ts-ignore
      optInFx({ address: account.address!, signTransactions })
    }
  }

  return (
    <div className="max-h-[25rem] flex flex-col space-y-[1rem] overflow-y-auto">
      {accountsData.map((account) => {
        if (account.address === userSession.address) return null

        const insufficientFundsForOptIn =
          toDecimal(account.amount).lessThan(toDecimal(MIN_ALGO_FOR_OPT_IN)) &&
          !account.isOptIn

        const cantOptIn = insufficientFundsForOptIn

        return (
          <div
            key={`account-${account.shortAddress}`}
            className="grid grid-cols-4 gap-x-[0.7rem] font-aldrich"
          >
            <div
              className={`${cn.bgAccounts} col-span-3 flex flex-col ml:flex-row justify-between rounded-sm pl-[1.15rem] pr-[1.05rem] pt-[0.7rem] pb-[0.55rem]`}
            >
              <div>
                <div className={cn.text}>{account.shortAddress}</div>
                <div className="text-[0.8rem] text-grayLight">
                  {account.name}
                </div>
              </div>
              <div>
                <div
                  className={`
                    flex items-center justify-start ml:justify-end
                    ${cantOptIn ? 'text-red' : cn.text}
                  `}
                >
                  <span
                    className={`${
                      cantOptIn ? 'text-red' : cn.text
                    } mr-[0.3rem]`}
                  >
                    {account.amount}
                  </span>
                  <span
                    className={`${
                      insufficientFundsForOptIn ? 'text-red' : 'text-grayLight'
                    }`}
                  >
                    {account.symbol}
                  </span>
                  {insufficientFundsForOptIn && (
                    <div
                      data-html
                      data-tip={ReactDOMServer.renderToString(
                        <div className="text-grayLight">
                          {insufficientFundsText.replace(
                            /{{amount}}/,
                            account.amount!.toString()
                          )}
                        </div>
                      )}
                      data-for="insufficientFundsForOptIn"
                    >
                      <ExclamationMarkRedIcon className="cursor-pointer relative -top-[0.1rem] ml-[0.2rem] w-[1.1rem] h-[1.1rem]" />
                    </div>
                  )}
                </div>
                <div
                  className={`text-[0.8rem] text-right uppercase
                  hidden ml:block
                    ${
                      insufficientFundsForOptIn ? 'text-red' : 'text-grayLight'
                    }`}
                >
                  {insufficientFundsForOptIn && 'Insufficient '}balance
                </div>
              </div>
            </div>

            {account.pending ? (
              <ButtonRocket />
            ) : (
              <button
                onClick={() => {
                  buttonHandler(account)
                }}
                className={`
                    text-white flex items-center justify-center rounded-sm cursor-pointer
disabled:cursor-default
                      ${
                        account.isOptIn
                          ? 'bg-redButtonGradient'
                          : cantOptIn
                          ? 'bg-grayLightSecond'
                          : 'bg-blueButtonGradient'
                      }    
                    `}
                disabled={cantOptIn}
              >
                {!account.isOptIn ? 'Opt-In' : 'Use'}
              </button>
            )}
          </div>
        )
      })}
      <ReactTooltip
        className={dark ? 'react-tooltip-black' : 'react-tooltip-white'}
        id="insufficientFundsForOptIn"
      />
    </div>
  )
}
