import { Provider, useWallet } from '@txnlab/use-wallet'
import { ModalTitle } from 'components'
import { useStore } from 'effector-react'
import { createAccountsDataFx, setAccounts } from 'models/algo'
import {
  openConnectChoiceAccountModal,
  openConnectWalletFailedModal,
  openConnectWalletModal,
  openConnectWalletSuccessModal,
  openConnectWalletWaitModal,
} from 'models/flow'
import { $userSession } from 'models/user'
import { useEffect } from 'react'
import { useTheme } from 'themeContext'
import { t } from 'utils'

export default function ConnectWalletButton() {
  const dark = useTheme()
  const { providers, activeAccount } = useWallet()
  console.log('activeAccount: ', activeAccount)
  const userSession = useStore($userSession)

  const cn = {
    text: dark ? 'text-white' : 'text-black',
    bgWallet: dark
      ? 'bg-black2 hover:bg-black4'
      : 'bg-grayUltraLight hover:bg-[#ddd]',
  }

  const handleTermsClick = () => {
    const url = '/pdf/algodao-terms-of-use.pdf'
    window.open(url, '_blank', 'noopener,noreferrer')
  }
  const connectWallet = async (provider: Provider) => {
    try {
      openConnectWalletWaitModal()
      await provider.connect()
      // modal wallets unmount the component. so we open a new wallet connection window
      openConnectWalletModal()
    } catch (error) {
      openConnectWalletFailedModal()
    }
  }

  useEffect(() => {
    const connectedProviderIndex =
      providers?.findIndex((provider) => provider.isConnected) ?? -1

    const updateAccounts = async (index: number) => {
      if (providers && index > -1) {
        setAccounts(providers[index].accounts)
        await createAccountsDataFx()
        if (userSession.address !== '') {
          openConnectWalletSuccessModal()
        } else {
          openConnectChoiceAccountModal()
        }
      }
    }

    if (connectedProviderIndex !== -1) {
      updateAccounts(connectedProviderIndex)
    }
  }, [providers, userSession.address])

  if (!providers) return null

  return (
    <div className="flex flex-col flex-grow">
      <ModalTitle>{t('connect_wallet')}</ModalTitle>
      <div className="">
        {providers?.map((provider) => {
          return (
            <div className="flex" key={provider.metadata.id}>
              <div
                key={provider.metadata.id}
                onClick={() => {
                  connectWallet(provider)
                }}
                className={`${cn.bgWallet} ${cn.text} mb-[0.5rem] leading-[1.2] px-[1.7rem] py-[1rem] flex cursor-pointer items-center w-full outline-none`}
              >
                <div className="flex items-center">
                  <p className={`max-w-[70px] mm:max-w-none mr-2 `}></p>
                  <div className="mr-[1.2rem]">
                    <img
                      className="w-[1.5rem] h-[1.5rem]"
                      alt={`${provider.metadata.name} icon`}
                      src={provider.metadata.icon}
                    />
                  </div>
                </div>
                <p>{provider.metadata.name}</p>
              </div>
            </div>
          )
        })}
      </div>
      <p className="text-base text-grayLight mt-[0.5rem]">
        By connecting a wallet you accept our{' '}
        <span
          onClick={handleTermsClick}
          className="underline cursor-pointer hover:text-grayBc"
        >
          Terms of Use
        </span>
        .
      </p>
    </div>
  )
}
