interface Props {
  color: string
  className?: string
}

export default function Tier5({ color, className = '' }: Props) {
  return (
    <svg
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w4.org/2000/svg"
      className={className}
    >
      <path
        d="M47.4801 15.2215C48.263 13.9032 50.1714 13.9032 50.9543 15.2215L70.748 48.5531L89.3278 81.5547C90.1665 83.0444 88.8911 84.8375 87.2087 84.534L49.5759 77.7444C49.3387 77.7016 49.0957 77.7016 48.8585 77.7444L11.2257 84.534C9.54328 84.8375 8.26786 83.0444 9.10656 81.5547L27.6864 48.5531L47.4801 15.2215Z"
        fill={color}
      />
      <path
        d="M49.8492 34.4704C49.5964 33.9366 48.8368 33.9366 48.5839 34.4704L32.3816 68.6787C32.116 69.2394 32.6571 69.8428 33.2433 69.6398L48.9875 64.1872C49.1359 64.1358 49.2972 64.1358 49.4456 64.1872L65.1898 69.6398C65.776 69.8428 66.3171 69.2394 66.0515 68.6787L49.8492 34.4704Z"
        fill="url(#paint0_linear_1054_613)"
      />
      <path
        d="M84.8643 57.9043L93 72.2112"
        stroke="#DFDFDF"
        strokeWidth="2.02027"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.0107 18.415L18.2954 48.2489"
        stroke="#DFDFDF"
        strokeWidth="2.02027"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1054_613"
          x1="74.825"
          y1="38.8004"
          x2="9.72359"
          y2="77.604"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#557FE7" />
          <stop offset="1" stopColor="#8BA5E7" />
        </linearGradient>
      </defs>
    </svg>
  )
}
