import { PoolStatus } from 'gqlgen/public'
import React from 'react'
import { PoolInfoType } from 'types'
import { TIMESTAMP_START } from 'utils/dayjs'
import Timer from './Timer'

interface Props {
  pool: PoolInfoType
  smallText?: boolean
}

export default function PoolStatusText({ pool, smallText }: Props) {
  const contentPublic = React.useMemo(() => {
    switch (pool.status) {
      case PoolStatus.ComingSoon:
      case PoolStatus.Registration:
      case PoolStatus.Whitelisting:
      case PoolStatus.Prelaunch:
        return pool.time.start && pool.time.start !== TIMESTAMP_START ? (
          <Timer
            text="IDO begins in"
            endTime={pool.time.start}
            smallText={smallText}
          />
        ) : (
          <div className="text-center">IDO is coming soon</div>
        )
      case PoolStatus.InProgress:
        return (
          <Timer
            text="Sale ends in"
            endTime={pool.time.end}
            smallText={smallText}
          />
        )
      case PoolStatus.SoldOut:
      case PoolStatus.SuccessfullyFinished:
      case PoolStatus.Failed:
        return <div className="text-base text-center">IDO is finished</div>
      default:
        return null
    }
  }, [pool.status, pool.time.start, pool.time.end, smallText])

  const contentPrivate = React.useMemo(() => {
    switch (pool.status) {
      case PoolStatus.ComingSoon:
        return pool.time.registration &&
          pool.time.registration !== TIMESTAMP_START ? (
          <Timer
            text="Registration opens in"
            endTime={pool.time.registration}
            smallText={smallText}
          />
        ) : (
          <div className="text-center">
            Registration dates to be announced. Follow updates.
          </div>
        )
      case PoolStatus.Registration:
        return (
          <Timer
            text={
              pool.time.prelaunch && pool.time.prelaunch !== TIMESTAMP_START
                ? 'Registration ends in'
                : 'Registration is in progress'
            }
            endTime={pool.time.prelaunch}
            smallText={smallText}
          />
        )
      case PoolStatus.Whitelisting:
      case PoolStatus.Prelaunch:
        return pool.time.start && pool.time.start !== TIMESTAMP_START ? (
          <Timer
            text="IDO begins in"
            endTime={pool.time.start}
            smallText={smallText}
          />
        ) : (
          <div className="text-center">IDO is coming soon</div>
        )
      case PoolStatus.InProgress:
        return (
          <Timer
            text="Sale ends in"
            endTime={pool.time.end}
            smallText={smallText}
          />
        )
      case PoolStatus.SoldOut:
      case PoolStatus.SuccessfullyFinished:
      case PoolStatus.Failed:
        return <div className="text-base text-center">IDO is finished</div>
      default:
        return null
    }
  }, [
    pool.status,
    pool.time.registration,
    pool.time.start,
    pool.time.prelaunch,
    pool.time.end,
    smallText,
  ])

  return pool.isPublic ? contentPublic : contentPrivate
}
