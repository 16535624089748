import { ReactNode } from 'react'

type TitleProps = {
  children: ReactNode | string
  type?: 'red' | 'default' | 'lines'
  className?: string
}

export default function Title({
  type = 'default',
  children,
  className = '',
}: TitleProps) {
  const cn = {
    text: `
      ${type === 'default' ? 'text-black' : ''}
      ${type === 'red' ? 'uppercase text-red font-aldrich text-[2.1rem]' : ''}
      ${
        type === 'lines'
          ? 'uppercase text-grayLight font-aldrich text-[2.1rem]'
          : ''
      }
    `,
    separator: `
      ${type === 'default' ? 'w-full h-[1px] bg-gradient-to-r from-[#ddd]' : ''}
      ${type === 'red' ? 'w-full h-[1px] bg-gradient-to-r from-red' : ''}
      ${
        type === 'lines'
          ? 'hidden t:block t:w-full h-[1rem] bg-detailsTitleSep bg-cover relative -top-[0.2rem]'
          : ''
      }
    }`,
  }
  return (
    <div className={`flex items-center grow mb-[1.6rem]`}>
      <div className={`${className} ${cn.text} text-[1.3rem]`}>{children}</div>

      <div className={`${cn.separator} ml-[1.3rem]`}></div>
    </div>
  )
}
