import {
  attach,
  createEffect,
  createEvent,
  createStore,
  restore,
} from 'effector'
import { persist } from 'effector-storage/session'
import { GeneralInfoQuery, KycInfo, PoolStatus } from 'gqlgen/public'
import { $userSession } from 'models/user'
import { graphqlSdk } from 'utils/consts'
import { toDecimal } from 'utils/numbers'

// Staking
export const emptyStakingInfo: GeneralInfoQuery['stakingInfo'] = {
  apy: '0',
  unstakingFeeRatio: '4',
  // @ts-ignore
  stakingToken: {
    symbol: '',
  },
  stakingBalance: '0',
  walletBalance: '0',

  // @ts-ignore
  rewardToken: {
    symbol: '',
  },
  rewardAvailable: '0',
  rewardClaimed: '0',
  rewardTotal: '0',
  rewardTotalUSD: '0',
  tiers: [],
}

export const setStakingInfo = createEvent<GeneralInfoQuery['stakingInfo']>()
export const setPools = createEvent<GeneralInfoQuery['pools']>()
export const setIdoCurrentPoolName = createEvent<string>()
export const setIdoCurrentPoolAddress = createEvent<string>()
// export const setIdoCurrentPoolNetwork = createEvent<BlockchainNetwork>()
export const setKycInfo = createEvent<KycInfo>()
export const openApplyForKYCLink = createEvent()

export const $stakingInfo = restore(setStakingInfo, emptyStakingInfo)
persist({ store: $stakingInfo, key: 'stakingInfo' })

export const $poolRedirects = createStore(
  process.env.REACT_APP_POOL_REDIRECTS?.split(',').reduce((res, pair) => {
    const [from, to] = pair.split('->', 2)
    res[from] = to
    return res
  }, {} as Record<string, string>) ?? {}
)

export const $isSinglePoolMode = createStore(
  !!process.env.REACT_APP_SINGLE_POOL_ADDRESS
)

export const $stakingAllowed = $stakingInfo.map(
  ({ account }) =>
    toDecimal(account?.allowance).greaterThanOrEqualTo(
      toDecimal(account?.walletBalance)
    ) //.greaterThan(0)
)

export const $kycInfo = restore(setKycInfo, null)

export const $pools = restore(setPools, [])
export const $activePools = $pools.map((pools) =>
  pools.filter(
    (pool) =>
      pool.poolStatus === PoolStatus.Registration ||
      pool.poolStatus === PoolStatus.Whitelisting ||
      pool.poolStatus === PoolStatus.Prelaunch ||
      pool.poolStatus === PoolStatus.InProgress
  )
)
export const $upcomingPools = $pools.map((pools) =>
  pools.filter((p) => p.poolStatus === PoolStatus.ComingSoon)
)
export const $finishedPools = $pools.map((pools) =>
  pools.filter(
    (p) =>
      p.poolStatus === PoolStatus.SuccessfullyFinished ||
      p.poolStatus === PoolStatus.SoldOut ||
      p.poolStatus === PoolStatus.Failed
  )
)

export const $idoCurrentPoolName = restore(setIdoCurrentPoolName, '')
export const $idoCurrentPoolAddress = restore(setIdoCurrentPoolAddress, '')

export const fetchGeneralInfoFx = createEffect(async () => {
  const { session } = $userSession.getState()
  const stakingAppID = process.env.REACT_APP_STAKING_APP_ID
  if (!stakingAppID) {
    return
  }
  try {
    const { pools, stakingInfo } = await graphqlSdk.GeneralInfo({
      session,
      // contract: 'Stake',
      address: stakingAppID,
    })
    // @LOCAL
    setPools(pools)
    setStakingInfo(stakingInfo)
  } catch (err) {
    // todo: report error
  }
})

export const fetchKYCInfoFx = attach({
  source: $userSession,
  async effect(userSession) {
    if (userSession.session) {
      const { kycInfo } = await graphqlSdk.KYCInfo({
        session: userSession.session,
      })
      setKycInfo(kycInfo)
    }
    // @LOCAL
    //setKycInfo({ ...kycInfo, status: KycStatus.Approved })
  },
})

export const openApplyForKYCLinkFx = createEffect((kycInfo: KycInfo | null) => {
  let url = kycInfo?.generalLink ?? ''
  if (kycInfo?.account?.resubmitLink) {
    url = kycInfo?.account?.resubmitLink
  }
  window.open(url, '_blank', 'noopener,noreferrer')
})
