import * as algosdk from 'algosdk'
import { Transaction } from 'algosdk'
import AlgodClient from 'algosdk/dist/types/client/v2/algod/algod'
import {
  combine,
  createEffect,
  createEvent,
  createStore,
  restore,
} from 'effector'
import { persist } from 'effector-storage/local'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { $stakingInfo } from 'models/app'
import {
  openTxFailModal,
  openTxSuccessModal,
  setTxHash,
  ModalState,
  $modalState,
} from 'models/flow'
import { $userSession } from 'models/user'
import { WalletStatus } from 'utils/wallets'

type Account = { address: string; name?: string }
export type Accounts = Account[]

export const setAccounts = createEvent<Accounts>()
export const $accounts = restore(setAccounts, [])

persist({ store: $accounts, key: 'accounts' })

export const setIsPending = createEvent<boolean>()
export const $isTransactionPending = restore(setIsPending, false)

export interface Asset {
  amount: number
  'asset-id': number
  creator: string
  deleted: boolean
  'is-frozen': boolean
  'opted-in-at-round': number
}

export interface AccountData {
  address?: string
  shortAddress?: string
  name?: string
  amount?: number
  symbol?: string
  appsCount?: number
  isOptIn?: boolean
  appConnected?: boolean
  asaStake?: Asset
  asaReward?: Asset
  pending?: boolean
}

type AccountsData = AccountData[]
export type AlgodIndexer = algosdk.Indexer
export const setAccountsData = createEvent<AccountsData>()
export const $accountsData = restore(setAccountsData, [])

export const $algosdk = createStore(algosdk)

export const $algodClient = createStore(
  new algosdk.Algodv2(
    process.env.REACT_APP_ALGO_API_TOKEN ?? '',
    process.env.REACT_APP_ALGO_API_ENDPOINT ?? '',
    process.env.REACT_APP_ALGO_API_PORT ?? ''
  )
)

export const $algodIndexer = createStore(
  new algosdk.Indexer(
    process.env.REACT_APP_ALGO_INDEXER_TOKEN ?? '',
    process.env.REACT_APP_ALGO_INDEXER_ENDPOINT ?? '',
    process.env.REACT_APP_ALGO_INDEXER_PORT ?? ''
  )
)

export const addOptInAddressToPending = createEvent<string>()
export const removeOptInAddressFromPending = createEvent<string>()
export const $optInAddressesPending = createStore<string[]>([])

export const setWalletStatus = createEvent<{
  name: string
  status: WalletStatus
}>()

export const $walletStatus = createStore<{
  [walletName: string]: WalletStatus
}>({})

export const $gotAccountsButNoSession = combine(
  $accounts,
  $userSession,
  (accounts, userSession) => accounts.length > 0 && isEmpty(userSession.address)
)

export const createAccountsDataFx = createEffect(async () => {
  const stakingInfo = $stakingInfo.getState()
  const accounts = $accounts.getState()
  const optInAddressesPending = $optInAddressesPending.getState()
  const algosdk = $algosdk.getState()
  const userSession = $userSession.getState()
  const modalState = $modalState.getState()

  const createEmpty = (account: Account) => ({
    address: account.address,
    shortAddress: `${account.address.slice(0, 7)}...${account.address.slice(
      -4
    )}`,
    name: account?.name,
    amount: 0,
    symbol: 'ALGO',
    appsCount: 0,
    isOptIn: false,
    appConnected: false,
    asaStake: '',
    asaReward: '',
    pending: false,
  })

  // this code to reduce requests
  let currentAccounts = accounts
  if (
    userSession.address &&
    modalState !== ModalState.ConnectWalletSelectAccount
  ) {
    currentAccounts = accounts.filter((account) => {
      return account.address === userSession.address
    })
  }
  // end

  const accountsData = await Promise.all(
    currentAccounts.map(async (account: Account) => {
      try {
        const indexer = $algodIndexer.getState()
        const accountInfo = await indexer
          .lookupAccountByID(account.address)
          .exclude('all')
          .do()

        const accountApps = await indexer
          .lookupAccountAppLocalStates(account.address)
          .applicationID(Number(process.env.REACT_APP_STAKING_APP_ID))
          .do()

        const accountAssets = await indexer
          .lookupAccountAssets(account.address)
          .do()

        const appsLocalState = get(accountApps, `apps-local-states`, [])
        const appConnected =
          appsLocalState.findIndex(
            (app: { id: number }) =>
              app.id === Number(process.env.REACT_APP_STAKING_APP_ID)
          ) !== -1

        const assets = get(accountAssets, 'assets', [])
        const asaStake = assets.find(
          (asset: { 'asset-id': number }) =>
            asset['asset-id'] === Number(stakingInfo.stakingToken.address)
        )
        const asaReward = assets.find(
          (asset: { 'asset-id': number }) =>
            asset['asset-id'] === Number(stakingInfo.rewardToken.address)
        )

        const amountWithoutPendingRewards = get(
          accountInfo,
          `account['amount-without-pending-rewards']`,
          0
        )

        const isOptIn =
          appConnected && !isEmpty(asaStake) && !isEmpty(asaReward)

        const optInsInPending =
          optInAddressesPending.findIndex(
            (addr) => addr === account.address
          ) !== -1

        let pending = optInsInPending

        if (optInsInPending && isOptIn) {
          removeOptInAddressFromPending(account.address)
          pending = false
        }

        return {
          address: account.address,
          shortAddress: `${account.address.slice(
            0,
            7
          )}...${account.address.slice(-4)}`,
          name: account?.name,
          amount: algosdk.microalgosToAlgos(amountWithoutPendingRewards),
          symbol: 'ALGO',
          appsCount: appsLocalState.length,
          isOptIn,
          appConnected,
          asaStake,
          asaReward,
          pending,
        }
      } catch (err) {
        return createEmpty(account)
      }
    })
  )
  setAccountsData(accountsData)
})

export const optInFx = createEffect(
  async ({
    address,
    signTransactions,
  }: {
    address: string
    signTransactions: SignTransactions
  }) => {
    addOptInAddressToPending(address)
    const stakingInfo = $stakingInfo.getState()
    const algodClient = $algodClient.getState()
    const algosdk = $algosdk.getState()
    const accountsData = $accountsData.getState()
    createAccountsDataFx()

    // CLOSE OUT APP example
    // const params = await algodClient.getTransactionParams().do()
    // const txn = algosdk.makeApplicationCloseOutTxnFromObject({
    //   suggestedParams: {
    //     ...params,
    //   },
    //   from: 'GOULFDS7EQS6HG6MXUAU32ZIIIQHD6EIKZYMQ6ZAXVPCAJVHCBGBCU3IJI',
    //   appIndex: 71588557,
    //   note: new Uint8Array(Buffer.from('')),
    // })

    // const myAlgoConnect = new MyAlgoConnect()
    // const signedTxn = await myAlgoConnect.signTransaction(txn.toByte())
    // await algodClient.sendRawTransaction(signedTxn.blob).do()

    try {
      const note = new Uint8Array(Buffer.from(''))
      const stakingAppId = Number(process.env.REACT_APP_STAKING_APP_ID)
      const stakingAssetId = Number(stakingInfo.stakingToken.address)
      const rewardAssetId = Number(stakingInfo.rewardToken.address)

      const accountInfo = accountsData.find(
        (account) => account.address === address
      )

      let txnsToGroup = []

      if (!accountInfo?.appConnected) {
        const paramsStakingApp = await algodClient.getTransactionParams().do()
        txnsToGroup.push(
          algosdk.makeApplicationOptInTxnFromObject({
            suggestedParams: {
              ...paramsStakingApp,
            },
            from: address,
            appIndex: stakingAppId,
            note: note,
          })
        )
      }

      if (isEmpty(accountInfo?.asaStake)) {
        const paramsAsaStake = await algodClient.getTransactionParams().do()
        txnsToGroup.push(
          algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
            suggestedParams: {
              ...paramsAsaStake,
            },
            from: address,
            to: address,
            assetIndex: stakingAssetId,
            amount: 0,
            note: note,
          })
        )
      }

      if (isEmpty(accountInfo?.asaReward)) {
        const paramsAsaRewards = await algodClient.getTransactionParams().do()
        txnsToGroup.push(
          algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
            suggestedParams: {
              ...paramsAsaRewards,
            },
            from: address,
            to: address,
            assetIndex: rewardAssetId,
            amount: 0,
            note: note,
          })
        )
      }

      const txnsArray = await algosdk.assignGroupID(txnsToGroup)

      const encodedTransactions = txnsArray.map((transaction) =>
        algosdk.encodeUnsignedTransaction(transaction)
      )

      const stxn = await signTransactions(encodedTransactions)
      const tx = await algodClient.sendRawTransaction(stxn).do()

      await algosdk.waitForConfirmation(algodClient, tx.txId, 4)

      createAccountsDataFx()
    } catch (err: unknown) {
      let msg = ''

      if (
        err &&
        err instanceof Error &&
        err.message &&
        err.message.includes('Network request error')
      ) {
        msg =
          'In order to opt-in to new contracts you need to either increase your ALGO balance or opt-out from some of the current applications'
      }
      removeOptInAddressFromPending(address)
      openTxFailModal(msg)
      createAccountsDataFx()
      console.error('err', err)
    }
  }
)

export const signTransactionFn = async ({
  signTransactions,
  algodClient,
  transactions,
}: {
  signTransactions: SignTransactions
  algodClient: AlgodClient
  transactions: Transaction[]
}) => {
  const encodedTransactions = transactions.map((transaction) =>
    algosdk.encodeUnsignedTransaction(transaction)
  )

  const stxn = await signTransactions(encodedTransactions)
  const tx = await algodClient.sendRawTransaction(stxn).do()

  setTxHash(tx.txId)
  openTxSuccessModal()
  return algosdk.waitForConfirmation(algodClient, tx.txId, 4)
}

export const signTransaction = createEvent<{
  transactions: Transaction[]
  signTransactions: SignTransactions
}>()

export const signTransactionFx = createEffect(
  async (args: {
    signTransactions: SignTransactions
    algodClient: AlgodClient
    transactions: Transaction[]
  }) => {
    signTransactionFn(args)
  }
)
