import { ReactElement } from 'react'
import { ReactComponent as TierBgUnauthorized } from 'icons/tiers/tier-bg-login.svg'
import { ReactComponent as TierBg0 } from 'icons/tiers/tier-bg-0.svg'
import { ReactComponent as TierBg1 } from 'icons/tiers/tier-bg-1.svg'
import { ReactComponent as TierBg2 } from 'icons/tiers/tier-bg-2.svg'
import { ReactComponent as TierBg3 } from 'icons/tiers/tier-bg-3.svg'
import { ReactComponent as TierBg4 } from 'icons/tiers/tier-bg-4.svg'
import { ReactComponent as TierBg5 } from 'icons/tiers/tier-bg-5.svg'
import TierUnauthorized from './TierUnauthorized'
import Tier0 from './Tier0'
import Tier1 from './Tier1'
import Tier2 from './Tier2'
import Tier3 from './Tier3'
import Tier4 from './Tier4'
import Tier5 from './Tier5'
import { TierUnion } from 'types'

export type TierMapType = {
  [key in TierUnion]: {
    icon: ReactElement
    animal: string
    name: string
    bg: ReactElement
    roman: string | null
  }
}

export const tiersMapFn: (dark: boolean) => TierMapType = (dark) => {
  const tierCn = `w-full h-full`
  const tierBgCn = 'h-[9.8rem]'
  const bgOpacity = dark ? 0.08 : 1
  const color = dark ? '#51555E' : '#292B33'

  return {
    login: {
      icon: (
        <TierUnauthorized className={tierCn} color={dark ? color : '#E6E6E6'} />
      ),
      animal: '',
      name: 'undetermined',
      bg: <TierBgUnauthorized className="h-[9.8rem]" fillOpacity={bgOpacity} />,
      roman: null,
    },
    0: {
      icon: <Tier0 className={tierCn} color={dark ? color : '#E6E6E6'} />,
      animal: '',
      name: 'undetermined',
      bg: <TierBg0 className={tierBgCn} fillOpacity={bgOpacity} />,
      roman: null,
    },
    1: {
      icon: <Tier1 className={tierCn} color={color} />,
      name: 'Fleet Commander',
      animal: 'bg-tierAnimal1',
      bg: (
        <TierBg5
          className={tierBgCn}
          fillOpacity={bgOpacity}
          strokeOpacity={bgOpacity}
        />
      ),
      roman: 'I',
    },
    2: {
      animal: 'bg-tierAnimal2',
      icon: <Tier2 className={tierCn} color={color} />,
      name: 'Flagship Commander',
      bg: (
        <TierBg4
          className={tierBgCn}
          fillOpacity={bgOpacity}
          strokeOpacity={bgOpacity}
        />
      ),
      roman: 'II',
    },
    3: {
      icon: <Tier3 className={tierCn} color={dark ? color : '#E6E6E6'} />,
      name: 'Vice Admiral',
      animal: 'bg-tierAnimal3',
      bg: (
        <TierBg3
          className={tierBgCn}
          fillOpacity={bgOpacity}
          strokeOpacity={bgOpacity}
        />
      ),
      roman: 'III',
    },
    4: {
      icon: <Tier4 className={tierCn} color={dark ? color : '#e6e6e6'} />,
      animal: 'bg-tierAnimal4',
      name: 'Lieutenant',
      bg: (
        <TierBg2
          className={tierBgCn}
          fillOpacity={bgOpacity}
          strokeOpacity={bgOpacity}
        />
      ),
      roman: 'IV',
    },
    5: {
      icon: <Tier5 className={tierCn} color={dark ? color : '#e6e6e6'} />,
      name: 'Ensign',
      animal: 'bg-tierAnimal5',
      bg: (
        <TierBg1
          className={tierBgCn}
          fillOpacity={bgOpacity}
          strokeOpacity={bgOpacity}
        />
      ),
      roman: 'V',
    },
  }
}

export default tiersMapFn
