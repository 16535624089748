interface Props {
  color: string
  className?: string
}

export default function Tier1({ className = '', color }: Props) {
  return (
    <svg
      viewBox="0 0 101 100"
      fill="none"
      xmlns="http://www.w4.org/2000/svg"
      className={className}
    >
      <g>
        <circle cx="49.9986" cy="50.3472" r="36.7808" fill={color} />
        <path
          d="M57.7314 30.0625L61.5685 47.3499"
          stroke="white"
          strokeWidth="1.99903"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M39.9941 44.5918L38.1958 50.3486"
          stroke="white"
          strokeWidth="1.99903"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle
          cx="50"
          cy="50.3477"
          r="47.0005"
          stroke={color}
          strokeWidth="1.99903"
        />
        <path
          d="M49.2988 25.7562C49.3675 25.3095 50.0084 25.302 50.0876 25.7469L54.786 52.1555C54.9212 52.9156 55.2019 53.6424 55.6127 54.2961L65.2641 69.6532C65.4961 70.0224 65.0536 70.4435 64.6963 70.1935L53.1035 62.0815C51.0389 60.6369 48.2915 60.6369 46.227 62.0815L34.7606 70.105C34.3982 70.3586 33.9532 69.9229 34.199 69.5552L44.3229 54.4128C44.8101 53.684 45.1316 52.8574 45.2649 51.991L49.2988 25.7562Z"
          fill="white"
        />
      </g>
    </svg>
  )
}
