interface Props {
  color: string
  className?: string
}

export default function TierUnauthorized({ className = '', color }: Props) {
  return (
    <svg
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_1080_437)">
        <path
          d="M48.2763 2.55084C49.1016 1.32924 50.9003 1.32925 51.7256 2.55084L62.0703 17.8626C62.5372 18.5537 63.3717 18.8994 64.1906 18.7408L82.3324 15.2286C83.7798 14.9484 85.0516 16.2202 84.7714 17.6676L81.2592 35.8094C81.1006 36.6283 81.4463 37.4628 82.1374 37.9297L97.4492 48.2744C98.6708 49.0997 98.6708 50.8984 97.4492 51.7237L82.1374 62.0683C81.4463 62.5353 81.1006 63.3697 81.2592 64.1886L84.7714 82.3304C85.0516 83.7778 83.7798 85.0497 82.3324 84.7695L64.1906 81.2572C63.3717 81.0987 62.5372 81.4443 62.0703 82.1355L51.7256 97.4472C50.9003 98.6688 49.1016 98.6688 48.2763 97.4472L37.9317 82.1355C37.4647 81.4443 36.6303 81.0987 35.8114 81.2572L17.6696 84.7695C16.2222 85.0497 14.9503 83.7778 15.2305 82.3304L18.7428 64.1886C18.9013 63.3697 18.5557 62.5353 17.8645 62.0683L2.55279 51.7237C1.3312 50.8984 1.3312 49.0997 2.55279 48.2744L17.8645 37.9297C18.5557 37.4628 18.9013 36.6283 18.7428 35.8094L15.2305 17.6676C14.9503 16.2202 16.2222 14.9484 17.6696 15.2286L35.8114 18.7408C36.6303 18.8994 37.4647 18.5537 37.9317 17.8626L48.2763 2.55084Z"
          fill={color}
        />
        <path
          d="M50.1934 25.7424C50.2343 25.3768 50.7657 25.3768 50.8066 25.7424L53.7244 51.861C53.8385 52.882 54.1778 53.865 54.7178 54.7389L64.257 70.1771C64.454 70.496 64.019 70.8241 63.7665 70.5471L52.7802 58.491C51.5566 57.1482 49.4434 57.1482 48.2198 58.491L37.2335 70.5471C36.981 70.8241 36.546 70.496 36.743 70.1771L46.2822 54.7389C46.8222 53.865 47.1615 52.882 47.2756 51.861L50.1934 25.7424Z"
          fill="url(#paint0_linear_1080_437)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1080_437"
          x1="74.0569"
          y1="92.3525"
          x2="42.1586"
          y2="31.0824"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <clipPath id="clip0_1080_437">
          <rect width="100" height="100" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
