import Button from 'components/Button'
import { ReactComponent as ExclamationMarkRedIcon } from 'icons/exclamation-mark-red.svg'
import ModalTitle from 'components/ModalTitle'
import { t } from 'utils'
import { $poolId } from 'models/flow'
import { useStore } from 'effector-react'
import { optInPool } from 'models/pool'
import { useWallet } from '@txnlab/use-wallet'

export default function RegistrationConfirmationModal() {
  const poolId = useStore($poolId)
  const { signTransactions } = useWallet()

  const handleButtonClick = () => {
    if (poolId) {
      optInPool({ poolId, isPrivate: true, signTransactions })
    }
  }

  return (
    <div className="flex flex-col flex-grow">
      <ModalTitle>{t('modal_confirm_registration_heading')}</ModalTitle>
      <div className="font-mono text-black/75 leading-[1.2rem] text-base mb-[3.2rem]">
        <div className="flex items-start ">
          <ExclamationMarkRedIcon className="shrink-0 w-[1.4rem] h-[1.4rem]" />
          <p className="ml-[1rem]">
            Opt-in to the pool contract is required in order to interact with
            participation terminal. You only need to do this once per IDO.
          </p>
        </div>
        <div className="flex items-start mt-[0.5rem]">
          <ExclamationMarkRedIcon className="shrink-0 w-[1.4rem] h-[1.4rem]" />
          <p className="ml-[1rem]">
            Registration is non-reversible. You can still move to a higher tier
            before the whitelisting stage begins. You should have your tokens
            staked until the end of the IDO, otherwise we do not guarantee
            correct whitelisting and allocation assignment.
          </p>
        </div>
      </div>
      <Button className="w-full" onClick={handleButtonClick} type="slide">
        {t('modal_confirm_registration_button')}
      </Button>
    </div>
  )
}
