import { Redirect, Router } from '@reach/router'
import { Main } from 'components'
import { useStore } from 'effector-react'
import { $isSinglePoolMode } from 'models/app'
import {
  ProjectDetailsRoute,
  StakingRoute,
  DashboardRoute,
  HomeRoute,
} from 'routes'
import { ReactComponent as GlobalSVG } from './global.svg'
import { ThemeProvider } from './themeContext'

import {
  WalletProvider,
  useInitializeProviders,
  PROVIDER_ID,
} from '@txnlab/use-wallet'
import { DeflyWalletConnect } from '@blockshake/defly-connect'
import { PeraWalletConnect } from '@perawallet/connect'
import { DaffiWalletConnect } from '@daffiwallet/connect'

function App() {
  const isSinglePoolMode = useStore($isSinglePoolMode)

  const providers = useInitializeProviders({
    providers: [
      //@ts-ignore
      { id: PROVIDER_ID.ALGOSIGNER },
      { id: PROVIDER_ID.PERA, clientStatic: PeraWalletConnect },
      { id: PROVIDER_ID.DEFLY, clientStatic: DeflyWalletConnect },
      { id: PROVIDER_ID.DAFFI, clientStatic: DaffiWalletConnect },
      { id: PROVIDER_ID.EXODUS },
    ],
  })

  return (
    <WalletProvider value={providers}>
      <Router primary={false}>
        <ThemeProvider path="/">
          <Main path="/">
            {isSinglePoolMode ? (
              <ProjectDetailsRoute path="/IDO" />
            ) : (
              <HomeRoute path="/" />
            )}
            <ProjectDetailsRoute path="project/:id" />
            <StakingRoute path="staking" />
            <DashboardRoute path="dashboard" />
            {isSinglePoolMode ? (
              <Redirect from="*" to="/IDO" noThrow />
            ) : (
              <Redirect from="*" to="/" noThrow />
            )}
          </Main>
        </ThemeProvider>
      </Router>
      <GlobalSVG />
    </WalletProvider>
  )
}

export default App
