import AlgodClient from 'algosdk/dist/types/client/v2/algod/algod'
import Decimal from 'decimal.js'
import {
  attach,
  createEffect,
  createEvent,
  createStore,
  merge,
  restore,
} from 'effector'
import find from 'lodash/find'
import { signTransactionFn, AlgodIndexer, $algodIndexer } from 'models/algo'
import { $stakingInfo } from 'models/app'
import {
  openTxWaitModal,
  openTxFailModal,
  openOptInPoolModal,
  openOptIntoAssetModal,
} from 'models/flow'
import { setStakingTokenDecimals } from 'models/staking'
import { $userSession, UserSession } from 'models/user'
import {
  makeAppCloseOutTransaction,
  makeAppOptInTransaction,
  makeAssetOptInTransaction,
} from 'sdk/common'
import {
  makeClaimTransaction,
  makeRefundTransaction,
  makeReserveTransaction,
  makeReserveWithoutWhitelistTransaction,
} from 'sdk/pool'
import { graphqlSdk } from 'utils/consts'
import { toDecimal } from 'utils/numbers'

type OptInPoolType = 'pending' | 'true' | 'false'

export const setIsOptInPool = createEvent<OptInPoolType>()
export const $isOptInPool = restore(setIsOptInPool, 'pending')

const setTargetTokenBalance = createEvent<string>()
const setStakingTokenBalance = createEvent<string>()
const setRewardTokenBalance = createEvent<string>()

export const $targetTokenBalance = restore(setTargetTokenBalance, '0')
export const $stakingTokenBalance = restore(setStakingTokenBalance, '0')
export const $rewardTokenBalance = restore(setRewardTokenBalance, '0')

export const registerToThePool = createEvent<{ poolId: string }>()
export const registerToThePoolFx = createEffect(
  async ({
    poolAddress,
    session,
  }: {
    poolAddress: string
    session: string
  }) => {
    const res = await graphqlSdk.RegisterPoolParticipation({
      input: {
        poolAddress,
        session,
        blockchain: 'algorand',
      },
    })

    if (res.registerPoolParticipation.success === false) {
      throw Error(`${res.registerPoolParticipation.reason}`)
    }
  }
)

export const buy = createEvent<{
  input: string
  poolId: string
  isPrivate: boolean
  targetTokenId: string
  targetTokenDecimals: number
  signTransactions: (
    transactions: Uint8Array[] | Uint8Array[][],
    indexesToSign?: number[] | undefined,
    returnGroup?: boolean | undefined
  ) => Promise<Uint8Array[]>
}>()

export const buyFx = createEffect(
  async ({
    amount,
    poolId,
    isPrivate,
    algodClient,
    quoteAssetID,
    userSession,
    signTransactions,
  }: {
    amount: number
    poolId: string
    isPrivate: boolean
    algodClient: AlgodClient
    userSession: UserSession
    quoteAssetID: number
    signTransactions: SignTransactions
  }) => {
    openTxWaitModal()
    try {
      const address = $userSession.getState().address

      const algodIndexer = $algodIndexer.getState()
      const accountAppLocalStates = await algodIndexer
        .lookupAccountAppLocalStates(address)
        .do()

      const poolInList = accountAppLocalStates['apps-local-states'].find(
        (item: any) => {
          return item.id.toString() === poolId
        }
      )

      if (!poolInList) {
        await optInPool({
          poolId,
          isPrivate,
          signTransactions,
        })
        return
      }

      let transactions = []

      if (isPrivate) {
        const res = await graphqlSdk.GetMerkleProof({
          pool: poolId,
          session: userSession.session,
        })

        /*
        console.log('buyFx params')
        console.log({
          poolAppID: Number(poolId),
          from: userSession.address,
          tier: res.getMerkleProof?.tier ?? 0,
          wave: res.getMerkleProof?.wave ?? 0,
          proof: res.getMerkleProof?.proof ?? [],
          quoteAssetAmount: amount,
          quoteAssetID: Number(quoteAssetID),
        })
*/

        transactions = await makeReserveTransaction({
          algodClient,
          poolAppID: Number(poolId),
          from: userSession.address,
          tier: res.getMerkleProof?.tier ?? 0,
          wave: res.getMerkleProof?.wave ?? 0,
          proof: res.getMerkleProof?.proof ?? [],
          quoteAssetAmount: amount,
          quoteAssetID: Number(quoteAssetID),
        })
      } else {
        transactions = await makeReserveWithoutWhitelistTransaction({
          algodClient,
          poolAppID: Number(poolId),
          from: userSession.address,
          quoteAssetAmount: amount,
          quoteAssetID: Number(quoteAssetID),
        })
      }

      await signTransactionFn({ transactions, algodClient, signTransactions })
    } catch (err) {
      openTxFailModal()
      console.error('err', err)
      throw Error(`${err}`)
    }
  }
)

export const refund = createEvent<{
  poolId: string
  targetTokenId: string
  signTransactions: SignTransactions
}>()
export const refundFx = createEffect(
  async ({
    poolAppID,
    quoteAssetID,
    algodClient,
    from,
    signTransactions,
  }: {
    poolAppID: number
    quoteAssetID: number
    algodClient: AlgodClient
    from: string
    signTransactions: SignTransactions
  }) => {
    openTxWaitModal()

    try {
      const txn = await makeRefundTransaction({
        algodClient,
        poolAppID,
        from,
        quoteAssetID,
      })
      await signTransactionFn({
        transactions: [txn],
        algodClient,
        signTransactions,
      })
    } catch (err) {
      openTxFailModal()
      console.error('err', err)
      throw Error(`${err}`)
    }
  }
)

export const claimPool = createEvent<{
  poolId: string
  idoAssetID: string
  signTransactions: SignTransactions
}>()
export const claimPoolFx = createEffect(
  async ({
    poolAppID,
    idoAssetID,
    algodClient,
    from,
    signTransactions,
  }: {
    poolAppID: number
    idoAssetID: number
    algodClient: AlgodClient
    from: string
    signTransactions: SignTransactions
  }) => {
    openTxWaitModal()

    try {
      const address = $userSession.getState().address
      const indexer = $algodIndexer.getState()
      const response = await indexer.lookupAccountAssets(address).do()

      const idoAsset = response['assets'].find(
        (item: { 'asset-id': number }) => item['asset-id'] === idoAssetID
      )
      if (!idoAsset) {
        optIntoAsset({ assetId: idoAssetID, signTransactions })
        return
      }

      const txn = await makeClaimTransaction({
        algodClient,
        poolAppID,
        from,
        idoAssetID,
      })

      await signTransactionFn({
        transactions: [txn],
        algodClient,
        signTransactions,
      })
    } catch (err) {
      openTxFailModal()
      console.error('err', err)
      throw Error(`${err}`)
    }
  }
)

export const optIntoAsset = createEvent<{
  assetId: number
  signTransactions: SignTransactions
}>()
export const optIntoAssetFx = createEffect(
  async ({
    algodClient,
    assetID,
    from,
    signTransactions,
  }: {
    algodClient: AlgodClient
    assetID: number
    from: string
    signTransactions: SignTransactions
  }) => {
    openOptIntoAssetModal()

    try {
      const txn = await makeAssetOptInTransaction({
        algodClient,
        assetID,
        from,
      })

      await signTransactionFn({
        transactions: [txn],
        algodClient,
        signTransactions,
      })
    } catch (err) {
      openTxFailModal()

      if (err instanceof Error) {
        console.error(err)
        throw err
      } else {
        console.error(JSON.stringify(err))
        throw Error(`${err}`)
      }
    }
  }
)

export const optInPool = createEvent<{
  poolId: string
  isPrivate: boolean
  signTransactions: SignTransactions
}>()
export const optInPoolFx = createEffect(
  async ({
    appID,
    algodClient,
    from,
    poolId,
    isPrivate,
    signTransactions,
  }: {
    appID: number
    algodClient: AlgodClient
    from: string
    poolId: string
    isPrivate: boolean
    signTransactions: SignTransactions
  }) => {
    openOptInPoolModal()

    try {
      const txn = await makeAppOptInTransaction({
        algodClient,
        appID,
        from,
      })

      await signTransactionFn({
        transactions: [txn],
        algodClient,
        signTransactions,
      })

      if (isPrivate) {
        registerToThePool({ poolId })
      }
      checkOptIn(appID)
    } catch (err: unknown) {
      let msg = ''
      if (
        err &&
        err instanceof Error &&
        err.message &&
        err.message.includes('Network request error')
      ) {
        msg =
          'In order to opt-in to new contracts you need to either increase your ALGO balance or opt-out from some of the current applications'
      }
      openTxFailModal(msg)
      console.error('err', JSON.stringify(err))

      throw Error(`${err}`)
    }
  }
)

export const optOutPool = createEvent<{
  appId: string
  signTransactions: SignTransactions
}>()
export const optOutPoolFx = createEffect(
  async ({
    appID,
    algodClient,
    from,
    signTransactions,
  }: {
    appID: number
    algodClient: AlgodClient
    from: string
    signTransactions: SignTransactions
  }) => {
    openTxWaitModal()

    try {
      const txn = await makeAppCloseOutTransaction({
        algodClient,
        appID,
        from,
      })

      await signTransactionFn({
        transactions: [txn],
        algodClient,
        signTransactions,
      })
      checkOptIn(appID)
    } catch (err) {
      openTxFailModal()
      console.error('err', err)
      throw Error(`${err}`)
    }
  }
)

export const checkOptIn = createEvent<number>()
export const checkOptInFx = createEffect(
  async ({
    poolId,
    from,
    algodIndexer,
  }: {
    poolId: number
    from: string
    algodIndexer: AlgodIndexer
  }) => {
    const appsLocalStates = await algodIndexer
      .lookupAccountAppLocalStates(from)
      .do()
    const found = appsLocalStates['apps-local-states'].find(
      (app: any) => app.id === poolId && !app.deleted
    )

    if (found) {
      setIsOptInPool('true')
    } else {
      setIsOptInPool('false')
    }
  }
)

export const fetchWalletBalancesFx = attach({
  source: [$algodIndexer, $userSession, $stakingInfo],
  async effect([algodIndexer, userSession, stakingInfo]) {
    const address = userSession.address

    const targetTokenAssetID = Number(process.env.REACT_APP_TARGET_TOKEN_ID)
    const stakingTokenAssetID = Number(stakingInfo.stakingToken.address)
    const rewardTokenAssetID = Number(stakingInfo.rewardToken.address)

    if (!address) {
      console.error('no address has been found')
      return
    }

    if (!targetTokenAssetID) {
      console.error('no targetTokenAssetID has been found')
    }

    /*
    console.log('AssetID:')
    console.log({
      targetTokenAssetID,
      stakingTokenAssetID,
      rewardTokenAssetID,
    })
    */

    try {
      const accountAssets = await algodIndexer.lookupAccountAssets(address).do()

      const targetTokenAsset = find(
        accountAssets?.assets,
        (asset) => asset['asset-id'] === targetTokenAssetID
      )

      const stakingTokenAsset = find(
        accountAssets?.assets,
        (asset) => asset['asset-id'] === stakingTokenAssetID
      )

      const rewardTokenAsset = find(
        accountAssets?.assets,
        (asset) => asset['asset-id'] === rewardTokenAssetID
      )

      /*
      console.log('Asset:')
      console.log({
        targetTokenAsset,
        stakingTokenAsset,
        rewardTokenAsset,
      })
      */

      const targetAssetResponse = await algodIndexer
        .lookupAssetByID(targetTokenAssetID)
        .do()

      const stakingAssetResponse = await algodIndexer
        .lookupAssetByID(stakingTokenAssetID)
        .do()

      const rewardAssetResponse = await algodIndexer
        .lookupAssetByID(rewardTokenAssetID)
        .do()

      const targetTokenDecimals =
        targetAssetResponse.asset?.params?.decimals ?? 0

      const stakingTokenDecimals =
        stakingAssetResponse.asset?.params?.decimals ?? 0

      const rewardTokenDecimals =
        rewardAssetResponse.asset?.params?.decimals ?? 0
      /*
      console.log('Decimals:')
      console.log({
        targetTokenDecimals,
        stakingTokenDecimals,
        rewardTokenDecimals,
      })
      */

      if (!targetTokenAsset) {
        console.error('no target token asset has been found')
      }

      const targetBalance = toDecimal(targetTokenAsset?.amount ?? 0)
        .div(Decimal.pow(10, targetTokenDecimals))
        .toString()

      const stakingBalance = toDecimal(stakingTokenAsset?.amount ?? 0)
        .div(Decimal.pow(10, stakingTokenDecimals))
        .toString()

      const rewardBalance = toDecimal(rewardTokenAsset?.amount ?? 0)
        .div(Decimal.pow(10, rewardTokenDecimals))
        .toString()

      setTargetTokenBalance(targetBalance)
      setStakingTokenBalance(stakingBalance)
      setRewardTokenBalance(rewardBalance)
      setStakingTokenDecimals(stakingTokenDecimals)
    } catch (err) {
      console.error(err)
    }
  },
})

export const $buyTransactionDone = createStore(false)
  .on(buyFx, () => false)
  .on(buyFx.done, () => true)
  .on(buyFx.fail, () => false)

export const $buyTransactionPending = createStore(false)
  .on(buyFx, () => true)
  .on(buyFx.doneData, () => false)
  .on(buyFx.fail, () => false)

const poolActionTriggersPending = merge([buyFx, claimPoolFx, refundFx])

const poolActionTriggersDone = merge([
  buyFx.done,
  claimPoolFx.done,
  refundFx.done,
])

const poolActionTriggersFail = merge([
  buyFx.fail,
  claimPoolFx.fail,
  refundFx.fail,
])
const setTransactionFinished = createEvent()

poolActionTriggersDone.watch(() => {
  setTimeout(() => {
    setTransactionFinished()
  }, 5000)
})

export const $poolActionTransactionPending = createStore(false)
  .on(poolActionTriggersPending, () => true)
  .on(setTransactionFinished, () => false)
  .on(poolActionTriggersFail, () => false)

export const $optInOutTransactionPending = createStore(false)
  .on([optInPoolFx, optOutPoolFx], () => true)
  .on(checkOptInFx.done, () => false)
  .on([optInPoolFx.fail, optOutPoolFx.fail], () => false)
