import Logo from 'components/Logo'
import { ModalTitle } from 'components'
import { t } from 'utils'
import { useTheme } from 'themeContext'

export default function TxWaitModal() {
  const dark = useTheme()
  const color = dark ? '!text-white' : '!text-black'
  return (
    <div className="flex flex-col flex-grow">
      <ModalTitle className={color}>{t('modal_tx_wait_heading')}</ModalTitle>
      <p
        className={`text-center font-mono ${color} leading-[1.35rem] text-base`}
      >
        Confirm this transaction in your wallet.
      </p>
      <div className="flex flex-col items-center">
        <Logo
          bgFill={dark ? 'white' : 'black'}
          className="text-red w-[6rem] h-[6.5rem] mt-[4rem] mb-[2rem] "
        />
      </div>
    </div>
  )
}
