import { t } from 'utils'
import { ModalTitle, TextLinkModal } from 'components'
import { ReactComponent as IconBackArrow } from 'icons/back-arrow.svg'
import { openConnectWalletSuccessModal } from 'models/flow'
import { useStore } from 'effector-react'
import { $kycInfo } from 'models/app'
import { useTheme } from 'themeContext'

export default function AboutKYCModal() {
  const kycInfo = useStore($kycInfo)

  const dark = useTheme()

  const handleBackClick = () => {
    openConnectWalletSuccessModal()
  }

  const textColor = !dark ? 'text-black' : 'text-white'
  const textDescColor = !dark ? 'text-black/75' : 'text-white/75'

  return (
    <div className="relative flex flex-col flex-grow">
      <div className="flex items-center mb-[1.85rem]">
        <IconBackArrow
          className="mr-[0.75rem] w-[1.25rem] h-[0.85rem] cursor-pointer"
          onClick={handleBackClick}
        />
        <ModalTitle className="mb-0 leading-none !text-left">
          {t('kyc_verification')}
        </ModalTitle>
      </div>
      <div className="h-[1px] w-full bg-[#ddd]"></div>
      <div className={`${textColor} mt-[1.85rem] mb-[2rem]`}>
        <div className="mb-[1.5rem]">
          <div className="mb-2 leading-none text-[1.3rem]">
            Why passing KYC?
          </div>
          <div className={textDescColor}>
            Passing the KYC process is necessary to ensure that AlgoDAO members
            are in compliance with anti-terrorism financing and anti-money
            laundering requirements. The KYC process also ensure compliance with
            regulations regarding politically-exposed persons or residents of
            restricted jurisdictions.
          </div>
        </div>
        <div className="mb-[1.5rem]">
          <div className="mb-2 leading-none  text-[1.3rem]">
            How long does it take?
          </div>
          <div className={textDescColor}>
            KYC processing time will vary for different applicants, but you can
            generally expect to complete the KYC form within 5 to 10 minutes.
            <br />
            Please note that, after submitting your application, it might take
            more than 12 hours for the verification process to complete.
          </div>
        </div>
        <div>
          <div className="mb-2 leading-none text-[1.3rem]">
            My KYC verification failed. Why?
          </div>
          <div className={textDescColor}>
            If your KYC verification failed, you will most likely receive a
            notification explaining the issue - this could be due to expired or
            unclear documentation, among other things. The notification will
            also include instructions on how to resolve the issue for the
            verification to be completed.
          </div>
        </div>
      </div>
      <TextLinkModal href={kycInfo?.supportLink}>
        KYC Issues & Questions
      </TextLinkModal>
    </div>
  )
}
