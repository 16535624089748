import { ReactComponent as CopyrightMark } from 'icons/copyright-mark.svg'

type TitleProps = {
  type?: 'red' | 'blue' | 'gray'
}

export default function Title({ type = 'red' }: TitleProps) {
  const cn = {
    wrapper: `
      ${type === 'red' ? 'text-white bg-redMainGradient justify-center' : ''}
      ${type === 'blue' ? 'text-white bg-blueMainGradient justify-center' : ''}
      ${type === 'gray' ? 'bg-black2 pl-[5.15rem] text-grayLight' : ''}
    `,
    before: `
      ${type === 'red' ? 'before:bg-footerAfterRed before:top-[-1.6rem]' : ''}
      ${type === 'blue' ? 'before:bg-footerAfterBlue before:top-[-1.6rem]' : ''}
      ${type === 'gray' ? 'before:bg-footerAfterGray before:top-[-1.8rem]' : ''}
    `,
    copy: `
      ${type === 'red' ? 'fill-white' : ''}
      ${type === 'blue' ? 'fill-white' : ''}
      ${type === 'gray' ? 'fill-grayLight' : ''}
    `,
  }
  return (
    <div
      className={`
        leading-none relative flex items-center z-100 bottom-0 w-full h-[3.2rem]
        before:absolute before:bg-contain before:w-full before:h-[1.5rem] before:left-0
        ${cn.wrapper} ${cn.before}
      `}
    >
      <CopyrightMark
        className={`${cn.copy} relative top-[0.1rem] mr-[0.2rem] w-[0.8rem] h-[0.8rem]`}
      />{' '}
      2022 ALGODAO, All rights reserved
    </div>
  )
}
