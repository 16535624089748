import { ButtonRocket, ModalTitle } from 'components'
import { t } from 'utils'
import { ReactComponent as ExclamationMarkRedIcon } from 'icons/exclamation-mark-red.svg'

export default function OptInModal() {
  return (
    <div className="flex flex-col flex-grow">
      <ModalTitle>{t('modal_optin_registration_heading')}</ModalTitle>

      <div className="font-mono text-black/75 leading-[1.2rem] text-base mb-[3.2rem]">
        <div className="flex items-start ">
          <ExclamationMarkRedIcon className="shrink-0 w-[1.4rem] h-[1.4rem]" />
          <p className="ml-[1rem]">
            Opt-in to the pool contract is required in order to interact with
            participation terminal. You only need to do this once per IDO.
          </p>
        </div>
      </div>
      <ButtonRocket color="red" />
    </div>
  )
}
