import { useNavigate } from '@reach/router'
import { Button, TextLink } from 'components'
import ModalWalletHeading from 'components/ModalWalletHeading'
import tiersMapFn from 'components/YourTier/tiersMap'
import { useStore } from 'effector-react'
import { KycRejectType, KycStatus } from 'gqlgen/public'
import { $kycInfo, $stakingInfo, openApplyForKYCLink } from 'models/app'
import { closeModal, openAboutKYCModal } from 'models/flow'
import { $stakingTokenBalance } from 'models/pool'
import { $connected, $userTier } from 'models/user'
import React from 'react'
import { useTheme } from 'themeContext'
import { formatUTC } from 'utils/dayjs'
import { formatNumber, toDecimal } from 'utils/numbers'

export default function ConnectWalletSuccessModal() {
  const dark = useTheme()

  const navigate = useNavigate()
  const stakingInfo = useStore($stakingInfo)
  const kycInfo = useStore($kycInfo)
  const stakingTokenBalance = useStore($stakingTokenBalance)

  const userTier = useStore($userTier)
  const connected = useStore($connected)
  const tierInfo = stakingInfo.tiers.find(({ level }) => level === userTier)

  const handleGoToStakingClick = () => {
    navigate('/staking')
    closeModal()
  }
  const handleAboutKYCClick = () => {
    openAboutKYCModal()
  }

  const handleApllyForKYCClick = () => {
    closeModal()
    openApplyForKYCLink()
  }

  const passedKYC = kycInfo?.account?.status === KycStatus.Approved
  let kycStatusLabel = passedKYC ? 'Verified' : 'Not verified'

  switch (kycInfo?.account?.rejectType) {
    case KycRejectType.Final:
      kycStatusLabel = `${kycStatusLabel}: Rejected`
      break
    case KycRejectType.Retry:
      kycStatusLabel = `${kycStatusLabel}: Resubmit`
      break
  }

  const kycStatusDescription = passedKYC
    ? 'Congratulations! You have successfully completed the KYC procedure.'
    : kycInfo?.account?.rejectType === KycRejectType.Final
    ? 'We were unable to verify your account.'
    : kycInfo?.account?.rejectType === KycRejectType.Retry
    ? "It looks like some of your documents don't meet the necessary criteria."
    : 'Your address is not yet approved by KYC procedure.'

  const TIERS_MAP = React.useMemo(() => tiersMapFn(dark), [dark])

  const cn = {
    nameColor: dark ? 'text-white' : 'text-black', //tier === '0' ? 'text-grayLight' : 'text-black',
    textColor: dark ? 'text-grayLight' : 'text-gray',
    asaBg: dark ? 'bg-blackDeep' : 'bg-grayUltraLight',
    separator: dark ? 'bg-grayLight' : 'bg-grayLightSecond',
  }

  const oddsMultiple = toDecimal(tierInfo?.participationTickets).eq(0)
    ? 'Guaranteed'
    : tierInfo?.participationTickets

  const allocationMultiple = tierInfo?.allocationMultiple

  const aboutStakingTiersLink =
    'https://algodao.gitbook.io/faq/algodao-introduction-and-faq/participation-in-idos#membership-tiers-and-idos'
  return (
    <div className="flex flex-col flex-grow">
      <ModalWalletHeading />
      <div className="grid ml:grid-cols-2 grid-cols-1 gap-[0.7rem]">
        <div className={`${cn.asaBg} font-aldrich rounded-md p-[1rem]`}>
          <div className="flex items-baseline">
            <div className={`${cn.nameColor} text-[1.3rem] mr-[0.5rem]`}>
              {formatNumber(stakingTokenBalance)}
            </div>
            <div className="text-[0.8rem] text-grayLight">
              {stakingInfo?.stakingToken?.symbol ?? ''}
            </div>
          </div>
          <div className="text-red uppercase text-[0.8rem]">Wallet balance</div>
        </div>
        <div className={`${cn.asaBg} font-aldrich rounded-md p-[1rem]`}>
          <div className="flex items-baseline">
            <div className={`${cn.nameColor} text-[1.3rem] mr-[0.5rem]`}>
              {formatNumber(stakingInfo?.account?.stakingBalance)}
            </div>
            <div className="text-[0.8rem] text-grayLight">
              {stakingInfo?.stakingToken?.symbol ?? ''}
            </div>
          </div>
          <div className="text-red uppercase text-[0.8rem]">Your stake</div>
        </div>
      </div>
      <div className="relative mt-[1.5rem]">
        <div
          className={`text-grayMedium absolute hidden ms:block right-[0] top-[1rem] w-[10.6rem] h-[10.6rem]`}
        >
          {TIERS_MAP[userTier].icon}
        </div>
        <div className="mb-[0.75rem]">
          <div className={`${cn.textColor} leading-[100%] mb-[0.5rem]`}>
            Your tier
          </div>
          <div
            className={`relative z-10 uppercase text-[1.6rem] leading-[100%] ${cn.nameColor}`}
          >
            {TIERS_MAP[userTier].name}
          </div>
        </div>
        <div className={`${cn.textColor} text-[0.9rem] mb-[1.5rem]`}>
          {tierInfo ? (
            <>
              <p>
                Pool Weight Multiplier —{' '}
                <span className="text-blue font-aldrich">
                  x{allocationMultiple}
                </span>
              </p>
              <p>
                Participation Odds Multiple —{' '}
                <span className="text-blue font-aldrich">{oddsMultiple}</span>
              </p>
            </>
          ) : null}
          {connected && userTier === 0 && (
            <div className="w-1/2">
              <p>You don’t have sufficient stake to participate in IDOs.</p>
              <p>
                Read more{' '}
                <a
                  target="_blank"
                  href={aboutStakingTiersLink}
                  className="underline cursor-pointer hover:no-underline text-grayLight"
                  rel="noreferrer"
                >
                  about staking tiers.
                </a>
              </p>
            </div>
          )}
        </div>
        <Button
          color="blue"
          className="w-full ml:w-[15rem]"
          onClick={handleGoToStakingClick}
        >
          Stake
        </Button>
      </div>

      <div className={`${cn.separator} w-full h-[1px] mt-[1.6rem]`}></div>

      <div className="text-left">
        <div className="mt-[1.25rem] mb-[0.75rem]">
          <div className={`${cn.textColor} leading-[100%] mb-[0.5rem]`}>
            Your KYC status
          </div>
          <div
            className={`uppercase text-[1.6rem] leading-[100%] ${cn.nameColor}`}
          >
            {kycStatusLabel}
          </div>
        </div>
        <p className={`${cn.textColor} mb-[1.5rem]`}>
          {kycStatusDescription} (Last sync{' '}
          {formatUTC(kycInfo?.account?.lastSyncTime)})
        </p>

        {!passedKYC && kycInfo?.account?.rejectType !== KycRejectType.Final ? (
          <Button
            color="blue"
            className="mb-[1.5rem] w-full ml:w-[15rem]"
            onClick={handleApllyForKYCClick}
          >
            {kycInfo?.account?.rejectType === KycRejectType.Retry
              ? 'Resubmit'
              : 'Apply for KYC'}
          </Button>
        ) : null}
        <div className={`${cn.textColor} flex`}>
          {/* {(kycInfo?.rejectType === null ||
            kycInfo?.status === KycStatus.Empty) &&
          kycInfo.status !== KycStatus.Approved ? (
            <TextLink
              className="mr-[1.25rem]"
              href={kycInfo?.usInvestorsLink ?? ''}
            >
              For accredited us investors
            </TextLink>
          ) : null} */}

          <TextLink onClick={handleAboutKYCClick}>About KYC</TextLink>
        </div>
      </div>
    </div>
  )
}
