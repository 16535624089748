import {
  Algodv2,
  ALGORAND_MIN_TX_FEE,
  assignGroupID,
  encodeUint64,
  getApplicationAddress,
  makeApplicationCallTxnFromObject,
  makeAssetTransferTxnWithSuggestedParamsFromObject,
  OnApplicationComplete,
  Transaction,
} from 'algosdk'

interface MakeReserveTransactionOptions {
  algodClient: Algodv2
  poolAppID: number
  from: string
  tier: number
  wave: number
  proof: string[]
  quoteAssetAmount: number
  quoteAssetID: number
}

/**
 * @param algodClient Algorand client
 * @param poolAppID app ID of pool smart contract
 * @param from address of user
 * @param tier tier of participant
 * @param wave wave of participant
 * @param proof array of base64-encoded elements — merkle proof
 * @param quoteAssetID asset ID of tokens to send
 * @param quoteAssetAmount amount of tokens to send
 */
export const makeReserveTransaction = async ({
  algodClient,
  poolAppID,
  from,
  tier,
  wave,
  proof,
  quoteAssetAmount,
  quoteAssetID,
}: MakeReserveTransactionOptions): Promise<Transaction[]> => {
  const enc = new TextEncoder()
  const extendCompBudgetMethodName = enc.encode('extend_comp_budget')

  let suggestedParams = await algodClient.getTransactionParams().do()
  suggestedParams.fee = 2_000
  suggestedParams.flatFee = true

  const reserveTx = makeApplicationCallTxnFromObject({
    from: from,
    appIndex: poolAppID,
    appArgs: [
      enc.encode('reserve'),
      encodeUint64(encode3NumbersToOne(tier, wave, proof.length)),
      ...proof.map((it) => new Uint8Array(Buffer.from(it, 'base64'))),
    ],
    foreignAssets: [quoteAssetID],
    onComplete: OnApplicationComplete.NoOpOC,
    suggestedParams: suggestedParams,
  })

  suggestedParams = await algodClient.getTransactionParams().do()
  const transferTx = makeAssetTransferTxnWithSuggestedParamsFromObject({
    from: from,
    to: getApplicationAddress(poolAppID),
    assetIndex: quoteAssetID,
    amount: quoteAssetAmount,
    suggestedParams: suggestedParams,
  })

  const extendComputationBudgetTx = makeApplicationCallTxnFromObject({
    from: from,
    appIndex: poolAppID,
    appArgs: [extendCompBudgetMethodName],
    onComplete: OnApplicationComplete.NoOpOC,
    suggestedParams: suggestedParams,
  })
  const extendComputationBudgetTx2 = makeApplicationCallTxnFromObject({
    from: from,
    appIndex: poolAppID,
    appArgs: [extendCompBudgetMethodName, enc.encode('2')],
    note: enc.encode(`${Math.random()}`),
    onComplete: OnApplicationComplete.NoOpOC,
    suggestedParams: suggestedParams,
  })

  return assignGroupID([
    transferTx,
    reserveTx,
    extendComputationBudgetTx,
    extendComputationBudgetTx2,
  ])
}

interface MakeReserveWithoutWhitelistTransactionOptions {
  algodClient: Algodv2
  poolAppID: number
  from: string
  quoteAssetAmount: number
  quoteAssetID: number
}

export const makeReserveWithoutWhitelistTransaction = async ({
  algodClient,
  poolAppID,
  from,
  quoteAssetAmount,
  quoteAssetID,
}: MakeReserveWithoutWhitelistTransactionOptions): Promise<Transaction[]> => {
  const enc = new TextEncoder()
  const methodName = enc.encode('reserve_without_whitelist')

  const suggestedParams = await algodClient.getTransactionParams().do()
  suggestedParams.fee = 2_000
  suggestedParams.flatFee = true

  const reserveTx = makeApplicationCallTxnFromObject({
    from: from,
    appIndex: poolAppID,
    appArgs: [methodName],
    foreignAssets: [quoteAssetID],
    onComplete: OnApplicationComplete.NoOpOC,
    suggestedParams: suggestedParams,
  })

  const transferTx = makeAssetTransferTxnWithSuggestedParamsFromObject({
    from: from,
    to: getApplicationAddress(poolAppID),
    assetIndex: quoteAssetID,
    amount: quoteAssetAmount,
    suggestedParams: suggestedParams,
  })

  const extendComputationBudgetTx = makeApplicationCallTxnFromObject({
    from: from,
    appIndex: poolAppID,
    appArgs: [enc.encode('extend_comp_budget')],
    onComplete: OnApplicationComplete.NoOpOC,
    suggestedParams: suggestedParams,
  })

  return assignGroupID([transferTx, reserveTx, extendComputationBudgetTx])
}

interface MakeClaimTransactionOptions {
  algodClient: Algodv2
  poolAppID: number
  from: string
  idoAssetID: number
}

export const makeClaimTransaction = async ({
  algodClient,
  poolAppID,
  from,
  idoAssetID,
}: MakeClaimTransactionOptions): Promise<Transaction> => {
  const enc = new TextEncoder()
  const methodName = enc.encode('claim')

  const suggestedParams = await algodClient.getTransactionParams().do()
  suggestedParams.fee = 2 * ALGORAND_MIN_TX_FEE
  suggestedParams.flatFee = true

  return makeApplicationCallTxnFromObject({
    from: from,
    appIndex: poolAppID,
    appArgs: [methodName],
    foreignAssets: [idoAssetID],
    onComplete: OnApplicationComplete.NoOpOC,
    suggestedParams: suggestedParams,
  })
}

interface MakeRefundTransactionOptions {
  algodClient: Algodv2
  poolAppID: number
  from: string
  quoteAssetID: number
}

export const makeRefundTransaction = async ({
  algodClient,
  poolAppID,
  from,
  quoteAssetID,
}: MakeRefundTransactionOptions): Promise<Transaction> => {
  const enc = new TextEncoder()
  const methodName = enc.encode('refund')

  const suggestedParams = await algodClient.getTransactionParams().do()
  suggestedParams.fee = 2 * ALGORAND_MIN_TX_FEE
  suggestedParams.flatFee = true

  return makeApplicationCallTxnFromObject({
    from: from,
    appIndex: poolAppID,
    appArgs: [methodName],
    foreignAssets: [quoteAssetID],
    onComplete: OnApplicationComplete.NoOpOC,
    suggestedParams: suggestedParams,
  })
}

// Uses Chinese Remainder Theorem
const encode3NumbersToOne = (a: number, b: number, c: number): number => {
  let k = 0
  for (let i = 0; i < 5; i++) {
    if ((17 * i + (c - 1) - (a - 1)) % 5 === 0) {
      k = i
    }
  }

  let l = 0
  for (let i = 0; i < 4; i++) {
    if ((85 * i + 17 * k + (c - 1) - (b - 1)) % 4 === 0) {
      l = i
    }
  }

  return 85 * l + 17 * k + (c - 1)
}
