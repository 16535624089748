import Logo from 'components/Logo'
import { ModalTitle } from 'components'
import { ReactElement } from 'react-markdown/lib/react-markdown'
import { t } from 'utils'
import { useTheme } from 'themeContext'

type Props = {
  text: string | ReactElement
}
export default function ConnectWalletSignModal({ text }: Props) {
  const dark = useTheme()

  const cn = {
    text: dark ? 'text-white' : 'text-black',
  }

  return (
    <div className={`${cn.text} flex flex-col flex-grow items-center`}>
      <ModalTitle className="text-center">{t('connect_wallet')}</ModalTitle>
      <div className="flex flex-col items-center">
        <div className="text-center mt-[1.75rem] mb-[4rem]">{text}</div>
        <Logo
          bgFill={dark ? 'white' : 'black'}
          className="text-red w-[6rem] h-[6.5rem] mb-[2rem] "
        />
      </div>
    </div>
  )
}
