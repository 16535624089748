import { useStore } from 'effector-react'
import { createAccountsDataFx } from 'models/algo'
import { $modalState, ModalState } from 'models/flow'
import AboutKYCModal from './AboutKYCModal'
import ConnectWalletChoiceAccountModal from './ConnectWalletChoiceAccountModal'
import ConnectWalletModal from './ConnectWalletModal'
import ConnectWalletSelectAccountModal from './ConnectWalletSelectAccountModal'
import ConnectWalletSuccessModal from './ConnectWalletSuccessModal'
import ConnectWalletWaitModal from './ConnectWalletWaitModal'
import ModalContainer from './ModalContainer'
import RegistrationConfirmationModal from './RegistrationConfirmationModal'
import RegistrationSignModal from './RegistrationSignModal'
import RegistrationSuccessModal from './RegistrationSuccessModal'
import TxFailureModal from './TxFailureModal'
import TxSuccessModal from './TxSuccessModal'
import TxWaitModal from './TxWaitModal'
import ConnectWalletFailedModal from './ConnectWalletFailedModal'
import OptInModal from './OptInModal'
import OptInToAssetModal from './OptInToAssetModal'
import UnstakeWarningModal from './UnstakeWarningModal'

export default function Modals() {
  const modalState = useStore($modalState)

  let modal = null
  switch (modalState) {
    case ModalState.ConnectWallet:
      modal = <ConnectWalletModal />
      break
    case ModalState.ConnectWalletWait:
      modal = <ConnectWalletWaitModal text={`Asking for access`} />
      break
    case ModalState.ConnectWalletSign:
      modal = (
        <ConnectWalletWaitModal
          text={
            <div>
              {' '}
              Sign a signature request in your wallet. <br />
              Signing is free and will not send a transaction.
            </div>
          }
        />
      )
      break
    case ModalState.ConnectWalletChoiceAccount:
      modal = <ConnectWalletChoiceAccountModal />
      break
    case ModalState.ConnectWalletSelectAccount:
      createAccountsDataFx()
      modal = <ConnectWalletSelectAccountModal />
      break
    case ModalState.ConnectWalletSuccess:
      modal = <ConnectWalletSuccessModal />
      break
    case ModalState.ConnectWalletFailed:
      modal = <ConnectWalletFailedModal />
      break
    case ModalState.TxWait:
      modal = <TxWaitModal />
      break
    case ModalState.TxSuccess:
      modal = <TxSuccessModal />
      break
    case ModalState.TxFailure:
      modal = <TxFailureModal />
      break
    case ModalState.RegistrationSuccess:
      modal = <RegistrationSuccessModal />
      break
    case ModalState.RegistrationConfirmation:
      modal = <RegistrationConfirmationModal />
      break
    case ModalState.OptInPool:
      modal = <OptInModal />
      break
    case ModalState.OptInToAsset:
      modal = <OptInToAssetModal />
      break
    case ModalState.RegistrationSign:
      modal = <RegistrationSignModal />
      break
    case ModalState.AboutKYC:
      modal = <AboutKYCModal />
      break
    case ModalState.UnstakeWarning:
      modal = <UnstakeWarningModal />
      break
  }

  return <ModalContainer>{modal}</ModalContainer>
}
