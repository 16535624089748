import { sample } from 'effector'
import isEmpty from 'lodash/isEmpty'
import { $accountsData, setAccountsData } from 'models/algo'
import { $stakingInfo } from 'models/app'
import { closeModal, openConnectChoiceAccountModal } from 'models/flow'
import {
  $userAccount,
  $userSession,
  EMPTY_USER_SESSION,
  authFx,
  setUserAccount,
  setUserSession,
  setUserTier,
} from '.'

$accountsData.watch((state) => {
  const userSession = $userSession.getState()
  const userAccount = state.find(
    (account) => account.address === userSession.address
  )

  setUserAccount(userAccount || {})
})

$accountsData.on(setAccountsData, (state) => {
  const userAccount = $userAccount.getState()
  if (!isEmpty(userAccount.address)) {
    if (!userAccount?.isOptIn) {
      setUserSession(EMPTY_USER_SESSION)
      openConnectChoiceAccountModal()
    }
  }
})

sample({
  clock: $stakingInfo,
  fn: (info) => info.account?.tier?.level ?? 0,
  target: setUserTier,
})

// sample({
//   clock: connectWallet,
//   source: {
//     accounts: $accounts,
//     currentWallet: $currentWallet,
//     session: $userSession,
//   },
//   fn: ({ accounts, currentWallet, session }) => ({
//     accounts,
//     currentWallet,
//     address: session.address,
//   }),
//   target: connectWalletFx,
// })

// REMOVE_LATER
authFx.doneData.watch(() => {
  closeModal()
})

// sample({
//   clock: $accountsData,
//   source: $userSession,
//   fn: (userSession, accountsData) =>
//     accountsData.find((account) => account.address === userSession.address) ||
//     {},
//   target: setUserAccount,
// })

// sample({
//   clock: createAccountsDataFx,
//   source: $userAccount,
//   fn: (userAccount) => {
//     if (!isEmpty(userAccount.address) && !userAccount?.isOptIn) {
//       $userSession.reset(setUserSession)
//       openConnectСhoiceAccountModal()
//     }
//   },
// })
