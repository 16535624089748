import ModalTitle from 'components/ModalTitle'
import Logo from 'components/Logo'
import { t } from 'utils'

export default function RegistrationSignModal() {
  return (
    <div className="flex flex-col flex-grow">
      <ModalTitle>{t('modal_confirm_registration_heading')}</ModalTitle>
      <p className="text-center font-mono text-black leading-[1.35rem] text-base">
        Sign a signature request in your wallet. <br /> Signing is free and will
        not send a transaction.
      </p>
      <div className="flex flex-col items-center">
        <Logo className="text-red w-[6rem] h-[6.5rem] mt-[4rem] mb-[2rem] " />
      </div>
    </div>
  )
}
